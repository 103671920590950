<template>
  <div>
    <TabHeader title="Bloquear comunicações" />
    <div class="block-communication-tab__body">
      <div class="block-communication-tab__title">
        <span>Selecione os contatos que deseja bloquear</span>
      </div>
      <div class="block-communication-tab__description">
        <span>
          Os contatos selecionados serão adicionados a nossas listas de contatos
          bloqueados, portanto não receberão mais nenhum tipo de comunicação.
        </span>
      </div>
      <div class="block-communication-tab__description">
        <span>
          Os contatos já selecionados ao abrir esta aba já estão bloqueados.
          Para desbloquear basta remover a seleção e salvar.
        </span>
      </div>
      <div class="block-communication-tab__description">
        <span>Emails:</span>
      </div>
      <div
        v-if="email_status_list.length === 0"
        class="block-communication-tab__warning"
      >
        <span>Usuário não possui e-mails cadastrados</span>
      </div>
      <div class="block-communication-tab__users">
        <template v-for="item in email_status_list">
          <div
            :key="item.email"
            class="block-communication-tab__users-checkbox"
          >
            <Checkbox v-model="item.blocked" :label="`${item.email}`" />
          </div>
        </template>
      </div>
      <div class="block-communication-tab__description">
        <span>Telefones:</span>
      </div>
      <div
        v-if="profile.phoneStatusList.length === 0"
        class="block-communication-tab__warning"
      >
        <span>Usuário não possui telefones cadastrados</span>
      </div>
      <div class="block-communication-tab__users">
        <template v-for="item in profile.phoneStatusList">
          <div
            :key="item.phone"
            class="block-communication-tab__users-checkbox"
          >
            <Checkbox v-model="item.blocked" :label="`${item.phone}`" />
          </div>
        </template>
      </div>
      <Button
        class="block-communication-tab__btn"
        :text="submitting ? 'Aguarde...' : 'Bloquear contatos'"
        :loading="submitting"
        type="positive"
        @click="saveClicked"
      />
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import ProfileAPI from '@/api/profileAPI';
import ProfileService from '@/assets/javascripts/services/ProfileService';
import { mapState, mapActions } from 'vuex';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    TabHeader,
    Checkbox,
    Button,
  },
  data: () => {
    return {
      submitting: false,
      email_status_list: [],
    };
  },
  computed: {
    ...mapState('Profile', ['profile']),
  },
  mounted() {
    this.getEmailStatus(this.profile.id);
  },
  methods: {
    ...mapActions('RightPanel', ['tabGoBack']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),

    getEmailStatus(baseUserId) {
      ProfileService.sendgridEmailStatus(baseUserId).then(result => {
        this.email_status_list = result;
      });
    },

    saveClicked() {
      this.submitting = true;

      const emails = this.email_status_list || [];
      const phones = this.profile.phoneStatusList || [];

      ProfileAPI.manageCommunication(emails, phones)
        .then(() => {
          this.notifySuccess(
            'Os status dos contatos serão atualizados em breve!'
          );
          this.tabGoBack();
        })
        .catch(() => {
          this.notifyError(
            'Ocorreu um erro ao bloquear os contatos selecionados, tente novamente mais tarde'
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.block-communication-tab__body
  overflow-y: auto
  padding: $space-m $space-st 0 $space-st

.block-communication-tab__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.block-communication-tab__description
  font-size: 14px
  padding-top: $space-xs
  margin-top: $space-m

.block-communication-tab__warning
  display: flex
  align-items: center
  justify-content: center
  padding-top: $space-st
  color: $silver-gray

.block-communication-tab__users
  padding-top: $space-xs
  padding-bottom: $space-xs

.block-communication-tab__users-checkbox
  padding: $space-xs 0

.block-communication-tab__btn
  width: 100%
  margin-top: $space-l

.checkbox
  font-weight: bold
  line-height: $space-st
  margin-bottom: $space-xs
</style>
