<template>
  <div class="update-ticket">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <form class="update-ticket__body" @submit.prevent="submit">
      <div class="opa__card-body">
        <div class="update-ticket__title">
          Atualizar dados da ordem e do comprovante de pré-matrícula
        </div>
        <div class="update-ticket__text-gray">
          Deseja copiar os dados do perfil do aluno para a ordem e para o
          comprovante?
        </div>
        <div class="update-ticket__text-bold">
          Atenção, essa ação é irreversível.
        </div>
        <Button
          class="update-ticket__button"
          type="positive"
          :text="'Atualizar dados'"
          :submit="true"
        />
      </div>
    </form>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Rollbar from '@/common_modules/tools/Rollbar';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    Button,
    TabHeader,
  },
  computed: {
    ...mapGetters('Order', ['order', 'offerNames']),
    ...mapState('Order', ['coupon']),
  },
  methods: {
    ...mapActions('Order', ['submitUpdateTicket']),
    ...mapActions('Coupon', ['submitUpdateUserData']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),
    async submit() {
      try {
        await this.submitUpdateTicket(this.order.result);

        if (this.coupon) {
          await this.submitUpdateUserData(this.coupon.id);
        }

        this.notifySuccess('Dados atualizados com sucesso!');
      } catch (error) {
        Rollbar.error('UpdateTicket | error to submit', { error });
        this.$faro.api.pushError(error, {
          context: {
            message: 'UpdateTicket | error to submit',
          },
        });

        this.notifyError(
          error.response?.data?.message ||
            'Não foi possível atualizar os dados. Tente novamente mais tarde.'
        );
      }

      this.tabGoBack();
    },
  },
};
</script>

<style lang="sass">
.update-ticket
  height: 100%
  background-color: $space-gray

.update-ticket__body
  padding: $space-st

.update-ticket__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.update-ticket__text-gray
  padding: $space-s 0
  color: $chrome-gray

.update-ticket__text-bold
  padding: $space-s 0
  color: $chrome-gray
  font-weight: $fw-bold

.update-ticket__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
