<template>
  <Card class="course-card">
    <template slot="header">
      <div class="course-card__header">
        <span>Dados do curso</span>
        <CopyIcon
          v-if="offer"
          label="Copiar dados da vaga"
          :clipboard-text="clipboardOfferInfoText"
        />
      </div>
    </template>
    <CourseCardBody class="course-card__body" :course="course" :offer="offer" />
  </Card>
</template>

<script>
import Card from '@/components/common/Card';
import CopyIcon from '@/components/Profile/Scholarship/CopyIcon';
import CourseCardBody from '@/components/Profile/Scholarship/CourseCardBody';
import brlCurrency from '@/assets/javascripts/formatters/BRLCurrency';
import {
  installmentsPrefix,
  installmentsUnit,
} from '@/assets/javascripts/formatters/Installments';
import _ from 'lodash';

export default {
  components: {
    Card,
    CopyIcon,
    CourseCardBody,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    saleCampaignDiscount() {
      if (this.offer.saleCampaign) {
        return this.offer.preEnrollmentFee.discount_value;
      }
      return 0;
    },
    campusAddress() {
      const campus = this.course.campus;
      const addressFirstPart =
        campus.neighborhood && campus.address
          ? campus.neighborhood + ', ' + campus.address + ', '
          : '';
      const addressSecondPart =
        campus.city && campus.state ? campus.city + ' - ' + campus.state : '';

      return addressFirstPart + addressSecondPart;
    },
    feeWithSalesCampaignValue() {
      return this.offer.preEnrollmentFee.value - this.saleCampaignDiscount;
    },
    installmentsPrefix() {
      const {
        course: { level },
        offer: { maxPayments },
      } = this;

      const installmentsText = installmentsPrefix(level, maxPayments);

      return installmentsText ? `${installmentsText} ` : '';
    },
    installmentsUnit() {
      return installmentsUnit(this.course.level);
    },
    fullPriceWithPrefix() {
      const {
        installmentsPrefix,
        offer: { fullPrice },
      } = this;

      return `${installmentsPrefix}${brlCurrency(fullPrice)}`;
    },
    offeredPriceWithPrefix() {
      const {
        installmentsPrefix,
        offer: { offeredPrice },
      } = this;

      return `${installmentsPrefix}${brlCurrency(offeredPrice)}`;
    },
    clipboardOfferInfoText,
  },
  methods: {
    plurify(period) {
      const to_plural = {
        semestre: 'semestres',
        quinzenal: 'quinzenas',
        mes: 'meses',
        trimestre: 'trimestres',
        dia: 'dias',
        semanal: 'semanas',
        modulo: 'módulos',
        ano: 'anos',
        hora: 'horas',
      };

      return to_plural[period];
    },
  },
};

function clipboardOfferInfoText() {
  const {
    campusAddress,
    course,
    feeWithSalesCampaignValue,
    fullPriceWithPrefix,
    installmentsUnit,
    offer,
    offeredPriceWithPrefix,
  } = this;
  let courseEnrollment;

  if (course.enrollmentSemester === 'Início imediato') {
    courseEnrollment = course.enrollmentSemester;
  } else {
    courseEnrollment = checkIfEnrollmentSemesterIsTrimester(course)
      ? trimester(course.enrollmentSemester)
      : semester(course.enrollmentSemester);
  }

  const enrollmentFeeText = !this.offer.freePreEnrollment
    ? `Valor da pré-matrícula: ${brlCurrency(feeWithSalesCampaignValue)}`
    : '';

  return `*${course.campus.university.name} - ${course.name}*
${course.campus.name}

Início: ${courseEnrollment}
${course.shift}
${course.maxPeriods} ${this.plurify(this.course.periodKind)}
${course.level}
${course.kind}
${campusAddress}

${installmentsUnit} sem o desconto: ${fullPriceWithPrefix}
Bolsa: ${offer.discountPercentage}%
${installmentsUnit} _com o desconto_: ${offeredPriceWithPrefix}
${enrollmentFeeText}

${offer.url}`;
}

function checkIfEnrollmentSemesterIsTrimester(course) {
  const universityId =
    course.campus && course.campus.university && course.campus.university.id;
  switch (universityId) {
    case 19:
      return course.kind == 'EaD' && course.level.includes('pós-graduação');
    default:
      return false;
  }
}

function semester(semesterCode) {
  if (!_.isString(semesterCode)) {
    return semesterCode;
  }
  const [year, semester] = semesterCode.split('.');
  return `${semester}º semestre de ${year}`;
}

function trimester(trimesterCode) {
  if (!_.isString(trimesterCode)) {
    return trimesterCode;
  }
  const [year, trimester] = trimesterCode.split('.');
  return `${trimester}º trimestre de ${year}`;
}
</script>

<style lang="sass" scoped>
.course-card
  margin-bottom: $space-st
  color: $silver

.course-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.course-card__body
  margin: $space-m 0
</style>
