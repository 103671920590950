<template>
  <div ref="form" class="restricted-offer">
    <TabHeader
      class="restricted-offer__header"
      :title="'Vincular Oferta Restrita'"
    />
    <div class="restricted-offer__body">
      <div class="restricted-offer__title">
        Vincular Oferta Restrita
      </div>
      <div class="restricted-offer__group">
        <label class="restricted-offer__label">
          Insira o ID da oferta restrita criada
        </label>
        <div class="restricted-offer__search">
          <input
            v-model="formData.restrictedOfferId"
            class="restricted-offer__input"
            type="text"
            name="restrictedOfferId"
            :data-index="0"
            placeholder="Exemplo: 12345"
            autocomplete="off"
          />
          <Button
            class="restricted-offer-search__button opa-btn--action"
            :disabled="!formData.restrictedOfferId"
            text="Buscar oferta"
            @click="searchOffer"
          />
        </div>
        <div v-if="offerDetails">
          <Card class="review-request-card">
            <template slot="header">
              <div class="review-request-card__header">
                <Icon class="review-request-card__icon" svg-name="i-error" />
                <div class="review-request-card__title">
                  Revise os dados da solicitação:
                </div>
              </div>
            </template>
            <div class="review-request-card__items-list">
              <p class="review-request-card__item">
                Nome do curso: {{ offerData.courseName }}
              </p>
              <p class="review-request-card__item">
                Instituição: {{ offerData.university }}
              </p>
              <p class="review-request-card__item">
                Turno: {{ offerData.shift }}
              </p>
              <p class="review-request-card__item">
                Modalidade: {{ offerData.kind }}
              </p>
              <p class="review-request-card__item">
                Mensalidade: R${{ offerData.offeredPrice }} ({{
                  offerData.discountPercentage
                }}%)
              </p>
            </div>
          </Card>
          <div class="restricted-offer__select-options">
            <label class="restricted-offer__label">
              Selecione a tratativa desejada
            </label>
            <Select
              v-model="formData.selectedOption"
              class="restricted-offer__select"
              placeholder="Selecione uma opção"
              :list="restrictedOfferOptions"
              label-property="name"
            />
          </div>
        </div>
        <div v-if="isGenerateWithSpecificValue">
          <div class="restricted-offer-specific-value">
            <label class="restricted-offer-specific-value__label">
              Insira o valor específico para a ordem
            </label>
            <input
              v-model="formData.specificOrderValue"
              class="restricted-offer-specific-value__input"
              type="text"
              name="specificOrderValue"
              placeholder="Exemplo: 150.90"
              autocomplete="off"
              min="0"
              step="0.01"
            />
          </div>
        </div>
        <div v-if="isGenerateWithVoucher">
          <div class="restricted-offer-voucher-list">
            <label class="restricted-offer-voucher-list__label">
              Selecione o voucher
            </label>
            <Select
              v-model="formData.voucherCode"
              class="restricted-offer-voucher-list__select"
              placeholder="Selecione uma opção"
              :list="voucherCodeList"
              label-property="code"
            />
          </div>
        </div>
        <div class="review-request__confirm-terms-checkbox">
          <Checkbox
            v-model="formData.accepted"
            label="Confirmo que os dados estão corretos e estou ciente de que essa ação é irreversível."
          />
        </div>
        <Button
          class="restricted-offer__submit-button"
          :disabled="isSubmitButtonDisabled"
          text="Vincular oferta ao aluno"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import Select from '@/components/common/Select';
import Checkbox from '@/components/common/Checkbox';
import ProfileService from '@/assets/javascripts/services/ProfileService';
import OfferAPI from '@/api/offerAPI';

import { mapState, mapActions } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
    Card,
    Icon,
    Select,
    Checkbox,
  },
  data() {
    return {
      formData: {
        restrictedOfferId: null,
        selectedOption: null,
        specificOrderValue: null,
        voucherCode: null,
        accepted: false,
      },
      offerData: {
        courseName: null,
        kind: null,
        shift: null,
        university: null,
        offeredPrice: null,
        discountPercentage: null,
        enabled: null,
      },
      restrictedOfferOptions: [
        {
          name: 'Gerar ordem com PEF padrão',
          key: 'generate-default-order',
        },
        {
          name: 'Gerar ordem com valor específico',
          key: 'generate-with-specific-value',
        },
        {
          name: 'Gerar ordem gratuita',
          key: 'generate-free-order',
        },
        {
          name: 'Troca de bolsa',
          key: 'generate-with-voucher',
        },
      ],
      offerDetails: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profile', 'user']),
    ...mapState('Voucher', ['vouchers']),
    isGenerateWithSpecificValue() {
      return (
        this.formData.selectedOption?.key === 'generate-with-specific-value'
      );
    },
    isGenerateWithVoucher() {
      return this.formData.selectedOption?.key === 'generate-with-voucher';
    },
    hasErrors() {
      const {
        restrictedOfferId,
        selectedOption,
        specificOrderValue,
        voucherCode,
      } = this.formData;
      if (this.isGenerateWithSpecificValue) {
        return !restrictedOfferId || !specificOrderValue;
      }
      if (this.isGenerateWithVoucher) {
        return !restrictedOfferId || !voucherCode;
      }
      return !restrictedOfferId || !selectedOption;
    },
    isSubmitButtonDisabled() {
      return this.hasErrors || !this.formData.accepted;
    },
    voucherCodeList() {
      return this.vouchers;
    },
  },
  created() {
    const { base_user } = this.user;

    this.loadVouchers(base_user);
  },
  methods: {
    ...mapActions('Profile', ['refreshProfile']),
    ...mapActions('Notifications', ['notifySuccess', 'notifyError']),
    ...mapActions('Voucher', ['loadVouchers']),

    async searchOffer() {
      try {
        const response = await OfferAPI.getOffer(
          this.formData.restrictedOfferId
        );
        const { course, offer } = response.data;

        this.offerDetails = true;

        this.offerData = {
          courseName: course.name,
          kind: course.kind,
          shift: course.shift,
          university: course.campus.university.name,
          offeredPrice: offer.offeredPrice,
          discountPercentage: offer.discountPercentage,
        };
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao buscar a oferta.';
        this.notifyError(message);
      }
    },
    async submit() {
      try {
        const payload = {
          baseUserId: this.user.base_user,
          restrictedOfferId: this.formData.restrictedOfferId,
          selectedOption: this.formData.selectedOption.key,
          specificOrderValue: this.formData.specificOrderValue,
          voucherCode: this.formData.voucherCode?.code ?? null,
        };

        await ProfileService.createOrderFromRestrictedOffer(payload);

        this.notifySuccess('Oferta vinculada com sucesso!');
        this.refreshProfile();
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao vincular a oferta.';

        this.notifyError(message);
      }
    },
  },
};
</script>

<style lang="sass">
.restricted-offer
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.restricted-offer__body
  overflow: auto
  padding: $space-st

.restricted-offer__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-m

.review-request-card__items-list
  margin-top: $space-m

.review-request-card__item
  margin-bottom: $space-m

.restricted-offer__search
  display: flex
  gap: $space-m
  justify-content: space-between
  align-items: center
  margin-bottom: 24px

.restricted-offer__label
  display: block
  margin-bottom: $space-m

.restricted-offer__group
  display: flex
  flex-direction: column

.restricted-offer__input
  @extend %opa-input
  width: 100%

.restricted-offer__input:disabled
  cursor: not-allowed
  opacity: 0.3

.restricted-offer__input::placeholder
  color: $chrome-gray

.restricted-offer__submit-button
  width: 100%
  margin-bottom: $space-s

.restricted-offer__select
  margin-bottom: $space-l

.restricted-offer-specific-value__label
  margin-bottom: $space-s

.restricted-offer-specific-value__input
  @extend %opa-input
  margin-top: $space-m
  margin-bottom: $space-l
  width: 100%

.restricted-offer-voucher-list__select
  margin-top: $space-s
  margin-bottom: $space-l

.review-request-card
  margin-bottom: $space-l

.review-request-card__header
  display: flex
  align-items: center
  gap: $space-s

.review-request-card__icon
  width: 24px
  height: 24px

.review-request-card__title
  font-size: rem(16)
  font-weight: bold
  margin: 0

.review-request-card__body
  margin-top: $space-m
  margin-bottom: $space-m

.review-request-card__checkbox
  margin-top: $space-l
  margin-bottom: $space-l
</style>
