import { isNil } from 'lodash';

export default {
  isOutdated(offer) {
    return !offer.visible || !offer.enabled;
  },
  isFreePreEnrollment(offer) {
    return offer.freePreEnrollment;
  },
  hasSeats(offer) {
    return !(
      isNil(offer.remainingSeats) ||
      ['N/D', 'Indisponível', '0 (Todas as bolsas reservadas)'].includes(
        offer.remainingSeats
      )
    );
  },
  acceptsBoletoPayment(offer) {
    return offer.acceptsBoletoPayment;
  },
};
