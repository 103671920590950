<template>
  <button
    class="opa-icon__container"
    type="button"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <svg class="opa-icon" :class="svgClass" :alt="svgAlt">
      <title>{{ svgAlt }}</title>
      <use :href="`#${svgName}`" />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    svgClass: {
      type: String,
      required: false,
      default: '',
    },
    svgName: {
      type: String,
      required: true,
    },
    svgAlt: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
