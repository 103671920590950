<template>
  <div class="update-enrollment-semester">
    <TabHeader title="Alterar Semestre" />
    <template>
      <form class="update-enrollment-semester__body" @submit.prevent="submit">
        <template slot="header" />
        <div class="opa__card-body">
          <div class="update-enrollment-semester__title">
            Atualizar o semestre do comprovante de pré-matrícula
          </div>
          <div class="update-enrollment-semester__text-gray">
            O semestre atual do comprovante de pré-matrícula é
            <strong>
              {{ coupon.enrollment_semester }}
            </strong>
          </div>
          <div class="update-enrollment-semester__text-bold">
            Atenção, o semestre será alterado apenas para o comprovante de
            pré-matricula!
          </div>
          <div class="update-enrollment-semester__label">
            Selecione o semestre desejado
          </div>
          <Select
            v-model="selectedSemester"
            class="update-enrollment-semester__select"
            placeholder="Semestre"
            :list="semestersList"
            label-property="value"
          />
          <Button
            class="update-enrollment-semester__button"
            type="positive"
            :disabled="hasErrors"
            :text="submitting ? 'Aguarde...' : 'Atualizar'"
            :submit="true"
            :loading="submitting"
          />
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapState, mapActions } from 'vuex';
import CouponAPI from '@/api/couponAPI.js';

export default {
  components: {
    TabHeader,
    Select,
    Button,
  },

  data: () => {
    return {
      selectedSemester: null,
      submitting: false,
    };
  },
  computed: {
    ...mapState('Order', ['coupon']),

    semestersList() {
      const currentYear = new Date().getFullYear();
      const years = [currentYear - 1, currentYear, currentYear + 1];
      const quarters = ['.1', '.2', '.3', '.4'];

      return years.reduce((acc, year) => {
        const yearQuarters = quarters.map(quarter => ({
          value: year + quarter,
        }));

        return acc.concat(yearQuarters);
      }, []);
    },
    hasErrors() {
      return !this.selectedSemester;
    },
  },
  methods: {
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),

    async submit() {
      this.submitting = true;
      CouponAPI.updateSemester({
        couponId: this.coupon.id,
        semester: this.selectedSemester.value,
      })
        .then(() => {
          this.notifySuccess('Semestre alterado com sucesso');
        })
        .catch(() => {
          this.notifyError(
            'Erro ao atualizar o semestre. A modalidade ou instituição do curso não permite essa alteração.'
          );
        })
        .finally(() => {
          this.submitting = false;
          this.tabGoBack();
        });
    },
  },
};
</script>

<style lang="sass">
.update-enrollment-semester__body
  padding: $space-st

.update-enrollment-semester__label
  margin-bottom: $space-s
  margin-top: $space-st
  font-weight: bold

.update-enrollment-semester__select
  margin-bottom: $space-m

.update-enrollment-semester__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st

.update-enrollment-semester__text-gray strong
  color: white

.update-enrollment-semester__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.update-enrollment-semester__text-gray
  padding: $space-s 0
  color: $chrome-gray

.update-enrollment-semester__text-bold
  padding: $space-s 0
  color: $chrome-gray
  font-weight: $fw-bold
</style>
