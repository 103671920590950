<template>
  <div class="course-details">
    <Card class="queropago-card">
      <template slot="header">
        <div class="queropago-card__header">
          <span>Curso</span>
          <CopyIcon :clipboard-text="clipboardCourseText" />
        </div>
      </template>
      <div class="queropago-card__body">
        <div class="queropago-card__item">
          <strong>Nome:</strong>
          <div class="queropago-card__item-content long-text">
            {{ currentBilling.course.name }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Modalidade:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.course.kind.name }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Turno:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.course.shift.name }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Nível:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.course.level.name }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Instituição:</strong>
          <div class="queropago-card__item-content long-text">
            {{ currentBilling.course.institution.name }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Campus:</strong>
          <div class="queropago-card__item-content long-text">
            {{ currentBilling.course.campus.name }}
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import CopyIcon from '@/components/Profile/Scholarship/CopyIcon';

export default {
  components: {
    Card,
    CopyIcon,
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
    clipboardCourseText() {
      const courseData = this.currentBilling.course;
      const courseName = `Nome: ${courseData.name}\n`;
      const kindName = `Modalidade: ${courseData.kind.name}\n`;
      const shiftName = `Turno: ${courseData.shift.name}\n`;
      const levelName = `Level: ${courseData.level.name}\n`;
      const institutionName = `Instituição: ${courseData.institution.name}\n`;
      const campusName = `Campus: ${courseData.campus.name}`;
      const result =
        courseName +
        kindName +
        shiftName +
        levelName +
        institutionName +
        campusName;

      return result;
    },
  },
};
</script>

<style lang="sass" scoped>
.long-text
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
