<template>
  <div class="opa-app">
    <Notifications />
    <OpaSprite class="opa-sprite" />
    <OpaSidebar />
    <nuxt />
    <RightPanel />
  </div>
</template>

<script>
import Notifications from '@/components/notification/Notifications';
import OpaSidebar from '@/components/sidebar/OpaSidebar';
import OpaSprite from '@/static/images/icons/sprite.svg';
import RightPanel from '@/components/common/RightPanel';
import Vue from 'vue';

export default {
  middleware: 'Authenticated',
  components: {
    Notifications,
    OpaSidebar,
    OpaSprite,
    RightPanel,
  },
  created() {
    Vue.config.errorHandler = undefined;
  },
};
</script>
