<template>
  <Card v-if="showSubscription || isLoading || error" class="subscription-card">
    <template slot="header">
      <div class="subscription-card__header">
        <div class="subscription-card__header-item">
          <span class="subscription-card__big-text">Taxa de manutenção</span>
        </div>
      </div>
    </template>
    <Loading v-if="isLoading" />
    <div v-else-if="error" class="subscription-card__body">
      <Badge type="error">
        Falha ao carregar informação
      </Badge>
      <div class="subscription-card__line">
        <span>Canal:</span>
        <span>#squad-retencao-help</span>
      </div>
      <div class="subscription-card__line">
        <span>Mensagem de erro:</span>
      </div>
      <span>{{ error }}</span>
    </div>
    <div v-else-if="showSubscription" class="subscription-card__body">
      <div class="subscription-card__line">
        <span class="subscription-card__body-title">Situação</span>
        <Badge class="order-card__status-badge" :type="badgeType">
          {{ subscriptionStatusLabel }}
        </Badge>
      </div>
      <div class="subscription-card__line">
        <span>Forma de pagamento</span><span>{{ paymentMethod }}</span>
      </div>
      <div class="subscription-card__line">
        <span>Valor</span><span>{{ amount }}</span>
      </div>
      <div class="subscription-card__line">
        <span>Quantidade paga</span><span>{{ charges }}</span>
      </div>
      <div class="subscription-card__line">
        <span>Parcelas mínimas</span>
        <span>
          {{ subscription.minInstallments }}
        </span>
      </div>
      <div class="subscription-card__line">
        <span>Próximo vencimento</span>
        <span>
          {{ subscription.currentPeriodEnd | momentformat('L') }}
        </span>
      </div>
      <template v-if="subscription.cancellationFeeFormatted">
        <div class="subscription-card__separator" />
        <div class="subscription-card__line subscription-card__important">
          <span>Taxa de cancelamento</span>
          <span>
            {{ subscription.cancellationFeeFormatted }}
          </span>
        </div>
      </template>
    </div>
  </Card>
</template>

<script>
import Rollbar from '@/common_modules/tools/Rollbar';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import SubscriptionFetchQuery from '@/plugins/apollo/querobolsa/queries/subscriptionFetch';
import Loading from '@/components/common/Loading';

function apolloErrorHandler(error) {
  this.error = error.message;

  Rollbar.error('[SubscriptionDanger] SubscriptionFetch GraphAPI error', {
    error,
  });

  this.$faro.api.pushError(error, {
    context: {
      message: '[SubscriptionDanger] SubscriptionFetch GraphAPI error',
    },
  });
}

export default {
  components: {
    Card,
    Badge,
    Loading,
  },
  props: {
    order: {
      type: Object,
      required: false,
      default: null,
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    subscription: null,
    error: null,
  }),
  computed: {
    showSubscription() {
      return this.subscription;
    },
    isLoading() {
      return this.$apollo.queries.SubscriptionFetch.loading;
    },
    paymentMethod() {
      const translatedPaymentMethod = {
        credit_card: 'Cartão de crédito',
        boleto: 'Boleto',
        pix: 'Pix',
      };

      return translatedPaymentMethod[this.subscription.paymentMethod];
    },
    version() {
      const translatedVersion = {
        monthly: 'mês',
        semiannual: 'semestre',
        annual: 'ano',
      };

      return translatedVersion[this.subscription.version];
    },
    amount() {
      return `${this.subscription.amountFormatted}/${this.version}`;
    },
    charges() {
      return `${this.subscription.paidCount} de
        ${this.subscription.installments}`;
    },
    subscriptionStatusLabel() {
      return {
        active: 'Ativo',
        canceled: 'Cancelado',
        defaulter: 'Inadimplente',
        dropped_out: 'Evadido',
        finished: 'Finalizado',
        trial: 'Ativo',
      }[this.subscription.status];
    },
    badgeType() {
      switch (this.subscription.status) {
        case 'active':
        case 'trial':
          return 'success';
        case 'canceled':
        case 'defaulter':
        case 'dropped_out':
          return 'error';
        case 'finished':
          return 'primary';
        default:
          return 'gray';
      }
    },
  },
  apollo: {
    SubscriptionFetch: {
      manual: true,
      prefetch: true,
      addTypename: true,
      query: SubscriptionFetchQuery,
      client: 'querobolsa',
      fetchPolicy: 'network-only',
      skip() {
        return !this.order;
      },
      variables() {
        return { orderId: this.order.id };
      },
      result(response) {
        this.subscription = response.data.subscriptionFetch;
      },
      error: apolloErrorHandler,
    },
  },
};
</script>

<style lang="sass" scoped>
.subscription-card
  color: $silver
  margin-bottom: $space-st

.subscription-card__header
  align-items: flex-start
  display: flex
  font-size: rem(18)
  font-weight: bold
  justify-content: space-between
  position: relative
  width: 100%

.subscription-card__body
  color: $silver-gray
  padding: $space-s 0

.subscription-card__body-title
  color: $white
  font-size: rem(16)

.subscription-card__line
  align-items: center
  display: flex
  justify-content: space-between
  margin: $space-s 0

.subscription-card__important
  color: $white
  font-weight: bold

.subscription-card__separator
  background-color: $star-gray
  height: 2px

.subscription-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold

.subscription-card__payment_method_badge
  margin-left: 0
</style>
