<template>
  <div class="scholarship-tab">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    >
      <div
        ref="headerMoreBox"
        class="opa-icon__container scholarship-tab__header-more-box"
        tabindex="0"
        role="button"
      >
        <Popover
          class="scholarship-tab__header-more-popover"
          open-on="focus"
          :mirror-el="() => $refs.headerMoreBox"
          :toggleable-focus="true"
        >
          <Icon
            ref="more"
            class="opa-icon scholarship-tab__header-more-icon"
            svg-name="i-more-vertical"
          />
          <template slot="popover">
            <Tooltip vposition="down" hposition="right">
              <TooltipList :list="menuList">
                <template slot-scope="{ item }">
                  <a
                    class="scholarship-tab__menu-link"
                    :href="item.link"
                    target="_blank"
                    @click="menuClicked(item.key)"
                  >
                    <Icon
                      class="scholarship-tab__menu-icon"
                      :svg-name="item.icon"
                    />
                    {{ item.text }}
                  </a>
                </template>
              </TooltipList>
            </Tooltip>
          </template>
        </Popover>
      </div>
    </TabHeader>
    <div class="scholarship-tab__scroller">
      <div class="scholarship-tab__body">
        <template v-if="order.loading || offer.loading">
          <OrderInfoSkeleton />
        </template>
        <template v-else>
          <template v-if="orderData">
            <OrderCard
              class="order-info__card"
              :order="orderData"
              :follow-up="followUpData"
            />
          </template>
          <Alert
            v-if="isOutdated"
            class="spacer-bottom-st js-outdated-offer-alert"
            message="Oferta indisponível"
            type="error"
            :dismissable="false"
          />
          <Alert
            v-if="canShowNewOffer"
            class="spacer-bottom-st js-new-offer-alert"
            message="Nova oferta encontrada"
            type="warning"
            :dismissable="false"
          >
            <button
              class="scholarship-tab__alert-link"
              type="button"
              @click="openNewOffer()"
            >
              <span>Abrir</span>
            </button>
          </Alert>
          <div
            class="scholarship-tab__offer-container"
            :class="{
              'scholarship-tab__offer-container--outdated': isOutdated,
            }"
          >
            <div class="scholarship-tab__old-offer-filter">
              <ScholarshipInfo
                :data="offerData"
                :follow-up="followUpData"
                :order="orderData"
                :has-user="!!params.baseUserId || hasOrderId"
              />
              <template
                v-if="!isOutdated && hasCheckoutForOffer && !hasParcelamento"
              >
                <Button
                  v-if="canRegister"
                  class="scholarship-tab__register-order-button"
                  type="positive"
                  text="Registrar Ordem"
                  @click="registerOrderClicked()"
                />
                <Button
                  v-if="canStart"
                  class="scholarship-tab__start-order-button"
                  type="white"
                  text="Iniciar Ordem"
                  @click="startOrderClicked()"
                />
              </template>
            </div>
          </div>
          <Admission
            v-if="orderData && isAdmissionsAttendant"
            class="spacer-top-st"
            :order-id="orderData.id"
          />
        </template>
      </div>
    </div>
    <NavList
      v-if="params.showAllOrdersButton"
      :list="[
        {
          title: 'Todas as Matrículas',
          key: 'showAllOrders',
          titleAttribute: 'Todas as Matrículas',
        },
      ]"
      @navclick="navClicked"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';

import Admission from '@/opa-modules/admissions/components/Admission';
import Alert from '@/components/common/Alert';
import Button from '@/components/common/Button';
import CouponModel from '@/models/Coupon';
import FollowUpModel from '@/models/FollowUp';
import Icon from '@/components/common/Icon';
import NavList from '@/components/Profile/NavList';
import OfferModel from '@/models/Offer';
import OrderCard from '@/components/Profile/Scholarship/OrderCard';
import OrderInfoSkeleton from '@/components/Profile/Scholarship/OrderInfoSkeleton';
import OrderModel from '@/models/Order';
import OrderAPI from '@/api/orderAPI';
import Popover from '@/components/common/Popover';
import ScholarshipInfo from '@/components/Profile/Scholarship/ScholarshipInfo';
import TabHeader from '@/components/common/TabHeader';
import Tooltip from '@/components/common/Tooltip';
import TooltipList from '@/components/common/TooltipList';

export default {
  components: {
    TabHeader,
    ScholarshipInfo,
    Button,
    Icon,
    Tooltip,
    Popover,
    TooltipList,
    OrderCard,
    OrderInfoSkeleton,
    NavList,
    Admission,
    Alert,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('Order', [
      'offer',
      'order',
      'offerNames',
      'followUp',
      'coupon',
    ]),
    ...mapGetters('Permissions', [
      'isAdmissionsAttendant',
      'canReadOrderOnAdmin',
      'canDownloadPreEnrollmentCoupon',
      'canUpdateFollowUpStep',
      'canResendDigitalContract',
      'canUpdateEnrollmentSemester',
      'canActivateDigitalAdmission',
      'canActivateCoupon',
      'canUpdateAgreementState',
    ]),
    hasParcelamento() {
      return this.offerData.offer.hasParcelamento;
    },
    hasCheckoutForOffer() {
      if (this.orderData) return true;

      return this.offerData.offer.hasCheckout;
    },
    hasLogin() {
      return !!this.params.hasLogin;
    },
    hasOrderId() {
      return !!this.params.orderId;
    },
    offerData() {
      return this.offer.result;
    },
    orderData() {
      return this.order.result;
    },
    followUpData() {
      return this.followUp;
    },
    isFreePreEnrollment() {
      return FollowUpModel.isFreePreEnrollment(this.followUp);
    },
    menuList() {
      if (!this.offerData) return [];

      return [
        {
          icon: 'i-eye',
          text: 'Ver Oferta no Site',
          key: 'queroBolsaLink',
          link: get(this, 'offerData.offer.url'),
          disable: OfferModel.isOutdated(this.offerData.offer),
        },
        {
          icon: 'i-receipt',
          text: 'Ver Ordem no Admin',
          key: 'orderAdminUrl',
          disable: !this.isOrderVisibleOnAdmin,
        },
        {
          icon: 'i-money',
          text: 'Ver Pagamentos',
          key: 'paymentsList',
        },
        {
          icon: 'i-warning',
          text: 'Solucionar Status Problema',
          key: 'activateCoupon',
          disable: !this.canShowStatusProblemFixer,
        },
        {
          icon: 'i-download',
          text: 'Baixar comprovante pré-matrícula',
          key: 'couponDownload',
          link: CouponModel.couponUrl(this.coupon),
          disable: !this.canShowCouponDownload,
        },
        {
          icon: 'i-insert-drive-file',
          text: 'Registrar B.O.',
          key: 'registerBO',
        },
        {
          icon: 'i-payment',
          text: 'Copiar Link de pagamento',
          key: 'copyPaymentLink',
          disable: !get(this, 'order.result.payment.payment_url'),
        },
        {
          icon: 'i-send-bill',
          text: 'Enviar cobrança',
          key: 'sendBill',
          disable: !this.canSendBill,
        },
        {
          icon: 'i-file-minus',
          text: 'Expirar boleto',
          key: 'expiresBoleto',
          disable: !this.canExpirePayment,
        },
        {
          icon: 'i-download',
          text: 'Baixar boleto',
          key: 'downloadBoleto',
          disable: !this.hasBoleto,
        },
        {
          icon: 'i-warning',
          text: 'Reembolsos e voucher',
          key: 'refundRequest',
          disable: !this.canRequestRefund,
        },
        {
          icon: 'i-wallet',
          text: 'Cancelar Reembolso',
          key: 'refundRequestCancel',
          disable: !this.hasRefundRequestOpen,
        },
        {
          icon: 'i-authorize-ticket',
          text: 'Autorização manual de boleto',
          key: 'manualTicketAuthorization',
          disable: !this.canManualTicketAuthorization,
        },
        {
          icon: 'i-share-forward',
          text: 'Enviar confirmação de compra',
          key: 'sendPurchaseConfirmationEmail',
          disable: !this.hasValidCoupon,
        },
        {
          icon: 'i-share-forward',
          text: 'Reenviar e-mail de dados de reembolso',
          key: 'sendRefundEmail',
          disable: !this.hasPendingRefund,
        },
        {
          icon: 'i-update-ticket',
          text: 'Atualizar dados da conta',
          key: 'updateTicket',
          disable: !this.canUpdateTicket,
        },
        {
          icon: 'i-flag',
          text: 'Solicitar Auditoria',
          key: 'auditRequest',
          disable: !this.canRequestAudit,
        },
        {
          icon: 'i-school',
          text: 'Iniciar matrícula direta',
          key: 'startDirectEnrollment',
          disable: !this.canStartDirectEnrollment,
        },
        {
          icon: 'i-edit',
          text: 'Atualizar Status da Matrícula',
          key: 'updateFollowUpStep',
          disable: !this.canShowUpdateFollowUpStep,
        },
        {
          icon: 'i-send',
          text: 'Reenviar Contrato Digital',
          key: 'resendDigitalContract',
          disable: !this.canShowResendDigitalContract,
        },
        {
          icon: 'i-calendar',
          text: 'Alterar Semestre',
          key: 'updateEnrollmentSemester',
          disable: !this.canShowUpdateEnrollmentSemester,
        },
        {
          icon: 'i-school',
          text: 'Ativar Admissão Digital',
          key: 'activateDigitalAdmission',
          disable: !this.canShowActivateDigitalAdmission,
        },
        {
          icon: 'i-reports',
          text: 'Atualizar Status do Contrato',
          key: 'updateAgreementState',
          disable: !this.canShowUpdateAgreementState,
        },
      ].filter(x => !x.disable);
    },
    canSendBill() {
      if (!this.orderData) return false;
      return OrderModel.canPay(this.orderData, this.offerData.offer);
    },
    canExpirePayment() {
      if (!this.orderData) return false;
      return OrderModel.canExpirePayment(this.orderData);
    },
    canStart() {
      return this.params.baseUserId && !this.orderData;
    },
    canRegister() {
      if (!this.hasLogin) return false;

      if (this.orderData) {
        return OrderModel.canRegister(this.orderData, this.offerData.offer);
      }
      return true;
    },
    canRequestAudit() {
      if (!this.orderData) return false;
      return OrderModel.hasValidPayment(this.orderData);
    },
    canStartDirectEnrollment() {
      if (!this.offerData || !this.orderData) return false;
      return (
        this.orderData.status === 'registered' &&
        OfferModel.isFreePreEnrollment(this.offerData.offer)
      );
    },
    hasBoleto() {
      if (!this.orderData) return false;
      return OrderModel.hasBoleto(this.orderData);
    },
    hasPaid() {
      return this.orderData && this.orderData.paid_at;
    },
    hasRefundRequestOpen() {
      return this.orderData && this.orderData.status === 'refund_requested';
    },
    canRequestRefund() {
      const isFreePreEnrollment =
        this.followUp && this.followUp.data['free_pre_enrollment'];

      const isAcceptableStatus =
        this.orderData &&
        ['paid', 'refund_rejected', 'membership_canceled'].includes(
          this.orderData.status
        );

      return !isFreePreEnrollment && isAcceptableStatus;
    },
    canUpdateTicket() {
      if (!this.orderData) return false;
      return OrderModel.canUpdateTicket(this.orderData);
    },
    canManualTicketAuthorization() {
      if (!this.orderData) return false;
      return OrderModel.canManualTicketAuthorization(this.orderData);
    },
    isOutdated() {
      return (
        (OfferModel.isOutdated(this.offerData.offer) ||
          OrderModel.isOutdated(this.orderData)) &&
        !OrderModel.isPaidOrAlmostPaid(this.orderData)
      ); //
    },
    newOffer() {
      return this.offerData.offer.newOffer;
    },
    canShowNewOffer() {
      return this.newOffer && this.isOutdated;
    },
    hasValidCoupon() {
      if (!this.orderData) return false;
      return OrderModel.hasValidCoupon(this.orderData);
    },
    hasPendingRefund() {
      if (!this.orderData) return false;
      return OrderModel.hasPendingRefund(this.orderData);
    },
    isOrderVisibleOnAdmin() {
      return this.orderData && this.canReadOrderOnAdmin;
    },
    canShowCouponDownload() {
      return this.isCouponEnabled && this.canDownloadPreEnrollmentCoupon;
    },
    isCouponEnabled() {
      return this.coupon && this.coupon.status === 'enabled';
    },
    canShowUpdateFollowUpStep() {
      return this.canUpdateFollowUpStep && this.followUpData;
    },
    canShowResendDigitalContract() {
      return (
        this.canResendDigitalContract &&
        this.followUpData &&
        this.followUpData.kind === 'digital' &&
        !this.followUpData.contract_accepted
      );
    },
    canShowUpdateEnrollmentSemester() {
      return (
        this.orderData &&
        this.canUpdateEnrollmentSemester &&
        OrderModel.hasValidCoupon(this.orderData)
      );
    },
    canShowActivateDigitalAdmission() {
      return (
        this.canActivateDigitalAdmission &&
        this.followUpData &&
        this.followUpData.kind === 'regular'
      );
    },
    canShowStatusProblemFixer() {
      return (
        this.orderData &&
        OrderModel.hasStatusProblem(this.orderData) &&
        this.canActivateCoupon
      );
    },
    canShowUpdateAgreementState() {
      return (
        this.canUpdateAgreementState &&
        this.followUpData &&
        this.followUpData.kind === 'digital' &&
        !this.followUpData.contract_accepted
      );
    },
  },
  watch: {
    'params.uuid'() {
      this.fetchData();
    },
    'params.orderId'() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('Order', [
      'fetchOffer',
      'fetchOrder',
      'registerOrderClicked',
      'showAllOrdersClicked',
      'updateOfferClicked',
    ]),
    ...mapActions('Order', { dispatchStartOrderClicked: 'startOrderClicked' }),
    ...mapActions('RightPanel', ['tabChange']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    menuClicked(key) {
      switch (key) {
        case 'registerBO':
          return this.tabChange({ key: 'register-bo' });

        case 'copyPaymentLink':
          return this.$copyText(this.order.result.payment.payment_url);

        case 'sendBill':
          return this.tabChange({ key: 'send-bill' });

        case 'expiresBoleto':
          return this.tabChange({ key: 'expires-boleto' });

        case 'downloadBoleto':
          return window.open(
            `/download_boleto?id=${this.orderData.id}`,
            '_blank'
          );

        case 'refundRequest':
          return this.tabChange({ key: 'refund-request' });

        case 'refundRequestCancel':
          return this.tabChange({ key: 'refund-request-cancel' });

        case 'sendPurchaseConfirmationEmail':
          return this.tabChange({ key: 'send-purchase-confirmation-email' });

        case 'paymentsList':
          return this.tabChange({ key: 'payments-list' });

        case 'activateCoupon':
          return this.activateCoupon();

        case 'updateTicket':
          return this.tabChange({ key: 'update-ticket' });

        case 'manualTicketAuthorization':
          return this.tabChange({ key: 'manual-ticket-authorization' });

        case 'sendRefundEmail':
          return this.tabChange({ key: 'send-refund-email' });

        case 'auditRequest':
          return this.tabChange({ key: 'audit-request' });

        case 'orderAdminUrl':
          return window.open(OrderModel.adminUrl(this.orderData));

        case 'startDirectEnrollment':
          return this.startDirectEnrollment();

        case 'updateFollowUpStep':
          return this.tabChange({ key: 'update-follow-up-step' });

        case 'resendDigitalContract':
          return this.tabChange({ key: 'resend-digital-contract' });

        case 'updateEnrollmentSemester':
          return this.tabChange({ key: 'update-enrollment-semester' });

        case 'activateDigitalAdmission':
          return this.tabChange({ key: 'activate-digital-admission' });

        case 'updateAgreementState':
          return this.tabChange({ key: 'update-agreement-state' });

        default:
          return null;
      }
    },
    async startDirectEnrollment() {
      try {
        await OrderAPI.startDirectEnrollment({
          orderId: this.orderData.id,
        });

        this.notifySuccess('Matrícula iniciada com sucesso!');
        this.fetchData();
      } catch (error) {
        this.notifyError(
          'A solicitação falhou. Por favor, contate o time de matrícula direta.'
        );
      }
    },
    async activateCoupon() {
      try {
        await OrderAPI.activateCoupon({
          orderId: this.orderData.id,
        });

        this.notifySuccess('Status da Bolsa atualizado com sucesso!');
        this.fetchData();
      } catch (error) {
        this.notifyError(
          error.response.data?.message ||
            'Ocorreu um erro inesperado na atualização do status da bolsa.'
        );
      }
    },
    navClicked({ key }) {
      if (key === 'showAllOrders') {
        this.showAllOrdersClicked();
      }
    },
    fetchData() {
      const { uuid, orderId, baseUserId } = this.params;

      if (uuid) {
        this.fetchOffer({ uuid, baseUserId });
      } //
      else if (orderId) {
        this.fetchOrder({ orderId });
      }
    },
    async startOrderClicked() {
      const order = await this.dispatchStartOrderClicked();

      if (order) {
        this.tabChange({
          key: 'scholarship',
          params: {
            orderId: order.id,
            baseUserId: this.params.baseUserId,
            showAllOrdersButton: this.params.showAllOrdersButton,
            hasLogin: this.hasLogin,
          },
          replace: true,
        });
      }
    },
    openNewOffer() {
      this.tabChange({
        key: 'scholarship',
        params: {
          uuid: this.newOffer.uuid,
          baseUserId: this.params.baseUserId,
          showAllOrdersButton: this.params.showAllOrdersButton,
          hasLogin: this.hasLogin,
        },
      });
    },
  },
};
</script>
<style lang="sass">
.scholarship-tab
  line-height: normal
  display: flex
  flex-direction: column
  height: 100vh

.scholarship-tab__scroller
  flex-grow: 1
  overflow: auto
  background-color: $space-gray

.scholarship-tab__body
  padding: $space-st

.scholarship-tab__start-order-button
  margin-top: $space-m
  width: 100%

.scholarship-tab__register-order-button
  margin-top: $space-m
  width: 100%

.scholarship-tab__header-more-box
  padding: 0 $space-m

.scholarship-tab__header-more-popover
  display: flex

.scholarship-tab__menu-link
  color: $silver-gray !important
  white-space: nowrap
  display: flex
  align-items: center
  padding: $space-s $space-st
  text-decoration: none

.scholarship-tab__menu-icon
  margin-right: $space-m

.scholarship-tab__offer-container--outdated
  position: relative
  .scholarship-tab__old-offer-filter
    opacity: 0.3
    pointer-events: none

.scholarship-tab__old-offer-modal
  position: absolute
  bottom: 200px
  left: 50%
  transform: translateX(-50%)
  display: flex
  flex-direction: column
  align-items: center
  height: 200px
  width: 236px
  padding: $space-m
  background-color: $black-gray
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50)
  border-radius: 4px

.scholarship-tab__warning-icon
  fill: $primary-warning
  +square(32px)
  margin-top: 20px

.scholarship-tab__old-offer-title
  margin-top: 20px
  margin-bottom: $space-xl
  font-size: rem(18)
  font-weight: bold

.scholarship-tab__update-offer
  margin-bottom: $space-st
  width: 100%

.scholarship-tab__alert-link
  color: $black-gray
  font-weight: bold
  margin-right: $space-m
</style>
