<template>
  <Card
    class="offer-card"
    :show-footer-details="hasUser && !isFreePreEnrollment"
  >
    <template slot="header">
      <div class="offer-card__header">
        <div class="offer-card__header-item">
          <span class="offer-card__big-text">Pré-matrícula</span>
          <Badge
            v-if="remainingSeatsText"
            class="offer-card__badge"
            type="error"
          >
            {{ remainingSeatsText }}
          </Badge>
        </div>
      </div>
    </template>
    <template v-if="isFreePreEnrollment">
      <div class="offer-card__body offer-card__body--only-text">
        Não há pré-matrícula (Matrícula Direta)
      </div>
    </template>
    <!-- prettier-ignore -->
    <template v-else-if="!hasUser">
      <div class="offer-card__body offer-card__body--only-text">
        Para visualizar o valor da pré-matrícula, faça uma busca com o cadastro de um aluno.
      </div>
    </template>
    <template v-else-if="!hasPreEnrollmentFee">
      <div class="offer-card__body offer-card__body--only-text">
        Para este aluno, não existe registro de pré matrícula
      </div>
    </template>
    <template v-else>
      <div class="offer-card__body">
        <span v-if="offer.saleCampaign">Valor sem desconto</span>
        <div v-else>
          <div class="offer-card__important--content">
            <div v-if="order && order.isOrderPriceAbtestProposal">
              <div class="offer-card__important--method">
                <span class="offer-card__important">Cartão de Crédito</span>
                <span class="offer-card__important">
                  {{ (offer.preEnrollmentFee.value * 1.1) | brlcurrency }}
                </span>
              </div>
            </div>
            <div v-else>
              <div class="offer-card__important--method">
                <span class="offer-card__important">
                  Cartão de Crédito (-10%)
                </span>
                <span class="offer-card__important">
                  {{ offer.preEnrollmentFee.value | brlcurrency }}
                </span>
              </div>
            </div>
            <div>
              <div class="offer-card__important--method">
                <span class="offer-card__important">PIX</span>
                <span class="offer-card__important">
                  {{ (offer.preEnrollmentFee.value * 1.1) | brlcurrency }}
                </span>
              </div>
            </div>
            <div>
              <div class="offer-card__important--method">
                <span class="offer-card__important">Boleto</span>
                <span class="offer-card__important">
                  {{ (offer.preEnrollmentFee.value * 1.1) | brlcurrency }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="offer.saleCampaign">
          <div class="offer-card__line offer-card__line--spaced">
            <span> Desconto {{ offer.campaign }} </span>
            <span>
              {{ saleCampaignDiscount | brlcurrency }}
            </span>
          </div>
          <div class="offer-card__separator" />
          <div class="offer-card__line offer-card__line--spaced">
            <span class="offer-card__important">
              Valor com desconto {{ offer.campaign }}
            </span>
            <span class="offer-card__important">
              {{ feeWithSaleCampaignValue | brlcurrency }}
            </span>
          </div>
        </div>
      </div>
      <template slot="infoFooter">
        <div class="offer-card__line offer-card__line--spaced">
          <span>Receita Ensino Superior</span>
          <span>
            {{ offer.receitaIes | brlcurrency }}
          </span>
        </div>
        <template v-if="showCanibalizationWarning">
          <div class="offer-card__separator" />
          <div class="offer-card__line offer-card__line--spaced">
            <span>Comissão reduzida</span>
            <span>
              {{ canibalizationRevenue | brlcurrency }}
            </span>
          </div>
        </template>
      </template>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import FollowUpModel from '@/models/FollowUp';

export default {
  components: {
    Card,
    Badge,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
    followUp: {
      type: Object,
      default: null,
    },
    hasUser: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    isFreePreEnrollment() {
      if (this.followUp) {
        return FollowUpModel.isFreePreEnrollment(this.followUp);
      }
      return this.offer.freePreEnrollment;
    },
    hasPreEnrollmentFee() {
      return this.offer && this.offer.preEnrollmentFee;
    },
    canibalizationRevenue() {
      return this.offer.receitaIes * 0.1;
    },
    showCanibalizationWarning() {
      return !this.offer.showOnMainSearch;
    },
    saleCampaignDiscount() {
      return this.offer.preEnrollmentFee.discount_value;
    },
    remainingSeatsText() {
      const remainingSeats = this.offer.remainingSeats;
      if (typeof remainingSeats === 'string') return remainingSeats;
      if (typeof remainingSeats === 'number') {
        if (remainingSeats == 1) return '1 VAGA RESTANTE';
        if (remainingSeats <= 5) return `${remainingSeats} VAGAS RESTANTES`;
      }
      return null;
    },
    feeWithSaleCampaignValue() {
      return this.offer.preEnrollmentFee.value - this.saleCampaignDiscount;
    },
  },
};
</script>

<style lang="sass" scoped>
.offer-card
  margin-bottom: $space-st
  color: $silver

.offer-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.offer-card__badge
  position: absolute
  right: 0

.offer-card__body
  color: $silver-gray
  padding: $space-s 0

.offer-card__body--only-text
  padding: $space-m 0

.offer-card__line
  margin: $space-s 0
  align-items: center
  display: flex

.offer-card__line--spaced
  justify-content: space-between

.offer-card__important--content
  display: flex
  flex-direction: column

.offer-card__important--method
  display: flex
  gap: $space-s
  margin-bottom: $space-s
  justify-content: space-between

.offer-card__important
  font-weight: bold
  color: $white

.offer-card__separator
  background-color: $star-gray
  height: 2px

.offer-card__header
  padding: $space-s 0
  position: relative

.offer-card__header-item
  display: flex
  justify-content: space-between

  &:not(:last-child)
    margin-bottom: $space-s

.offer-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold
</style>
