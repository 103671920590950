<template>
  <div class="radio">
    <label class="radio__label" :for="choice">
      <CheckMark :value="value === choice" type="radio" />{{ label }}
      <input
        :id="choice"
        class="radio__input"
        type="radio"
        :name="name"
        :value="choice"
        @change="$emit('input', $event.target.value)"
        @click="$emit('click')"
      />
    </label>
  </div>
</template>

<script>
import CheckMark from '@/components/common/CheckMark';

export default {
  components: {
    CheckMark,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    choice: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.radio
  display: flex
  line-height: normal
  margin-left: $space-xxs
  margin-bottom: $space-m
  user-select: none
  color: $silver-gray

.radio__input
  position: absolute
  opacity: 0
  width: 0
  height: 0

.radio__label
  display: flex
  cursor: pointer

.radio__label:focus-within
  outline: auto $blue-qb3 2px
</style>
