<template>
  <button ref="selectBox" class="sidebar-user">
    <Popover class="sidebar-user__popover" :mirror-el="() => $refs.selectBox">
      <img
        class="sidebar-user__avatar"
        src="/images/quebo.png"
        :alt="name || 'Guia'"
      />
      <template slot="popover">
        <Tooltip special-position="down">
          <div class="sidebar-user__item" @click="logout">
            Sair do sistema
          </div>
        </Tooltip>
      </template>
    </Popover>
  </button>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Amplitude from '@/common_modules/tools/Amplitude';
import Rollbar from '@/common_modules/tools/Rollbar';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Tooltip,
    Popover,
  },

  data() {
    return {
      adminLoaded: false,
    };
  },

  computed: {
    ...mapState('Admin', ['name', 'email']),
    ...mapGetters('Admin', ['getId']),
  },

  async created() {
    this.adminLoaded = true;

    if (process.env.NUXT_ENV_BUILD === 'development') return;
    this.dataLayerRegister();
    Rollbar.configure(this.getId, this.email);
    this.$faro.api.setUser({ id: this.getId, email: this.email });
    Amplitude.identify(this.getId, {
      email: this.email,
      name: this.name,
    });
  },

  methods: {
    dataLayerRegister() {
      window.dataLayer.push({
        event: 'Authentication',
        userId: this.getId,
        userEmail: this.email,
      });
    },
    logout() {
      localStorage.removeItem('token');
      window.location.href = '/login';
    },
  },
};
</script>

<style lang="sass" scoped>
.sidebar-user
  background: none
  border: 0
  cursor: pointer
  margin-top: $space-s
  padding: 0
  position: relative
  width: 100%
  +flex-centered
  outline: none
  color: $silver-gray

.sidebar-user__avatar
  +circle(40px)
  border: 2px solid green
  border-radius: 100%
.sidebar-user__popover
  text-align: left

.sidebar-user__item
  padding: $space-m $space-st
  white-space: nowrap
  display: inline-block
</style>
