<template>
  <div class="update-agreement">
    <TabHeader title="Atualizar status do Contrato" />
    <form class="update-agreement__body" @submit.prevent="submit">
      <div class="opa__card-body">
        <div class="update-agreement__text">
          Essa opção atualiza o status do contrato.
        </div>
        <div class="update-agreement__info">
          A situação do contrato atual será atualizada conforme o status do
          ClickSign, portanto só será alterado caso o status atual não
          corresponda ao que consta no ClickSign.
        </div>
        <Button
          class="update-agreement__button"
          type="positive"
          :text="'Atualizar'"
          :submit="true"
        />
      </div>
    </form>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapState, mapActions } from 'vuex';
import FollowUpAPI from '@/api/followUpAPI';

export default {
  components: {
    TabHeader,
    Button,
  },
  computed: {
    ...mapState('Order', ['followUp']),
    followUpData() {
      return this.followUp;
    },
  },
  methods: {
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),

    async submit() {
      try {
        await FollowUpAPI.updateAgreementState({
          followUpId: this.followUp.id,
        });

        this.notifySuccess('Status do contrato atualizado com sucesso');
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao atualizar o status do contrato. Por favor, contate o suporte.';
        this.notifyError(message);
      }

      this.tabGoBack();
    },
  },
};
</script>

<style>
.update-agreement__body {
  padding: 6%;
}

.update-agreement__button {
  width: 100%;
}

.update-agreement__info {
  padding-top: 5%;
  display: flex;
  font-size: rem(18);
  color: white;
  padding-bottom: 5%;
}

.update-agreement__text {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  color: white;
}
</style>
