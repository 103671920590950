<template>
  <div class="choose-user">
    <TabHeader :title="`${users.length} contas encontradas`" />
    <div class="choose-user__container">
      <Accordion :list="users" :initialize-open="0">
        <template slot="head" slot-scope="{ item, open }">
          <div
            class="choose-user__header"
            :class="{ 'choose-user__header--open': open }"
          >
            <Avatar
              class="choose-user__avatar"
              :has-login="item.userInfo.hasLogin"
            />
            <div class="choose-user__header-info">
              <div class="choose-user__name">
                {{ item.userInfo.name }}
              </div>
              <span>{{ item.userInfo.phoneNumber }}</span>
            </div>
            <Icon svg-class="choose-user__icon" svg-name="i-chevron-right" />
          </div>
        </template>
        <template slot="body" slot-scope="{ item }">
          <div class="choose-user__body">
            <OrderCard
              v-if="item.order"
              class="choose-user__card"
              :order="item.order"
            />
            <CourseCard
              v-if="item.course"
              class="choose-user__card"
              :course="item.course"
              :offer="item.offer"
            />
            <Card class="choose-user__card">
              <template slot="header">
                <div class="choose-user__card-header">
                  Contatos e Endereço
                </div>
              </template>
              <div class="choose-user__card-body">
                <template v-for="info in getUserInfoList(item.userInfo)">
                  <div
                    v-if="info === 'space'"
                    :key="info"
                    class="choose-user__card-space"
                  />
                  <div v-else :key="info[0]" class="choose-user__card-row">
                    <span class="choose-user__card-caption">
                      {{ info[0] }}:
                    </span>
                    <span class="choose-user__card-text">
                      {{ info[1] }}
                    </span>
                  </div>
                </template>
              </div>
            </Card>
            <Button
              v-if="canChangeBaseUser && !isChoosingProfile"
              text="Selecionar esta conta"
              @click="submit(item)"
            />
          </div>
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion';
import TabHeader from '@/components/common/TabHeader';
import Avatar from '@/components/common/Avatar';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import OrderCard from '@/components/Profile/Scholarship/OrderCard';
import CourseCard from '@/components/Profile/Scholarship/CourseCard';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    Accordion,
    Avatar,
    Icon,
    OrderCard,
    CourseCard,
    Card,
    Button,
  },
  props: {
    params: {
      //params = { searchBy,  value }
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      users: [],
    };
  },
  computed: {
    ...mapGetters('Profile', ['canChangeBaseUser', 'isChoosingProfile']),
  },
  async created() {
    this.users = await this.searchUserFull(this.params);
  },
  methods: {
    ...mapActions('Profile', ['searchUserFull', 'chooseUser']),
    getUserInfoList(info) {
      return [
        ['CPF', info.cpf],
        ['E-mail', info.email],
        ['Celular', info.phoneNumber],
        'space',
        ['Nome completo', info.name],
        ['Data de nascimento', info.birthday],
        ['CEP', info.postalCode],
        ['Estado', info.state],
        ['Cidade', info.city],
        ['Bairro', info.neighborhood],
        ['Rua', info.address],
        ['Número', info.addressNumber],
        ['Complemento', info.addressComplement],
      ];
    },
    submit(item) {
      this.chooseUser(item.userInfo.id);
    },
  },
};
</script>
