<template>
  <div class="refund-request-cancel">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <form class="refund-request-cancel__body" @submit.prevent="submit">
      <div class="opa__card-body">
        <div class="refund-request-cancel__title">
          Deseja realmente cancelar a solicitação de reembolso?
        </div>
        <Button
          class="refund-request-cancel__button"
          type="positive"
          :text="submitting ? 'Aguarde...' : 'Prosseguir com o cancelamento'"
          :submit="true"
          :loading="submitting"
        />
      </div>
    </form>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Rollbar from '@/common_modules/tools/Rollbar';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
  },
  data: () => ({
    submitting: false,
  }),
  computed: {
    ...mapGetters('Order', ['offerNames']),
  },
  methods: {
    ...mapActions('Order', ['submitRefundRequestCancelClicked']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    async submit() {
      this.submitting = true;

      try {
        await this.submitRefundRequestCancelClicked();

        this.notifySuccess(
          'O cancelamento do reembolso foi realizado com sucesso!'
        );
      } catch (error) {
        Rollbar.error('RefundRequestCancel | error to submit', { error });
        this.$faro.api.pushError(error, {
          context: {
            message: 'RefundRequestCancel | error to submit',
          },
        });

        this.notifyError(
          'O cancelamento do reembolso falhou. Por favor, contate o suporte.'
        );
      }

      this.submitting = false;
    },
  },
};
</script>

<style lang="sass">
.refund-request-cancel
  height: 100%
  background-color: $space-gray

.refund-request-cancel__body
  padding: $space-st $space-st

.refund-request-cancel__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.refund-request-cancel__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
