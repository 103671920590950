<template>
  <div class="profile-actions">
    <Button
      v-keycut:r.click=""
      class="profile-actions__btn"
      :disabled="!canRegister"
      title="\+R"
      type="action"
      @click="registerClicked"
    >
      <div class="profile-actions__btn-inner">
        <Icon class="profile-actions__icon" svg-name="i-balloon-checked" />
        <span>Registrar</span>
      </div>
    </Button>
    <Button
      v-keycut:t.click=""
      class="profile-actions__btn"
      :disabled="!canTransfer"
      title="\+T"
      type="action"
      @click="transferClicked"
    >
      <div class="profile-actions__btn-inner">
        <Icon class="profile-actions__icon" svg-name="i-forward" />
        <span>Transferir</span>
      </div>
    </Button>
    <Button
      v-keycut:b.click=""
      class="profile-actions__btn"
      :disabled="canUseOnlyBasicTools"
      title="\+B"
      type="action"
      @click="searchCourseClicked"
    >
      <div class="profile-actions__btn-inner">
        <Icon class="profile-actions__icon" svg-name="i-school" />
        <span>Buscar Curso</span>
      </div>
    </Button>
    <div
      ref="profileMore"
      class="opa-icon__container"
      tabindex="0"
      role="button"
    >
      <Popover
        class="profile-actions-more-popover"
        open-on="focus"
        :mirror-el="() => $refs.profileMore"
        :toggleable-focus="true"
      >
        <Icon
          ref="more"
          class="opa-icon profile-actions-more-icon"
          svg-name="i-more-vertical"
        />
        <template slot="popover">
          <Tooltip vposition="down" hposition="right">
            <TooltipList :list="menuList">
              <template slot-scope="{ item }">
                <a
                  class="profile-actions__menu-link"
                  @click="menuClicked(item.key)"
                >
                  <Icon
                    class="profile-actions__menu-icon"
                    :svg-name="item.icon"
                  />
                  {{ item.text }}
                </a>
              </template>
            </TooltipList>
          </Tooltip>
        </template>
      </Popover>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';
import TooltipList from '@/components/common/TooltipList';

export default {
  components: {
    Button,
    Icon,
    Tooltip,
    Popover,
    TooltipList,
  },
  computed: {
    ...mapGetters('Profile', [
      'user',
      'canTransfer',
      'canRegister',
      'hasLogin',
    ]),
    ...mapGetters('Permissions', [
      'canMergeUsers',
      'canManageVoucher',
      'canUseOnlyBasicTools',
      'canDeleteRegister',
    ]),
    ...mapState('Profile', ['profile']),
    menuList() {
      return [
        {
          icon: 'i-eye',
          text: 'Impersonar Aluno',
          key: 'impersonate',
          enabled: this.hasLogin,
        },
        {
          icon: 'i-people',
          text: 'Unificar Usuários',
          key: 'merge-user',
          enabled: this.canMergeUsers,
        },
        {
          icon: 'i-voucher',
          text: 'Vouchers do aluno',
          key: 'manage-voucher',
          enabled: this.canManageVoucher && this.hasVoucher,
        },
        {
          icon: 'i-lock',
          text: 'Redefinir Senha',
          key: 'password-recovery',
          enabled: this.canPasswordRecovery,
        },
        {
          icon: 'i-user-check',
          text: 'Reativar Conta',
          key: 'reactivate-user',
          enabled: this.canReactivateUser,
        },
        {
          icon: 'i-remove_circle',
          text: 'Bloquear Comunicações',
          key: 'block-communication',
          enabled: this.hasEmailOrPhone,
        },
        {
          icon: 'i-email',
          text: 'Criar Login',
          key: 'create-login',
          enabled: !this.hasEmailLogin,
        },
        {
          icon: 'i-email',
          text: 'Atualizar Login',
          key: 'update-email-login',
          enabled: this.hasEmailLogin,
        },
        {
          icon: 'i-close',
          text: 'LGPD - Excluir Cadastro',
          key: 'lgpd-delete-register',
          enabled: this.canDeleteRegister,
        },
        {
          icon: 'i-receipt',
          text: 'Vincular Oferta Restrita',
          key: 'restricted-offer',
          enabled: true,
        },
      ].filter(x => x.enabled);
    },
    hasVoucher() {
      return this.profile.hasVoucher;
    },
    canPasswordRecovery() {
      const logins = this.profile?.logins || [];

      return logins.some(item => !!item.login_email);
    },
    canReactivateUser() {
      return this.profile?.destroyedAt;
    },
    hasEmailOrPhone() {
      const emails = this.profile?.emails || [];
      const phones = this.profile?.phones || [];

      return emails.length > 0 || phones.length > 0;
    },
    hasEmailLogin() {
      const logins = this.profile?.logins || [];

      return logins.some(element => element.type === 'EmailLogin');
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    ...mapActions('Profile', [
      'searchCourseClicked',
      'transferClicked',
      'registerClicked',
    ]),
    menuClicked(key) {
      if (key === 'impersonate') {
        return window.open(`/impersonate/${this.profile.id}`, '_blank');
      }

      return this.tabChange({ key });
    },
  },
};
</script>

<style lang="sass">
.profile-actions
  display: flex
  padding: 0 $space-m
  justify-content: space-between

.profile-actions__btn
  margin-bottom: $space-xxs

.profile-actions__btn-inner
  display: flex
  align-items: center

  & > span
    margin-left: $space-xs

.profile-actions__icon
  +square(15px)

.profile-actions-more-popover
  display: flex

.profile-actions__menu-link
  color: $silver-gray !important
  white-space: nowrap
  display: flex
  align-items: left
  padding: $space-s $space-st
  margin-right: $space-st
  text-decoration: none

.profile-actions__menu-icon
  margin-right: $space-m
</style>
