<template>
  <div>
    <TabHeader title="Cadastro do aluno" />
    <div class="password-recovery__body">
      <span class="password-recovery__label opa-bold">
        Redefinir Senha
      </span>
      <span>
        Um e-mail para redefinição de senha da conta será enviado para
        {{ primaryEmail }}.
      </span>
      <Button
        class="register-user__submit"
        text="Enviar e-mail de redefinição"
        type="positive"
        @click="PasswordRecovery"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import Rollbar from '@/common_modules/tools/Rollbar';
import ProfileService from '@/assets/javascripts/services/ProfileService.js';
import TabHeader from '@/components/common/TabHeader';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    TabHeader,
  },
  computed: {
    ...mapGetters('Order', ['userEmails']),
    primaryEmail() {
      return this.userEmails[0];
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabGoBack']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    async PasswordRecovery() {
      try {
        await ProfileService.passwordRecovery(this.primaryEmail);
      } catch (error) {
        const message =
          'Ocorreu um problema ao enviar o e-mail de recuperação de senha.';
        Rollbar.error('PasswordRecovery | request error', { error });
        this.notifyError(message);
        this.tabGoBack();
        return;
      }
      const message = 'E-mail de recuperação de senha enviado com sucesso!';
      this.notifySuccess(message);
      this.tabGoBack();
    },
  },
};
</script>

<style lang="sass">
.password-recovery__label
  font-size: 1.5em

.password-recovery__body
  display: flex
  flex-direction: column
  margin-top: $space-m
  padding: $space-m $space-st
</style>
