<template>
  <div class="send-refund-email">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <div class="send-refund-email__body">
      <div class="send-refund-email__title">
        Reenviar e-mail de dados de reembolso
      </div>
      <EmailSelector v-model="emailSelectorValue" />
      <Button
        class="send-refund-email__button"
        type="positive"
        :disabled="emailSelectorValue.hasErrors"
        text="Enviar email"
        submit="submit"
        @click="doSendRefundEmail"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import EmailSelector from '@/components/common/EmailSelector.vue';
import TabHeader from '@/components/common/TabHeader';
import OrderAPI from '@/api/orderAPI';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    EmailSelector,
    TabHeader,
  },
  data() {
    return {
      emailSelectorValue: {},
    };
  },
  computed: {
    ...mapGetters('Order', ['offerNames', 'order']),
  },
  methods: {
    ...mapActions('Notifications', ['notifySuccess']),
    ...mapActions('StoreManager', ['notify']),
    ...mapActions('RightPanel', ['tabGoBack']),
    async doSendRefundEmail() {
      const { emails } = this.emailSelectorValue;
      const { id: orderId } = this.order.result;

      try {
        await OrderAPI.sendRefundEmail({ emails, orderId });
      } catch (error) {
        this.handleError(error, emails, orderId);
        return;
      }

      this.notifySuccess('E-mail de dados do reembolso enviado com sucesso');
      this.tabGoBack();
    },
    handleError(error, emails, orderId) {
      const {
        response: { data },
      } = error;
      let errors = null;

      if (data.message && !data.message.startsWith('NÃO SUPORTADO')) {
        errors = {
          errorTitle: 'submitSendBillClicked | sendBill',
          errorDetails: { error, emails, orderId },
        };
      }

      this.notify({
        message: 'Erro ao enviar e-mail de dados de reembolso',
        errors,
      });
    },
  },
};
</script>

<style lang="sass">
.send-refund-email
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.send-refund-email__body
  flex-grow: 1
  overflow: auto
  padding: $space-st

.send-refund-email__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.send-refund-email__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
