<template>
  <div class="billing-details">
    <Card class="queropago-card">
      <template slot="header">
        <div class="queropago-card__header">
          <span>Cobrança</span>
          <Badge
            class="queropago-card__header-badge"
            :type="statusBadgeType(currentBilling)"
          >
            {{ currentBilling.translatedStatus }}
          </Badge>
        </div>
      </template>
      <div class="queropago-card__body">
        <div class="queropago-card__item">
          <strong>Mensalidade com desconto:</strong>
          <div class="queropago-card__item-content">
            {{ BRLCurrency(currentBilling.installmentValueWithDiscount) }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Mensalidade sem desconto:</strong>
          <div class="queropago-card__item-content">
            {{ BRLCurrency(currentBilling.installmentValueWithoutDiscount) }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Porcentagem de desconto:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.discountPercentage }}%
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Parcelas:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.billsCount }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Dia de vencimento:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.dueDay }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Semestre de ingresso:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.enrollmentSemester }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Origem:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.translatedOrigin }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Observações:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.observations }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Meio de pagamento:</strong>
          <div class="queropago-card__item-content">
            {{ currentBilling.translatedRecurrentPaymentMethod }}
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import QueropagoHelper from '@/helpers/QueropagoHelper';
import BRLCurrency from '@/assets/javascripts/formatters/BRLCurrency.js';

export default {
  components: {
    Card,
    Badge,
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
  },
  methods: {
    BRLCurrency,
    statusBadgeType(billing) {
      return QueropagoHelper.billingStatusBadgeType(billing);
    },
  },
};
</script>
