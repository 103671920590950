<template>
  <div class="payments-list">
    <TabHeader class="payments-list__header" title="Lista de Pagamentos" />
    <div v-if="paymentsBlank" class="payments-list__list--empty">
      Nenhum pagamento encontrado
    </div>
    <div v-else class="payments-list__list">
      <template v-for="payment in payments">
        <PaymentCard :key="payment.id" :payment="payment" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TabHeader from '@/components/common/TabHeader';
import PaymentCard from '@/components/Scholarship/PaymentCard';

export default {
  components: {
    PaymentCard,
    TabHeader,
  },
  computed: {
    ...mapState('Order', ['payments']),
    paymentsBlank() {
      return !this.payments || this.payments.length === 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.payments-list
  height: 100%
  display: flex
  flex-direction: column
  background: $space-gray

.payments-list__header
  flex-shrink: 0

.payments-list__list
  position: relative
  overflow-y: scroll
  overflow-y: overlay
  flex-grow: 1
  padding: $space-st

.payments-list__list--empty
  color: $white
  display: block
  font-size: rem(20)
  line-height: rem(22)
  margin-top: $space-xl
  text-align: center
  font-weight: bold
</style>
