<template>
  <Card v-if="admission.documents.length" show-footer-template>
    <template #header>
      <div class="admission-title admission-title--small">Lista</div>
    </template>

    <template #default>
      <div
        class="admission-card__content-wrapper admission-card__content-wrapper--spaced-s"
      >
        <template v-for="(document, index) in admission.documents">
          <template v-if="index > 0">
            <hr :key="`divider-${index}`" class="divider spacer-bottom-s" />
          </template>

          <div :key="`document-${index}`" class="document-list-item">
            <div>
              <div
                class="admission-text admission-text--xs admission-text--bold"
              >
                {{ document | docKindTranslate }}
              </div>

              <div
                v-if="document.statusDate"
                class="admission-text admission-text--xs admission-text--gray spacer-top-s"
              >
                {{ document.statusDate | momentformat('L') }}
              </div>

              <div
                v-if="document.status == 'rejected'"
                class="admission-text admission-text--xs admission-text--gray spacer-top-s"
              >
                Motivo: {{ document.rejectionReason }}
              </div>
            </div>

            <Badge
              class="admission-card__status-badge"
              :type="document | docStatusColor"
            >
              {{ document | docStatusTranslate }}
            </Badge>
          </div>
        </template>
      </div>
    </template>

    <template #footer>
      <button
        class="opa-card__btn opa-card__btn--right"
        type="button"
        @click="openQueroAlunos"
      >
        <span class="opa-card__btn-text">Quero Alunos</span>
        <Icon svg-name="i-chevron-right" svg-class="opa-card__btn-icon" />
      </button>
    </template>
  </Card>
</template>

<style lang="sass" scoped>
.document-list-item
  align-items: center
  display: flex
  justify-content: space-between

  &:not(:last-child)
    margin-bottom: $space-s
</style>

<script>
import Badge from '@/components/common/Badge';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';

import {
  docKindTranslate,
  docStatusColor,
  docStatusTranslate,
} from '@/assets/javascripts/data/admissions/document';

export default {
  components: {
    Badge,
    Card,
    Icon,
  },

  filters: {
    docKindTranslate,
    docStatusColor,
    docStatusTranslate,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },

  methods: {
    openQueroAlunos() {
      let admission_id = this.admission.id;
      window.open(
        `${process.env.QUERO_ALUNOS_HOST}/busca-pre-matriculas/${admission_id}`,
        '_blank'
      );
    },
  },
};
</script>
