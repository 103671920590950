<template>
  <div ref="form">
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="cpf">{{
        'CPF' | required(fullRequired)
      }}</label>
      <Cleave
        id="cpf"
        v-model="formData.cpf.value"
        class="register-user-body__input"
        type="text"
        name="cpf"
        placeholder="Ex: 123.456.789-00"
        autocomplete="off"
        :options="{
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        }"
        :raw="false"
        :disabled="!canShowUpdateCpf"
      />
      <Alert
        v-if="formData.cpf.alert"
        class="register-user-body__alert"
        :message="formData.cpf.alert.message"
        :type="formData.cpf.alert.type"
        :dismissable="false"
      >
        <button
          v-if="formData.cpf.alert.action"
          class="register-user-body__alert-link"
          type="button"
          @click="formData.cpf.alert.action"
        >
          <span>{{ formData.cpf.alert.actionMessage }}</span>
        </button>
      </Alert>
    </div>
    <div v-if="isCreateType" class="register-user-body__group">
      <label class="register-user-body__label" for="emailLogin">{{
        'Email (login)*'
      }}</label>
      <input
        id="emailLogin"
        v-model="formData.emailLogin.value"
        class="register-user-body__input"
        type="text"
        name="emailLogin"
        placeholder="Ex: jose.roberto@gmail.com"
        autocomplete="off"
      />
      <Alert
        v-if="formData.emailLogin.alert"
        class="register-user-body__alert"
        :message="formData.emailLogin.alert.message"
        :type="formData.emailLogin.alert.type"
        :dismissable="false"
      >
        <button
          v-if="formData.emailLogin.alert.action"
          class="register-user-body__alert-link"
          type="button"
          @click="formData.emailLogin.alert.action"
        >
          <span>{{ formData.emailLogin.alert.actionMessage }}</span>
        </button>
      </Alert>
    </div>
    <div v-if="!isCreateType" class="register-user-body__group">
      <label class="register-user-body__label" for="emails">{{
        'Emails' | required(fullRequired)
      }}</label>
      <component
        :is="uniques ? 'BlankInputList' : 'InputList'"
        v-model="formData.emails"
      >
        <template slot-scope="{ item, index }">
          <input
            id="emails"
            v-model="item.value"
            class="register-user-body__input"
            type="text"
            name="emails"
            :data-index="index"
            placeholder="Ex: jose.roberto@gmail.com"
            autocomplete="off"
          />
        </template>
        <template slot="bottom" slot-scope="{ item }">
          <Alert
            v-if="item.alert"
            class="register-user-body__alert"
            :message="item.alert.message"
            :type="item.alert.type"
            :dismissable="false"
          >
            <button
              v-if="item.alert.action"
              class="register-user-body__alert-link"
              type="button"
              @click="item.alert.action"
            >
              <span>{{ item.alert.actionMessage }}</span>
            </button>
          </Alert>
        </template>
      </component>
    </div>
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="phones">{{
        'Celular' | required(fullRequired)
      }}</label>
      <component
        :is="uniques ? 'BlankInputList' : 'InputList'"
        v-model="formData.phones"
      >
        <template slot-scope="{ item, index }">
          <Cleave
            id="phones"
            v-model="item.value"
            class="register-user-body__input"
            type="text"
            name="phones"
            :data-index="index"
            placeholder="Ex: (61) 9823-9900"
            autocomplete="off"
            :options="phoneMaskOptions()"
            :raw="false"
          />
        </template>
        <template slot="bottom" slot-scope="{ item }">
          <Alert
            v-if="item.alert"
            class="register-user-body__alert"
            :message="item.alert.message"
            :type="item.alert.type"
            :dismissable="false"
          >
            <button
              v-if="item.alert.action"
              class="register-user-body__alert-link"
              type="button"
              @click="item.alert.action"
            >
              <span>{{ item.alert.actionMessage }}</span>
            </button>
          </Alert>
        </template>
      </component>
    </div>
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="name">
        Nome completo *
      </label>
      <input
        id="name"
        v-model="formData.name.value"
        class="register-user-body__input"
        type="text"
        name="name"
        placeholder="Ex: José Roberto Rodrigues"
        autocomplete="off"
      />
      <Alert
        v-if="formData.name.alert"
        class="register-user-body__alert"
        :message="formData.name.alert.message"
        :type="formData.name.alert.type"
        :dismissable="false"
      />
    </div>
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="birthday">{{
        'Data de nascimento' | required(fullRequired)
      }}</label>
      <Cleave
        id="birthday"
        v-model="formData.birthday.value"
        class="register-user-body__input"
        type="text"
        name="birthday"
        placeholder="Ex: 10/02/1990"
        autocomplete="off"
        :options="{ date: true, datePattern: ['d', 'm', 'Y'] }"
        :raw="false"
      />
    </div>
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="postalCode">{{
        'CEP' | required(fullRequired)
      }}</label>
      <Cleave
        id="postalCode"
        v-model="formData.postalCode.value"
        class="register-user-body__input"
        type="text"
        name="postalCode"
        placeholder="Ex: 72930-200"
        autocomplete="off"
        :options="{ delimiter: '-', blocks: [5, 3], numericOnly: true }"
      />
    </div>
    <div class="register-user-body__address">
      <Icon
        v-if="loadingAddress"
        svg-name="i-engine"
        svg-class="register-user-body__loading-icon"
      />
      <div
        class="register-user-body__address-inputs"
        :class="{
          'register-user-body__address-inputs--loading': loadingAddress,
        }"
      >
        <div class="register-user-body__group">
          <label class="register-user-body__label" for="state">{{
            'Estado' | required(fullRequired)
          }}</label>
          <Select
            id="state"
            v-model="selectedState"
            class="register-user-body__select"
            placeholder="Selecione o estado"
            name="state"
            :list="states"
            label-property="name"
            @input="stanteInputEvent"
          />
        </div>
        <div class="register-user-body__group">
          <label class="register-user-body__label" for="city">{{
            'Cidade' | required(fullRequired)
          }}</label>
          <input
            id="city"
            v-model="formData.city.value"
            class="register-user-body__input"
            type="text"
            name="city"
            placeholder="Ex: São José dos Campos"
            autocomplete="off"
          />
        </div>
        <div class="register-user-body__group">
          <label class="register-user-body__label" for="neighborhood">{{
            'Bairro' | required(fullRequired)
          }}</label>
          <input
            id="neighborhood"
            v-model="formData.neighborhood.value"
            class="register-user-body__input"
            type="text"
            name="neighborhood"
            placeholder="Ex: Jardim Apollo"
            autocomplete="off"
          />
        </div>
        <div class="register-user-body__group">
          <label class="register-user-body__label" for="address">{{
            'Endereço' | required(fullRequired)
          }}</label>
          <input
            id="address"
            v-model="formData.address.value"
            class="register-user-body__input"
            type="text"
            name="address"
            placeholder="Ex: Avenida São João"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="addressNumber">{{
        'Número' | required(fullRequired)
      }}</label>
      <Cleave
        id="addressNumber"
        v-model="formData.addressNumber.value"
        class="register-user-body__input"
        type="text"
        name="addressNumber"
        placeholder="Ex: 1701"
        autocomplete="off"
        :options="{ numericOnly: true, blocks: [10] }"
      />
    </div>
    <div class="register-user-body__group">
      <label class="register-user-body__label" for="addressComplement">
        Complemento
      </label>
      <input
        id="addressComplement"
        v-model="formData.addressComplement.value"
        class="register-user-body__input"
        type="text"
        name="addressComplement"
        placeholder="Ex: Apto 44"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import ClickableIcon from '@/components/common/ClickableIcon';
import Alert from '@/components/common/Alert';
import InputList from '@/components/common/InputList';
import BlankInputList from '@/components/Profile/RegisterUserBody/BlankInputList';
import { stateCodeMap } from '@/assets/javascripts/data/stateCode';
import Select from '@/components/common/Select';

import formLib, {
  areFieldsOk,
  phoneMaskOptions,
} from '@/common_modules/formLib';
import getEventHandlers from '@/components/Profile/RegisterUserBody/eventHandlers';
import { isEmpty, without } from 'lodash';

import { mapActions, mapState, mapGetters } from 'vuex';
import Cleave from 'vue-cleave-component';

const states = Object.keys(stateCodeMap).map(key => {
  return { value: key, name: stateCodeMap[key] };
});

export default {
  components: {
    Icon,
    Cleave,
    Alert,
    ClickableIcon,
    BlankInputList,
    InputList,
    Select,
  },
  filters: {
    required: (value, apply) => value + (apply ? ' *' : ''),
  },
  props: {
    fullRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    uniques: {
      type: Boolean,
      required: false,
      default: false,
    },
    registerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      loadingAddress: false,
      selectedState: null,
      formDataKeys: [
        'cpf',
        'emails',
        'emailLogin',
        'phones',
        'name',
        'birthday',
        'postalCode',
        'state',
        'city',
        'neighborhood',
        'address',
        'addressNumber',
        'addressComplement',
      ],
      emptyProfile: {
        cpf: null,
        emails: [],
        emailLogin: null,
        phones: [],
        name: null,
        birthday: null,
        postalCode: null,
        state: null,
        city: null,
        neighborhood: null,
        address: null,
        addressNumber: null,
        addressComplement: null,
      },
      states,
    };
  },
  computed: {
    ...mapState('Profile', { storeProfile: 'profile' }),
    ...mapGetters('Permissions', ['canUpdateCpf']),
    profile() {
      return this.registerType == 'create'
        ? this.emptyProfile
        : this.storeProfile;
    },
    hasErrors() {
      if (isEmpty(this.formData)) return;

      const fieldsToCheck = [
        { key: 'emailLogin', required: this.isCreateType },
      ];

      if (this.fullRequired) {
        const fields = without(
          this.formDataKeys,
          'addressComplement',
          'emailLogin'
        );

        fieldsToCheck.push(...fields);
      } else {
        const fields = [
          { key: 'cpf', required: false },
          { key: 'name', required: true },
          { key: 'phones', required: false },
          { key: 'emails', required: !this.isCreateType },
        ];

        fieldsToCheck.push(...fields);
      }

      return !areFieldsOk(this.formData, fieldsToCheck);
    },
    cpfRegistered() {
      return !!this.profile.cpf;
    },
    hasDuplicatedEmail() {
      return this.formData.emails.findIndex(email => email.duplicated) != -1;
    },
    hasDuplicatedCPF() {
      return this.formData.cpf.duplicated;
    },
    isCreateType() {
      return this.registerType === 'create';
    },
    canShowUpdateCpf() {
      if (!this.cpfRegistered) {
        return true;
      }
      return this.canUpdateCpf;
    },
  },

  watch: {
    profile() {
      this.initializeForm();
    },
    formData: {
      handler() {
        this.updateParent();
      },
      deep: true,
    },
    hasErrors() {
      this.updateParent();
    },
    'formData.state.value'(formState) {
      this.selectedState = this.states.find(state => state.value === formState);
    },
  },

  created() {
    this.initializeForm();
  },

  mounted() {
    formLib(this.$refs.form, () => this.formData, getEventHandlers(this));
    this.$refs.form.querySelector('input:not([disabled])').focus();
  },

  methods: {
    ...mapActions('Profile', ['getAddress', 'searchUser', 'validateEmail']),
    ...mapActions('RightPanel', ['tabChange']),

    phoneMaskOptions: phoneMaskOptions,

    stanteInputEvent(state) {
      this.formData.state.value = state.value;
    },

    initializeForm() {
      const populatedFormData = this.formDataKeys.reduce((acc, key) => {
        let value = this.profile[key];
        const isArray = Array.isArray(value);
        const forgeObject = val => ({ value: val });

        if (isArray) {
          value = value.map(forgeObject);
          if (value.length === 0) {
            value.push(forgeObject(undefined));
          }
        } else {
          value = forgeObject(value);
        }

        acc[key] = value;
        return acc;
      }, {});

      if (populatedFormData.name.value === 'Anônimo') {
        populatedFormData.name.value = null;
      }

      this.$set(this, 'formData', populatedFormData);
    },

    updateParent() {
      this.$emit('input', {
        formData: this.formData,
        hasErrors: this.hasErrors,
        hasDuplicatedEmail: this.hasDuplicatedEmail,
        hasDuplicatedCPF: this.hasDuplicatedCPF,
      });
    },
  },
};
</script>
