<template>
  <div style="position: relative;">
    <Loading on-top="onTop" />
    <template v-for="x in 3">
      <Card :key="x" class="order-info__card">
        <div class="skeleton__card-body" />
      </Card>
    </template>
  </div>
</template>

<script>
import Card from '@/components/common/Card';
import Loading from '@/components/common/Loading';

export default {
  components: {
    Card,
    Loading,
  },
};
</script>
