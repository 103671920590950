import {
  initializeFaro,
  ErrorsInstrumentation,
  SessionInstrumentation,
  FetchTransport,
  ConsoleTransport,
  defaultMetas,
  ConsoleInstrumentation,
} from '@grafana/faro-web-sdk';

const collectorEndpoint = 'https://montilla.quero.space/rum/collect';
const env = process.env.NUXT_ENV_BUILD || process.env.NODE_ENV;

function samplingRate() {
  if (typeof navigator === 'undefined' || !navigator.userAgent) {
    return 0;
  }
  const bots =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
  const botsRegex = new RegExp(bots, 'i');
  return botsRegex.test(navigator.userAgent) ? 0 : 1;
}

export const faro = initializeFaro({
  enabled: true,
  isolate: true,
  app: {
    name: 'opa-client',
    version: '1.0.0',
    environment: process.env.NODE_ENV,
  },
  sessionTracking: {
    enabled: true,
    persistent: true,
    samplingRate: samplingRate(),
  },
  instrumentations: [
    new ErrorsInstrumentation(),
    new SessionInstrumentation(),
    new ConsoleInstrumentation({
      captureConsoleDisabledLevels: [],
    }),
  ],
  transports: [
    env === 'development'
      ? new ConsoleTransport()
      : new FetchTransport({ url: collectorEndpoint }),
  ],
  metas: [...defaultMetas],
});

export default function({ app }, inject) {
  faro.metas.add({
    view: {
      name: app.context.route.name,
    },
  });

  app.router.beforeEach((to, from, next) => {
    faro.api.setView(String(to.name));
    faro.api.pushEvent('view_changed', {
      from: String(from.name),
      to: String(to.name),
    });
    next();
  });

  inject('faro', faro);
}
