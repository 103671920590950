import { render, staticRenderFns } from "./ExtraInfoCard.vue?vue&type=template&id=10a47848&scoped=true"
import script from "./ExtraInfoCard.vue?vue&type=script&lang=js"
export * from "./ExtraInfoCard.vue?vue&type=script&lang=js"
import style0 from "./ExtraInfoCard.vue?vue&type=style&index=0&id=10a47848&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a47848",
  null
  
)

export default component.exports