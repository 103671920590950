<template>
  <div class="card-details">
    <Card
      class="queropago-card"
      :show-previous="shouldShowPreviousButton"
      :show-next="shouldShowNextButton"
      @clicked="changeCard"
    >
      <template slot="header">
        <div class="queropago-card__header">
          <span>Cartão</span>
          <Badge
            v-if="shouldShowBadge"
            class="queropago-card__header-badge"
            :type="'success'"
          >
            Padrão
          </Badge>
        </div>
      </template>
      <div class="queropago-card__body">
        <div class="queropago-card__item">
          <strong>Nome:</strong>
          <div class="queropago-card__item-content">
            {{ studentCard.name }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Tipo:</strong>
          <div class="queropago-card__item-content">
            {{ studentCard.type }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Bandeira:</strong>
          <div class="queropago-card__item-content">
            {{ studentCard.brand }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Número:</strong>
          <div class="queropago-card__item-content">
            {{ studentCard.formattedCardNumber }}
          </div>
        </div>
        <div class="queropago-card__item">
          <strong>Vencimento:</strong>
          <div class="queropago-card__item-content">
            {{ studentCard.formattedExpirationDate }}
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';

export default {
  components: {
    Card,
    Badge,
  },
  data: () => {
    return {
      studentCards: [],
      cardIndex: 0,
    };
  },
  computed: {
    ...mapState('Queropago', ['financialData', 'currentBilling']),
    shouldShowPreviousButton() {
      return this.currentCardIndex > 0;
    },
    shouldShowNextButton() {
      return this.currentCardIndex < this.studentCards.length - 1;
    },
    studentCard() {
      return this.studentCards[this.cardIndex] || {};
    },
    shouldShowBadge() {
      return this.currentCardIndex === 0;
    },
    currentCardIndex() {
      return this.studentCards.findIndex(
        card => card.id === this.studentCard.id
      );
    },
  },
  beforeMount() {
    this.parseStudentCards();
  },
  methods: {
    changeCard(value) {
      if (value === 'next') {
        this.cardIndex += 1;
      } else if (value === 'previous') {
        this.cardIndex -= 1;
      }
    },
    parseStudentCards() {
      if (this.currentBilling.currentCard !== {}) {
        this.studentCards.push(this.currentBilling.studentCard);

        for (let card of this.financialData.orderedCards) {
          if (card.id !== this.studentCards[0].id) {
            this.studentCards.push({
              ...card,
            });
          }
        }
      }
    },
  },
};
</script>
