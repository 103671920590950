<template>
  <ul class="tooltip-list">
    <li
      v-for="(item, index) in list"
      :key="index"
      class="tooltip-list__item"
      :class="{ 'tooltip-list__item--active': getSelectedItemId(item) }"
    >
      <slot :item="item" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    selectedItemId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    getSelectedItemId(item) {
      return this.selectedItemId === item.id;
    },
  },
};
</script>

<style lang="sass" scoped>
.tooltip-list
  list-style: none
  padding: $space-s 0

.tooltip-list__item
  cursor: pointer
  font-size: rem(14)
  white-space: nowrap

  &:hover
    background: $dark-gray

    + .tooltip-list__item
      &::before
        width: 100%

  &:not(:first-child)
    // Option divider
    &::before
      background: $dark-gray
      content: ""
      display: block
      height: 1px
      margin-left: auto
      margin-right: auto
      width: calc(100% - #{$space-st * 2})

.tooltip-list__item--active
  font-weight: $fw-bold
  color: white
</style>
