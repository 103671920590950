<template>
  <div class="scholarship-info">
    <VoucherCard v-if="hasVoucher" :voucher="voucher" />
    <PriceCard :course="data.course" :offer="data.offer" />
    <SubscriptionCard :order="order" :offer="data.offer" />
    <OfferCard
      v-if="!isOrderCompleted"
      :offer="data.offer"
      :follow-up="followUp"
      :has-user="hasUser"
      :order="order"
    />
    <CourseCard :course="data.course" :offer="data.offer" />
    <ExtraInfoCard v-if="canShowExtraInfo" :extra-info="getExtraInfo" />
    <a
      v-keycut:i.click=""
      class="scholarship-info__button"
      title="Detalhes e regras da vaga (\+i)"
      @click="showDetails"
    >
      Ver detalhes e regras da vaga
    </a>
    <ParcelamentoDataCard
      v-if="data.offer.hasParcelamento"
      :offer="data.offer"
      :course="data.course"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CourseCard from '@/components/Profile/Scholarship/CourseCard';
import ExtraInfoCard from '@/components/Profile/Scholarship/ExtraInfoCard';
import OfferCard from '@/components/Profile/Scholarship/OfferCard';
import ParcelamentoDataCard from '@/components/Profile/Scholarship/ParcelamentoDataCard';
import PriceCard from '@/components/Profile/Scholarship/PriceCard';
import SubscriptionCard from '@/components/Profile/Scholarship/SubscriptionCard';
import VoucherCard from '@/components/Profile/ManageVoucher/VoucherCard';

export default {
  components: {
    CourseCard,
    ExtraInfoCard,
    OfferCard,
    ParcelamentoDataCard,
    PriceCard,
    SubscriptionCard,
    VoucherCard,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    followUp: {
      type: Object,
      default: null,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
    hasUser: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    coupon() {
      return this.data.coupon;
    },
    isOrderCompleted() {
      return this.order && this.order.status === 'completed';
    },
    canShowExtraInfo() {
      if (!this.coupon) return Boolean(this.offerBenefit || this.offerWarning);
      return Boolean(this.couponBenefit || this.couponWarning);
    },
    getExtraInfo() {
      if (this.coupon) {
        return {
          warnings: this.couponWarning,
          benefits: this.couponBenefit,
        };
      } else {
        return {
          warnings: this.offerWarning,
          benefits: this.offerBenefit,
        };
      }
    },
    couponWarning() {
      return this.coupon.extra_warning;
    },
    couponBenefit() {
      return this.coupon.extra_benefit;
    },
    offerWarning() {
      return this.data.extraInfo.warnings;
    },
    offerBenefit() {
      return this.data.extraInfo.benefits;
    },
    voucher() {
      return this.order && this.order.voucher;
    },
    hasVoucher() {
      return Boolean(this.voucher);
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    showDetails() {
      this.tabChange({ key: 'offer-details', params: { data: this.data } });
    },
  },
};
</script>

<style lang="sass" scoped>
.scholarship-info__button
  display: block
  width: 100%
  cursor: pointer
  font-weight: $fw-bold
  color: $white
  padding: $space-xs $space-xs $space-xs 0
  margin-bottom: $space-st
</style>
