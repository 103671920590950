<template>
  <Card class="payment-card">
    <template slot="header">
      <div class="payment-card__card-header">
        <div>
          <div class="payment-card__card-title">
            Pagamento
          </div>
          ID {{ payment.id }}
        </div>
        <div>
          <div class="payment-card__card-status">
            <Badge
              class="voucher-card__status-badge"
              :type="paymentStatus.variant"
            >
              {{ paymentStatus.label }}
            </Badge>
          </div>
        </div>
      </div>
    </template>
    <div class="payment-card__body">
      <div class="payment-card__line">
        Tipo:
        <div class="opa-bold">
          {{ payment.type }}
        </div>
      </div>
      <div class="payment-card__line">
        Valor:
        <div class="opa-bold">
          {{ payment.value | brlcurrency }}
        </div>
      </div>
      <div class="payment-card__line">
        PSP:
        <div class="opa-bold">
          {{ payment.psp }}
        </div>
      </div>
      <div v-if="gatewayResponseJson.psp_reference" class="payment-card__line">
        PSP Reference:
        <div class="opa-bold">
          {{ gatewayResponseJson.psp_reference }}
        </div>
      </div>
      <div v-if="gatewayResponseJson.acquirer_name" class="payment-card__line">
        Adquirente:
        <div class="opa-bold">
          {{ gatewayResponseJson.acquirer_name }}
        </div>
      </div>
      <div v-if="gatewayResponseJson.acquirer_id" class="payment-card__line">
        ID no Adquirente:
        <div class="opa-bold">
          {{ gatewayResponseJson.acquirer_id }}
        </div>
      </div>
      <div v-if="gatewayResponseJson.paid_at" class="payment-card__line">
        Pago em:
        <div class="opa-bold">
          {{ gatewayResponseJson.paid_at | momentformat('L') }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';

export default {
  components: {
    Badge,
    Card,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paymentStatus() {
      const status = this.payment.status;

      const stateMapper = {
        awaiting_authorization: {
          label: 'Solicitação Iniciada',
          variant: 'blue',
        },
        authorization_request_received: {
          label: 'Aguardando Pagamento',
          variant: 'blue',
        },
        authorized: {
          label: 'Pago',
          variant: 'success',
        },
        rejected: {
          label: 'Rejeitado',
          variant: 'error',
        },
        sent_for_refund: {
          label: 'Reembolso em Andamento',
          variant: 'warning',
        },
        sent_for_cancel_or_refund: {
          label: 'Reembolso Solicitado',
          variant: 'warning',
        },
        refunded: {
          label: 'Reembolsado',
          variant: 'success',
        },
        captured: {
          label: 'Pago',
          variant: 'success',
        },
        manually_refunded: {
          label: 'Reembolsado Manualmente',
          variant: 'success',
        },
        expired: {
          label: 'Expirado',
          variant: 'error',
        },
        canceled: {
          label: 'Cancelado',
          variant: 'error',
        },
        failed: {
          label: 'Falhou',
          variant: 'error',
        },
      };

      return stateMapper[status] || { label: status, variant: 'abort' };
    },
    gatewayResponseJson() {
      return this.payment.gateway_response_json;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-card
  color: $silver
  margin-bottom: $space-st

.payment-card__card-title
  font-size: rem(18)
  font-weight: bold

.payment-card__card-header
  align-items: center
  color: $white
  display: flex
  justify-content: space-between
  position: relative
  width: 100%

.payment-card__body
  color: $silver-gray
  padding: $space-s 0

.payment-card__line
  align-items: center
  display: flex
  margin: $space-s 0
</style>
