<template>
  <div class="contract-details">
    <Card
      class="queropago-card"
      :show-previous="shouldShowPreviousButton"
      :show-next="shouldShowNextButton"
      @clicked="changeContract"
    >
      <template slot="header">
        <div class="queropago-card__header">
          <span>Acordos</span>
          <Badge
            v-if="shouldShowAgreementContractBadge"
            class="queropago-card__header-badge"
            :type="agreementContractBadgeType"
          >
            {{ currentAgreementContract.translatedStatus }}
          </Badge>
        </div>
      </template>
      <div v-if="emptyBillsList" class="queropago-card__no-content">
        Nenhuma mensalidade de acordo encontrada
      </div>
      <div>
        <template v-for="bill in currentAgreementContract.bills">
          <div :key="bill.id" class="bill-data">
            <div>
              <div class="queropago-card__item">
                <strong>{{ BRLCurrency(bill.currentAmount) }}</strong>
              </div>
              <div class="queropago-card__item">
                <span>{{ bill.formattedDueDate }}</span>
              </div>
            </div>
            <div>
              <Badge :type="billBadgeType(bill)">
                {{ bill.translatedStatus }}
              </Badge>
            </div>
          </div>
        </template>
      </div>
    </Card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import QueropagoHelper from '@/helpers/QueropagoHelper';
import BRLCurrency from '@/assets/javascripts/formatters/BRLCurrency.js';

export default {
  components: {
    Card,
    Badge,
  },
  data: () => {
    return {
      agreementContracts: [],
      agreementContractIndex: 0,
    };
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
    emptyBillsList() {
      return (
        !this.currentBilling.agreementContracts.length ||
        !this.currentAgreementContract.bills.length
      );
    },
    shouldShowPreviousButton() {
      return this.currentAgreementContractIndex > 0;
    },
    shouldShowNextButton() {
      return this.currentAgreementIndex < this.agreementContracts.length - 1;
    },
    currentAgreementContract() {
      return this.agreementContracts[this.agreementContractIndex] || {};
    },
    currentAgreementContractIndex() {
      return this.agreementContracts.findIndex(
        contract => contract.id === this.currentAgreementContract.id
      );
    },
    shouldShowAgreementContractBadge() {
      return this.currentAgreementContract.translatedStatus === 'Ativo';
    },
    agreementContractBadgeType() {
      switch (this.currentAgreementContract.translatedStatus) {
        case 'Ativo':
          return 'yellow';
        case 'Finalizado':
          return 'green';
        case 'Cancelado':
          return 'red';
        default:
          return 'gray';
      }
    },
  },
  beforeMount() {
    this.agreementContracts = this.currentBilling.agreementContracts;
  },
  methods: {
    BRLCurrency,
    billBadgeType(bill) {
      return QueropagoHelper.billStatusBadgeType(bill);
    },
    changeContract(value) {
      if (value === 'next') {
        this.agreementContractIndex += 1;
      } else if (value === 'previous') {
        this.agreementContractIndex -= 1;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.bill-data
  align-items: center
  display: flex
  justify-content: space-between
  &:not(:last-child)
    border-bottom: 1px solid $dark-gray
</style>
