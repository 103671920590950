<template>
  <div class="financial-data">
    <TabHeader class="order-list__header" title="Financeiro" />
    <div class="financial-data__scroller">
      <div class="financial-data__body">
        <CourseDetails />
        <BillingDetails />
        <CardDetails v-if="shouldShowCardData" />
        <ContractDetails />
        <AgreementDetails />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardDetails from '@/components/Profile/Queropago/CardDetails';
import CourseDetails from '@/components/Profile/Queropago/CourseDetails';
import BillingDetails from '@/components/Profile/Queropago/BillingDetails';
import ContractDetails from '@/components/Profile/Queropago/ContractDetails';
import AgreementDetails from '@/components/Profile/Queropago/AgreementDetails';
import TabHeader from '@/components/common/TabHeader';

export default {
  components: {
    CardDetails,
    CourseDetails,
    BillingDetails,
    ContractDetails,
    TabHeader,
    AgreementDetails,
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
    shouldShowCardData() {
      return ['Cartão de Crédito', 'Cartão de Débito'].includes(
        this.currentBilling.translatedRecurrentPaymentMethod
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.financial-data
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.financial-data__scroller
  background-color: $space-gray
  flex-grow: 1
  overflow: auto

.financial-data__body
  padding: $space-st
</style>
