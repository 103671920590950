<template>
  <div class="sidebar">
    <div class="sidebar__logo">
      <div class="sidebar__logo__icon" />
    </div>
    <div class="sidebar__menu">
      <TenantList />
    </div>
    <div class="sidebar__footer">
      <template v-for="item in availableItems">
        <Popover :key="item.route" open-on="hover" transition-name="fade">
          <component
            :is="item.isClient ? 'nuxt-link' : 'a'"
            class="sidebar__footer__item sidebar__footer__item--menu"
            :to="item.route"
            :href="item.route"
            :class="{ 'sidebar__footer__item--active': isCurrentItem(item) }"
          >
            <icon svg-class="sidebar__icon" :svg-name="item.icon" />
          </component>
          <template slot="popover">
            <Tooltip special-position="right">
              <div class="sidebar__tooltip">
                {{ item.text }}
              </div>
            </Tooltip>
          </template>
        </Popover>
      </template>
      <SidebarUser />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import SidebarUser from '@/components/sidebar/SidebarUser';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';
import TenantList from '@/components/sidebar/TenantList';

import { mapGetters } from 'vuex';

const SALES = 'sales';
const POST_SALES = 'post-sales';

export default {
  components: {
    Icon,
    SidebarUser,
    Popover,
    Tooltip,
    TenantList,
  },

  computed: {
    ...mapGetters('Sidebar', ['currentPath', 'items']),
    ...mapGetters('Permissions', ['isPostSales', 'canUseOnlyBasicTools']),

    availableItems() {
      const self = this;
      return this.items.filter(item => {
        return (
          (item.kind === SALES || self.isPostSales) && self.canShowItem(item)
        );
      });
    },

    kind() {
      return this.isPostSales ? POST_SALES : SALES;
    },
  },

  methods: {
    isCurrentItem({ route }) {
      return this.currentPath == route;
    },
    canShowItem(item) {
      return this.canUseOnlyBasicTools ? item.basicTool : true;
    },
  },
};
</script>
