<template>
  <svg :class="iconClass" :alt="svgAlt" @click="$emit('click')">
    <title>{{ svgAlt }}</title>
    <use :href="`#${svgName}`" />
  </svg>
</template>

<script>
export default {
  props: {
    svgClass: {
      type: [String, Object],
      required: false,
      default: null,
    },

    svgName: {
      type: String,
      required: true,
    },

    svgAlt: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    iconClass() {
      if (this.svgClass) return this.svgClass;

      switch (this.type) {
        case 'small':
          return 'opa-icon opa-icon--small';
        default:
          return 'opa-icon';
      }
    },
  },
};
</script>
