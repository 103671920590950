<template>
  <Card>
    <template slot="header">
      <div>
        <div class="admission-title admission-title--small">
          Matrícula
        </div>
        <div class="admission-text admission-text--xs">
          ID {{ admission.id }}
        </div>
      </div>
    </template>
    <div class="admission-card__content-wrapper">
      <div class="admission-card__body-item">
        <span>Situação:</span>
        <Badge
          class="admission-card__status-badge"
          :type="admission | followUpStepColor"
        >
          {{ admission | followUpStepTranslate }}
        </Badge>
      </div>
      <div class="admission-card__body-item">
        <Icon class="admission-card__icon" svg-name="i-schedule" /><span>{{
          admission.statusDate | momentformat('L - LT')
        }}</span>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import Icon from '@/components/common/Icon';

import {
  followUpStepColor,
  followUpStepTranslate,
} from '@/assets/javascripts/data/admissions/followUpStep';

export default {
  components: {
    Card,
    Badge,
    Icon,
  },

  filters: {
    followUpStepColor,
    followUpStepTranslate,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },
};
</script>
