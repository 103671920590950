<template>
  <div class="nav-list">
    <template v-for="item in list">
      <button
        v-show="!item.disabled"
        :key="item.title"
        class="nav-list__item"
        :title="item.titleAttribute"
        @click="clicked(item)"
      >
        <div class="nav-list__item-container">
          <a class="nav-list__title">{{ item.title }}</a>
          <Badge
            v-if="item.badgeText"
            class="nav-list__badge"
            :type="item.badgeType"
          >
            {{ item.badgeText }}
          </Badge>
          <Icon svg-name="i-chevron-right" svg-class="nav-list__icon" />
        </div>
      </button>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Icon from '@/components/common/Icon';
import Badge from '@/components/common/Badge';

export default {
  components: {
    Icon,
    Badge,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    clicked(item) {
      if (item.tab) {
        this.tabChange({ key: item.tab });
      } else {
        this.$emit('navclick', item);
      }
    },
  },
};

//pending: Show badges with useful data.
</script>
