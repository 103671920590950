<template>
  <div class="input-list__group">
    <template v-for="(item, index) in value">
      <div :key="index" class="input-list__row">
        <slot :item="item" :index="index" />
        <ClickableIcon
          class="input-list__delete-icon"
          svg-name="i-delete"
          svg-alt="Excluir campo"
          :disabled="disabled"
          @click="removeField(index)"
        />
      </div>
      <slot name="bottom" :item="item" />
    </template>
    <Button
      class="input-list__btn"
      text="Adicionar"
      type="dotted"
      :disabled="disabled"
      @click="addField()"
    />
  </div>
</template>

<script>
import ClickableIcon from '@/components/common/ClickableIcon';
import Button from '@/components/common/Button';

export default {
  components: {
    ClickableIcon,
    Button,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.$emit('input', value);
    },
  },
  methods: {
    addField() {
      this.value.push({ value: null });
    },
    removeField(index) {
      if (this.value.length === 1) return;
      this.value.splice(index, 1);
    },
  },
};
</script>

<style lang="sass" scoped>
.input-list__group
  display: flex
  flex-direction: column

.input-list__row
  display: flex
  align-items: center

.input-list__delete-icon
  height: 39px
  width: 39px
  justify-self: flex-start
  align-self: flex-start

.input-list__btn
  margin-top: $space-s
  margin-bottom: $space-s
</style>
