<template>
  <div class="avatar">
    <img
      class="avatar__img"
      :src="url"
      :class="{ 'avatar--big': big }"
      alt=""
    />
    <Icon
      v-if="hasLogin"
      svg-name="i-user-check"
      :svg-class="{ avatar__login: true, 'avatar__login--big': big }"
      svg-alt="Usuário com login"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    tenantId: {
      type: Number,
      required: false,
      default: null,
    },
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasLogin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('Admin', ['tenantSlugsById']),
    url() {
      const tenantSlug = this.tenantSlugsById[this.tenantId];
      if (!tenantSlug) return '/images/quebo.png';

      const kongUrl = process.env.KONG_URL;
      const icon = this.selected ? tenantSlug : `${tenantSlug}-gray`;

      return `${kongUrl}/favicon/fav-${icon}.svg`;
    },
  },
};
</script>

<style lang="sass">
.avatar
  position: relative

.avatar__img
  +circle(40px)
  background-size: contain
  object-fit: cover
  display: block

.avatar--big
  +circle(82px)

.avatar__login
  color: $silver-gray
  +square(15px)
  position: absolute
  right: -2px
  bottom: -2px

.avatar__login--big
  +square(25px)
</style>
