<template>
  <div class="order-list">
    <TabHeader class="order-list__header" title="Matrículas" />
    <div v-if="emptyOrdersList" class="order-list__list--empty">
      Nenhuma matrícula encontrada
    </div>
    <div v-else class="order-list__list">
      <Loading v-if="ordersLoading" class="order-list__loading" />
      <template v-for="order in orders" v-else>
        <OrderTip :key="order.id" :order="order" @click="orderClicked(order)" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import TabHeader from '@/components/common/TabHeader';
import Loading from '@/components/common/Loading';
import OrderTip from '@/components/Profile/Scholarship/OrderTip';

export default {
  components: {
    OrderTip,
    TabHeader,
    Loading,
  },
  computed: {
    ...mapState('Order', ['orders']),
    ordersLoading() {
      return !this.orders;
    },
    emptyOrdersList() {
      return !this.ordersLoading && !this.orders.length;
    },
  },
  created() {
    if (this.emptyOrdersList) return;

    this.fetchOrders();
  },
  methods: {
    ...mapActions('Order', ['orderClicked', 'fetchOrders']),
  },
};
</script>
