<template>
  <div>
    <sprite class="opa-sprite" />
    <nuxt />
  </div>
</template>
<script>
import Sprite from '@/static/images/icons/sprite.svg';

export default {
  components: {
    Sprite,
  },
};
</script>
