<template>
  <div class="reactivate-user">
    <TabHeader class="reactivate-user__header" title="Reativação de Conta" />
    <form class="reactivate-user__body" @submit.prevent="submit">
      <div class="reactivate-user__text">
        Esse usuário teve a conta suspensa em {{ userDestroyedAt }}
      </div>
      <div class="reactivate-user__text">
        Deseja continuar com a reativação do usuário?
      </div>
      <Button
        class="reactivate-user__submit"
        type="positive"
        :text="submitting ? 'Aguarde...' : 'Reativar'"
        :submit="true"
        :loading="submitting"
      />
    </form>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import TabHeader from '@/components/common/TabHeader';
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import profileAPI from '@/api/profileAPI';

export default {
  components: {
    Button,
    TabHeader,
  },
  data: () => ({
    submitting: false,
  }),
  computed: {
    ...mapState('Profile', ['profile']),
    ...mapGetters('UserSearch', ['inputValue']),

    userDestroyedAt() {
      return moment(this.profile.destroyedAt).format('L');
    },
  },
  methods: {
    ...mapActions('RightPanel', ['hidePanel']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('UserSearch', ['searchInputed']),
    ...mapActions('Omni', ['ticketClosed']),

    async submit() {
      this.submitting = true;

      profileAPI
        .reactivateUser(this.profile.id)
        .then(() => {
          this.hidePanel();
          this.ticketClosed();
          this.searchInputed(this.inputValue);
          this.notifySuccess('Conta reativada com sucesso!');
        })
        .catch(error => {
          const message =
            error?.response?.data?.message ||
            'Ocorreu um erro inesperado na reativação da conta.';
          this.notifyError(message);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.reactivate-user
  height: 100%
  display: flex
  flex-direction: column
  background: $space-gray

.reactivate-user__header
  flex-shrink: 0

.reactivate-user__body
  position: relative
  overflow-y: scroll
  overflow-y: overlay
  flex-grow: 1
  padding: $space-st

  > div
    margin-bottom: $space-m

.reactivate-user__submit
  width: 100%
</style>
