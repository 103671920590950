<template>
  <button class="show-more" type="button" @click="toggleShowMore">
    <div v-if="showMore" key="less" class="show-more__container">
      <span>Ver menos dados</span>
      <Icon
        class="show-more__icon show-more__icon--more"
        svg-name="i-chevron-right"
      />
    </div>
    <div v-else key="more" class="show-more__container">
      <span>Ver mais dados</span>
      <Icon
        class="show-more__icon show-more__icon--less"
        svg-name="i-chevron-right"
      />
    </div>
  </button>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showMore() {
      return this.value;
    },
    moreIconClass() {
      return 'show-more__icon--' + (this.showMore ? 'more' : 'less');
    },
  },
  methods: {
    toggleShowMore() {
      this.$el.blur();
      this.$emit('input', !this.showMore);
    },
  },
};
</script>
