<template>
  <div class="loading" :class="{ 'loading--ontop': onTop }">
    <Icon class="loading__icon" svg-name="i-engine" />
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    onTop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
