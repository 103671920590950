<template>
  <Card>
    <template slot="header">
      <div class="admission-title admission-title--small">
        Informações do processo
      </div>
    </template>
    <div class="admission-card__content-wrapper">
      <div v-if="admission.documents.length" class="spacer-bottom-st">
        <div
          class="admission-title admission-title--small admission-title--gray spacer-bottom-s"
        >
          Documentos para admissão digital
        </div>
        <p
          v-for="document in admission.documents"
          :key="document.id"
          class="admission-text admission-text--xs admission-text--gray spacer-bottom-s"
        >
          - {{ document | docKindTranslate }}
        </p>
      </div>
      <div v-if="admission.admissionExams.length" class="spacer-bottom-st">
        <div
          class="admission-title admission-title--small admission-title--gray spacer-bottom-s"
        >
          Formas de Ingresso
        </div>
        <p
          v-for="admissionExam in admission.admissionExams"
          :key="admissionExam.id"
          class="admission-text admission-text--xs admission-text--gray spacer-bottom-s"
        >
          <strong>{{ admissionExam | admissionExamTitle }}</strong>
          {{ admissionExam | admissionExamDescription }}
        </p>
      </div>
      <div v-if="admission.enrollmentFee" class="spacer-bottom-st">
        <div
          class="admission-title admission-title--small admission-title--gray spacer-bottom-s"
        >
          Taxa de matrícula
        </div>
        <div class="admission-text admission-text--xs admission-text--gray">
          Valor da taxa {{ admission.enrollmentFee.value | brlcurrency }}
        </div>
      </div>
      <div>
        <div
          class="admission-title admission-title--small admission-title--gray spacer-bottom-s"
        >
          Instruções pós matrícula
        </div>
        <div class="admission-text admission-text--xs admission-text--gray">
          <template v-if="admission.enrollmentInstructions">
            {{ admission.enrollmentInstructions }}
          </template>
          <template v-else>
            Sem instruções pós matrícula
          </template>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/common/Card';

import {
  docKindTranslate,
  docStatusTranslate,
} from '@/assets/javascripts/data/admissions/document';

import {
  admissionExamTitle,
  admissionExamDescription,
} from '@/assets/javascripts/data/admissions/admissionExam';

export default {
  components: {
    Card,
  },

  filters: {
    docKindTranslate,
    docStatusTranslate,
    admissionExamTitle,
    admissionExamDescription,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },
};
</script>
