<template>
  <button
    ref="button"
    style="height: 0; width: 0; position: absolute;"
    type="button"
  />
</template>

<script>
export default {
  mounted() {
    this.$refs.button.focus();
  },
};
</script>
