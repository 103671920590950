<template>
  <div class="purchase-confirmation">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <div class="purchase-confirmation__body">
      <div class="purchase-confirmation__title">
        Enviar confirmação de compra via e-mail
      </div>
      <Select
        v-model="selectedEmail"
        class="purchase-confirmation__select"
        placeholder="Escolha o e-mail"
        :list="userEmails"
        label-property="email"
      />
      <Button
        class="purchase-confirmation__button"
        type="positive"
        :disabled="hasSelectedEmail"
        text="Enviar confirmação"
        submit="submit"
        @click="sendPurchaseConfirmation"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    Button,
    Select,
    TabHeader,
  },
  data() {
    return {
      selectedEmail: null,
    };
  },
  computed: {
    ...mapGetters('Order', ['offerNames']),
    ...mapState('Profile', ['profile']),

    userEmails() {
      return this.profile.emails.map(email => {
        return { email: email };
      });
    },
    hasSelectedEmail() {
      return !this.selectedEmail;
    },
  },
  methods: {
    ...mapActions('Order', ['submitPurchaseConfirmationClicked']),
    sendPurchaseConfirmation() {
      this.submitPurchaseConfirmationClicked(this.selectedEmail.email);
    },
  },
};
</script>

<style lang="sass">
.purchase-confirmation
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100vh

.purchase-confirmation__body
  flex-grow: 1
  overflow: auto
  padding: $space-st

.purchase-confirmation__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  margin-bottom: $space-s
  padding-bottom: $space-s

.purchase-confirmation__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st

.purchase-confirmation__select
  margin-bottom: $space-m
</style>
