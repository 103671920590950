import AdminService from '@/assets/javascripts/services/AdminService';

const can = (permissions, subject, action) => {
  return permissions.some(permission => {
    return permission.action === action && permission.subject === subject;
  });
};

export const state = () => {
  return {
    permissions: [],
  };
};

export const mutations = {
  setPermissions(state, { permissions }) {
    state.permissions = permissions;
  },
};

export const actions = {
  async getPermissions({ commit }) {
    const permissions = await AdminService.getPermissions();

    commit('setPermissions', { permissions });
  },
};

export const getters = {
  isPostSales(state) {
    return ['operate_bo', 'operate_refund', 'operate_exchange'].some(action => {
      return can(state.permissions, 'OPA', action);
    });
  },
  isQueroPagoAttendant(state) {
    return can(state.permissions, 'OPA', 'use_billing_tools');
  },
  isAdmissionsAttendant(state) {
    return can(state.permissions, 'admission', 'use_tool');
  },
  isRotationalQueueAgent(state) {
    return can(state.permissions, 'OPA', 'rotational_agent_role');
  },
  canReadOrderOnAdmin(state) {
    return can(state.permissions, 'Order', 'read');
  },
  canDownloadPreEnrollmentCoupon(state) {
    return can(state.permissions, 'OPA', 'can_download_pre_enrollment_coupon');
  },
  canMergeUsers(state) {
    return can(state.permissions, 'OPA', 'merge_users');
  },
  canManageVoucher(state) {
    return can(state.permissions, 'OPA', 'manage_voucher');
  },
  canUpdateFollowUpStep(state) {
    return can(state.permissions, 'OPA', 'update_follow_up_step');
  },
  canResendDigitalContract(state) {
    return can(state.permissions, 'OPA', 'resend_digital_contract');
  },
  canUpdateEnrollmentSemester(state) {
    return can(state.permissions, 'OPA', 'update_enrollment_semester');
  },
  canActivateDigitalAdmission(state) {
    return can(state.permissions, 'OPA', 'activate_digital_admission');
  },
  canUpdateCpf(state) {
    return can(state.permissions, 'OPA', 'update_cpf');
  },
  canUpdateAgreementState(state) {
    return can(state.permissions, 'OPA', 'update_agreement_state');
  },
  canReactivateUser(state) {
    return can(state.permissions, 'OPA', 'reactivate_user');
  },
  canActivateCoupon(state) {
    return can(state.permissions, 'OPA', 'activate_coupon');
  },
  canUseOnlyBasicTools(state) {
    return can(state.permissions, 'OPA', 'use_only_basic_tools');
  },
  canDeleteRegister(state) {
    return can(state.permissions, 'OPA', 'lgpd_delete_register');
  },
};
