<template>
  <div class="order-tip" @click="$emit('click')">
    <div class="order-tip__left">
      <div class="order-tip__left-top">
        <h4 class="order-tip__university">
          {{ order.universityName }}
        </h4>
      </div>
      <div class="order-tip__left-top">
        <h4 class="order-tip__title">
          {{ order.courseName }}
        </h4>
        <h4 class="order-tip__discount">{{ order.discount }}%</h4>
      </div>
      <div class="order-tip__left-bot">
        <Badge class="order-tip__badge" :type="orderStatus.color">
          {{ orderStatus.title }}
        </Badge>
        <b class="order-tip__origin-name">
          {{ originDetails.name }}
        </b>
        <img class="order-tip__origin-logo" :src="originDetails.logo" />
      </div>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/common/Badge';
import getOrderStatusFormatting from '@/assets/javascripts/data/orderStatus.js';
import { orderOriginDetails } from '@/assets/javascripts/data/orderOrigins/orderOrigins';
import { mapGetters } from 'vuex';

export default {
  components: {
    Badge,
  },
  filters: {
    orderOriginDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      originDetails: {},
    };
  },
  computed: {
    ...mapGetters('Order', ['orderOrigin']),
    orderStatus() {
      return getOrderStatusFormatting(this.order.lastStatus);
    },
  },
  mounted() {
    this.originDetails = orderOriginDetails(this.order.orderOrigin);
  },
};
</script>
