<template>
  <div class="profile-home">
    <ProfileHead class="profile-home__head" />
    <div class="profile-home__scroll">
      <div class="profile-home__body">
        <Card v-if="profileLoading">
          <div class="skeleton__card-body" />
        </Card>
        <template v-else>
          <Button
            v-if="hasLogin && canChangeBaseUser && isChoosingProfile"
            class="profile-home__associate"
            :text="associateButtonText"
            :loading="profileSelectLoading"
            @click="profileSelectClick"
          />
          <UserInfo />
        </template>
      </div>
    </div>
    <NavList
      v-if="!profileLoading"
      v-keycut:m="() => navClicked({ key: 'orders' })"
      class="profile-home__nav"
      :list="navMenuList"
      @navclick="navClicked"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Card from '@/components/common/Card';
import ProfileHead from '@/components/Profile/ProfileHead';
import NavList from '@/components/Profile/NavList';
import UserInfo from '@/components/Profile/UserInfo';
import Button from '@/components/common/Button';

export default {
  components: {
    ProfileHead,
    Card,
    NavList,
    UserInfo,
    Button,
  },
  data() {
    return {
      profileSelectLoading: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profileLoading', 'profile']),
    ...mapGetters('Profile', [
      'canChangeBaseUser',
      'isChoosingProfile',
      'hasLogin',
    ]),
    ...mapGetters('Permissions', ['isQueroPagoAttendant']),
    navMenuList() {
      return [
        {
          title: 'Cobranças',
          key: 'financialData',
          disabled: !this.profile.isBillingUser || !this.isQueroPagoAttendant,
        },
        {
          title: 'Matrículas',
          key: 'orders',
          titleAttribute: '(\\+M)',
        },
      ];
    },
    associateButtonText() {
      return this.profileSelectLoading
        ? 'Processando...'
        : 'Selecionar usuário';
    },
  },
  methods: {
    ...mapActions('Profile', ['profileSelect', 'ordersClicked']),
    ...mapActions('Queropago', ['billingsTabClicked']),
    ...mapActions('StoreManager', ['notify']),
    navClicked({ key }) {
      if (key === 'orders') {
        this.ordersClicked();
      } else if (key === 'financialData' && this.isQueroPagoAttendant) {
        this.billingsTabClicked();
      }
    },
    async profileSelectClick() {
      this.profileSelectLoading = true;

      await this.profileSelect();

      this.notify({
        message: 'Usuário selecionado com sucesso.',
        type: 'success',
      });

      this.profileSelectLoading = false;
    },
  },
};
</script>
