<template>
  <div class="voucher-list">
    <TabHeader class="voucher-list__header" title="Vouchers do aluno" />
    <div v-if="emptyVoucherList" class="voucher-list__list--empty">
      Nenhum voucher encontrado
    </div>
    <div v-else class="voucher-list__list">
      <Loading v-if="voucherLoading" class="voucher-list__loading" />
      <template v-for="voucher in vouchers" v-else>
        <VoucherCard
          :key="voucher.id"
          :voucher="voucher"
          :show-course-name="true"
          :show-university-name="true"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import TabHeader from '@/components/common/TabHeader';
import VoucherCard from '@/components/Profile/ManageVoucher/VoucherCard';

export default {
  components: {
    Loading,
    TabHeader,
    VoucherCard,
  },
  computed: {
    ...mapState('Voucher', ['vouchers']),
    ...mapState('Profile', ['user']),
    voucherLoading() {
      return !this.vouchers;
    },
    emptyVoucherList() {
      return !this.voucherLoading && this.vouchers.length === 0;
    },
  },
  created() {
    const { base_user } = this.user;

    this.loadVouchers(base_user);
  },
  methods: {
    ...mapActions('Voucher', ['loadVouchers']),
  },
};
</script>
<style lang="sass" scoped>
.voucher-list
  height: 100%
  display: flex
  flex-direction: column
  background: $space-gray

.voucher-list__header
  flex-shrink: 0

.voucher-list__list
  position: relative
  overflow-y: scroll
  overflow-y: overlay
  flex-grow: 1
  padding: $space-st

.voucher-list__list--empty
  color: $white
  display: block
  font-size: rem(20)
  line-height: rem(22)
  margin-top: $space-xl
  text-align: center
  font-weight: bold
</style>
