import SocketService from './SocketService';
import HttpService from './HttpService';
const { setListener } = SocketService;

const WhatsappService = {
  async getAttendants(tenantId) {
    const params = { tenant_id: tenantId };
    const { data } = await HttpService.get(
      'api/admin_user/whatsapp_attendants',
      params
    );

    return data;
  },
  async getQueueOptions() {
    const { data } = await HttpService.get('api/queues');

    return data;
  },
  async getRotationalQueueShare() {
    const { data } = await HttpService.get('api/queues/rotational_share');

    return data;
  },
  async getQueueSize(chatType) {
    const { data } = await HttpService.get(`api/queues/${chatType}/size`);

    return data;
  },
  async sendMessage(message, whatsapp_username, whatsapp_message_shortcut_id) {
    const { data } = await HttpService.put(
      'api/whatsapp_message/send_message',
      {
        message: message,
        whatsapp_username: whatsapp_username,
        whatsapp_message_shortcut_id: whatsapp_message_shortcut_id,
        message_type: 'text',
      }
    );

    return data;
  },
  async sendAttachment(filename, attachment_data, whatsapp_username) {
    const { data } = await HttpService.put(
      'api/whatsapp_message/send_message',
      {
        file_name: filename,
        data: attachment_data,
        whatsapp_username: whatsapp_username,
        message_type: 'attachment',
      }
    );

    return data;
  },
  async fetchShortcuts(newMessage) {
    const { data } = await HttpService.post(
      '/api/whatsapp_message_shortcut/fetch_list',
      {
        key: newMessage.substring(1),
      }
    );

    return data;
  },
  async fetchActiveChat(whatsapp_username, limit) {
    const { data } = await HttpService.post(
      'api/whatsapp/fetch_chat_messages',
      {
        whatsapp_username: whatsapp_username,
        limit: limit,
        version: process.env.OPA_CLIENT_VERSION,
      }
    );

    return data;
  },
  async sendActiveMessage(hsm_name, whatsapp_username, with_substitutions) {
    const { data } = await HttpService.put(
      'api/whatsapp_message/send_message',
      {
        hsm_name: hsm_name,
        whatsapp_username: whatsapp_username,
        with_substitutions: with_substitutions,
        message_type: 'active_message',
      }
    );

    return data;
  },

  async getWhatsappHosts() {
    const { data } = await HttpService.get('api/whatsapp_hosts');
    return data;
  },

  setOnNewMessageListener(listener) {
    setListener('newWhatsappMessage', listener);
  },

  setOnStatusUpdateListener(listener) {
    setListener('messageStatusUpdate', listener);
  },

  setOnQueueSizeUpdateListener(listener) {
    setListener('updateWhatsappChatQueueSize', listener);
  },

  setOnNewAttachmentListener(listener) {
    setListener('newWhatsappAttachment', listener);
  },
};

export default WhatsappService;
