<template>
  <div class="contract-details">
    <Card
      class="queropago-card"
      :show-previous="shouldShowPreviousButton"
      :show-next="shouldShowNextButton"
      @clicked="changeContract"
    >
      <template slot="header">
        <div class="queropago-card__header">
          <span>Contratos</span>
          <Badge
            v-if="shouldShowContractBadge"
            class="queropago-card__header-badge"
            :type="'success'"
          >
            Vigente
          </Badge>
        </div>
      </template>
      <div v-if="emptyBillsList" class="queropago-card__no-content">
        Nenhuma mensalidade encontrada
      </div>
      <div>
        <template v-for="bill in currentContract.bills">
          <div :key="bill.id" class="bill-data">
            <div>
              <div class="queropago-card__item">
                <strong>{{ BRLCurrency(bill.currentAmount) }}</strong>
              </div>
              <div class="queropago-card__item">
                <span>{{ bill.formattedDueDate }}</span>
              </div>
            </div>
            <div>
              <Badge :type="billBadgeType(bill)">
                {{ bill.translatedStatus }}
              </Badge>
            </div>
          </div>
        </template>
      </div>
    </Card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import QueropagoHelper from '@/helpers/QueropagoHelper';
import BRLCurrency from '@/assets/javascripts/formatters/BRLCurrency.js';

export default {
  components: {
    Card,
    Badge,
  },
  data: () => {
    return {
      contracts: [],
      contractIndex: 0,
    };
  },
  computed: {
    ...mapState('Queropago', ['currentBilling']),
    emptyBillsList() {
      return (
        !this.currentBilling.contracts.length ||
        !this.currentContract.bills.length
      );
    },
    shouldShowPreviousButton() {
      return this.currentContractIndex > 0;
    },
    shouldShowNextButton() {
      return this.currentContractIndex < this.contracts.length - 1;
    },
    currentContract() {
      return this.contracts[this.contractIndex] || {};
    },
    activeContractIndex() {
      const index = this.contracts.findIndex(
        contract => contract.translatedStatus === 'Ativo'
      );

      return index > 0 ? index : 0;
    },
    currentContractIndex() {
      return this.contracts.findIndex(
        contract => contract.id === this.currentContract.id
      );
    },
    shouldShowContractBadge() {
      return this.currentContract.translatedStatus === 'Ativo';
    },
  },
  beforeMount() {
    this.contracts = this.currentBilling.contracts;
    this.contractIndex = this.activeContractIndex;
  },
  methods: {
    BRLCurrency,
    billBadgeType(bill) {
      return QueropagoHelper.billStatusBadgeType(bill);
    },
    changeContract(value) {
      if (value === 'next') {
        this.contractIndex += 1;
      } else if (value === 'previous') {
        this.contractIndex -= 1;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.bill-data
  align-items: center
  display: flex
  justify-content: space-between
  &:not(:last-child)
    border-bottom: 1px solid $dark-gray
</style>
