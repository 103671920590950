<template>
  <BaseModal v-if="showModal" @hide="hide">
    <template slot="header">
      <div class="application-modal__header">
        <div class="application-modal__header-title">
          Vestibular
        </div>
        <Badge type="error">
          REPROVADO NO VESTIBULAR
        </Badge>
      </div>
    </template>
    <template slot="body">
      <div class="application-modal__body-title">
        Dicas para realizar uma boa redação
      </div>
      <ul class="application-modal__tips-list">
        <li v-for="tip in tips" :key="tip">
          {{ tip }}
        </li>
      </ul>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/common/BaseModal';
import Badge from '@/components/common/Badge';

export default {
  components: {
    BaseModal,
    Badge,
  },

  props: {
    tips: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    showModal: false,
  }),

  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.application-modal__header
  align-items: center
  display: flex

.application-modal__header-title
  font-size: rem(16)
  font-weight: bold
  margin-right: $space-m

.application-modal__tips-list
  line-height: 1.8
  list-style-position: inside

.application-modal__body-title
  font-size: rem(14)
  font-weight: bold
  padding-bottom: $space-s
</style>
