<template>
  <div ref="form" class="create-login">
    <TabHeader class="create-login__header" :title="'Cadastrar aluno'" />
    <div class="create-login__body">
      <div class="create-login__title">
        Criar login do aluno
      </div>
      <div class="create-login__text">
        Ao criar um login para o usuário, ele receberá um e-mail para definição
        de senha.
      </div>
      <div class="create-login__group">
        <label class="create-login__label">Nome completo</label>
        <input
          id="name"
          v-model="formData.name.value"
          class="create-login__input"
          type="text"
          name="name"
          placeholder="Digite o nome do aluno"
          autocomplete="off"
          :disabled="userAlreadyHasName"
        />
        <Alert
          v-if="formData.name.alert"
          class="create-login__alert"
          :message="formData.name.alert.message"
          :type="formData.name.alert.type"
          :dismissable="false"
        />
      </div>
      <div class="create-login__group">
        <label class="create-login__label">E-mail</label>
        <input
          id="emailLogin"
          v-model="formData.emailLogin.value"
          class="create-login__input"
          type="text"
          name="emailLogin"
          :data-index="0"
          placeholder="nome@email.com"
          autocomplete="off"
        />
        <Alert
          v-if="formData.emailLogin.alert"
          class="create-login__alert"
          :message="formData.emailLogin.alert.message"
          :type="formData.emailLogin.alert.type"
          :dismissable="false"
        >
          <button
            v-if="formData.emailLogin.alert.action"
            class="create-login__alert-link"
            type="button"
            @click="formData.emailLogin.alert.action"
          >
            <span>{{ formData.emailLogin.alert.actionMessage }}</span>
          </button>
        </Alert>
      </div>
      <Button
        class="create-login__button"
        type="positive"
        :disabled="hasErrors"
        text="Criar login"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import TabHeader from '@/components/common/TabHeader';
import formLib, { areFieldsOk, cleanData } from '@/common_modules/formLib';
import getEventHandlers from '@/components/Profile/RegisterUserBody/eventHandlers';
import ProfileService from '@/assets/javascripts/services/ProfileService';
import Alert from '@/components/common/Alert';

import { mapState, mapActions, mapGetters } from 'vuex';
import Rollbar from '@/common_modules/tools/Rollbar';

export default {
  components: {
    Button,
    TabHeader,
    Alert,
  },
  data() {
    return {
      formData: {
        name: { value: null },
        emailLogin: { value: null },
      },
    };
  },
  computed: {
    ...mapState('Profile', ['profile', 'user']),
    ...mapGetters('Admin', ['getCurrentTenant']),
    hasErrors() {
      const fieldsToCheck = [
        {
          key: 'emailLogin',
          required: true,
        },
        {
          key: 'name',
          required: true,
        },
      ];
      const formCorrect = areFieldsOk(this.formData, fieldsToCheck);

      return !formCorrect;
    },
    userAlreadyHasName() {
      const { name } = this.profile;
      const nameIsAnonimo = name === 'Anônimo';

      return !!name && !nameIsAnonimo;
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    formLib(this.$refs.form, () => this.formData, getEventHandlers(this));
    this.$refs.form.querySelector('input:not([disabled])').focus();
  },
  methods: {
    ...mapActions('Profile', ['validateEmail', 'searchUser', 'refreshProfile']),
    ...mapActions('Notifications', ['notifySuccess', 'notifyError']),
    ...mapActions('RightPanel', ['tabGoBack', 'tabChange']),
    initialize() {
      this.formData.emailLogin.value = this.profile.emails[0];

      if (this.userAlreadyHasName) {
        this.formData.name.value = this.profile.name;
      }
    },
    async submit() {
      const cleanFormData = cleanData(this.formData);
      const email = cleanFormData.emailLogin;
      const name = cleanFormData.name;
      const baseUserId = this.user.base_user;
      const tenantIdentifier = this.getCurrentTenant.code;

      try {
        await ProfileService.createLogin({
          baseUserId,
          name,
          email,
          tenantIdentifier,
        });
      } catch (error) {
        Rollbar.error('CreateLogin | error to create login', {
          error,
          baseUserId,
          name,
          email,
        });
        return this.notifyError('Erro ao criar o login');
      }

      this.notifySuccess('Login criado com sucesso');
      this.refreshProfile();
      this.tabGoBack();
    },
  },
};
</script>

<style lang="sass">
.create-login
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.create-login__body
  flex-grow: 1
  overflow: auto
  padding: $space-st

.create-login__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.create-login__label
  display: block
  margin-bottom: $space-s

.create-login__group
  display: flex
  flex-direction: column
  margin-bottom: $space-m

.create-login__input
  @extend %opa-input
  flex-grow: 1
  margin-bottom: $space-s

.create-login__input:disabled
  cursor: not-allowed
  opacity: 0.3

.create-login__input::placeholder
  color: $chrome-gray

.create-login__text
  color: $silver-gray
  padding-bottom: $space-m

.create-login__alert
  width: 100%
  margin-bottom: $space-s

.create-login__alert-link
  color: $black-gray
  height: 100%
  font-weight: bold
  display: flex
  align-items: center
  margin-right: $space-m

.create-login__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-m
</style>
