import WhatsappService from '@/assets/javascripts/services/WhatsappService';
import _ from 'lodash';

const defaultQueueOptionId = 1;

export default {
  selectQueueOption({ commit }, queueOption) {
    commit('setQueueOptionSelected', queueOption);
    PersistentSelectedQueue.save(queueOption);
  },
  selectChat({ commit, dispatch }, { whatsappUsername, messagesLimit }) {
    if (!messagesLimit) {
      commit('loadAllChatMessagesStart');
    } else {
      commit('chatDeselect');
      commit('loadChatMessagesStart');
    }

    return WhatsappService.fetchActiveChat(whatsappUsername, messagesLimit)
      .then(payload => {
        commit('loadChatMessagesSuccess', payload);
      })
      .catch(error => {
        dispatch('StoreManager/notify', { message: error }, { root: true });
      });
  },
  sendMessage({ dispatch }, { message, shortcutId }) {
    dispatch('handleSendMessage', { message, shortcutId });
  },
  sendActiveMessage({ dispatch }, { hsmKey, withSubstitutions }) {
    dispatch('handleSendMessage', { hsmKey, withSubstitutions });
  },
  sendAttachment({ dispatch }, params) {
    return dispatch('handleSendMessage', params);
  },
  handleSendMessage(
    { state, dispatch, getters },
    {
      message: messageBody,
      hsmKey,
      fileName,
      data,
      withSubstitutions,
      shortcutId,
    }
  ) {
    const { chatSelected } = state;
    const { customer_id, whatsapp_username } = chatSelected;
    const isHsm = !!hsmKey;
    const isAttachment = !!fileName;
    const isActiveChat = !getters.isChatSelectedOpen;
    const messagesLimit = 20;

    return makeRequest()
      .then(message => {
        if (isActiveChat) {
          dispatch('selectChat', {
            whatsappUsername: message.whatsapp_username,
            messagesLimit,
          });
        } else {
          if (isAttachment) {
            message.whatsapp_attachments[0].url = data;
          }

          dispatch('handleNewMessage', { message, customer_id });
        }
      })
      .catch(error => {
        dispatch('StoreManager/notify', { message: error }, { root: true });
      });

    function makeRequest() {
      if (isAttachment) {
        return WhatsappService.sendAttachment(
          fileName,
          data,
          whatsapp_username
        );
      } else if (isHsm) {
        return WhatsappService.sendActiveMessage(
          hsmKey,
          whatsapp_username,
          withSubstitutions
        );
      } else {
        return WhatsappService.sendMessage(
          messageBody,
          whatsapp_username,
          shortcutId
        );
      }
    }
  },
  readChatSelected({ commit }) {
    commit('readWhatsappChat');
  },
  fetchShortcuts({ commit }, newMessage) {
    WhatsappService.fetchShortcuts(newMessage).then(response => {
      let newShortcuts = response.map(s =>
        Object.assign(s, {
          fullStr: `/${s.key} - ${s.message}`,
          key: `/${s.key}`,
        })
      );
      commit('setShortcuts', newShortcuts);
    });
  },
  async initialize({ commit, dispatch, state }) {
    commit('reset');

    await dispatch('updateQueueOptions');

    if (!state.setedListeners) {
      dispatch('setListeners');
    }
  },
  async updateQueueOptions({ commit, dispatch }) {
    commit('setQueueOptions', undefined);

    const options = await WhatsappService.getQueueOptions().catch(error => {
      dispatch('StoreManager/notify', { message: error }, { root: true });
    });
    if (options.length == 0) return;

    commit('setQueueOptions', options);
    const selectedQueue = PersistentSelectedQueue.retrieve(options);

    if (!selectedQueue) return;

    dispatch('selectQueueOption', selectedQueue);
  },
  setListeners({ commit, dispatch }) {
    WhatsappService.setOnNewMessageListener(msg => {
      dispatch('handleNewMessage', msg);
    });

    WhatsappService.setOnStatusUpdateListener(msg =>
      commit('updateMessageStatus', msg)
    );

    WhatsappService.setOnQueueSizeUpdateListener(
      ({ queue_size, chat_type }) => {
        commit('mayUpdateSizeOfQueues', {
          key: chat_type,
          size: queue_size,
        });
      }
    );

    WhatsappService.setOnNewAttachmentListener(msg =>
      commit('updateAttachment', msg)
    );

    commit('setedListeners');
  },
  async fetchAttendants({ commit, state }, tenantId) {
    const alreadyFetched = state.attendants.length !== 0;
    if (alreadyFetched) return;

    const attendants = await WhatsappService.getAttendants(tenantId);
    commit('setAttendants', attendants);
  },
  handleNewMessage({ state, dispatch, commit }, params) {
    const { message, customer_id } = params;
    const { chatSelected } = state;
    const atChatSelected =
      chatSelected &&
      chatSelected.whatsapp_username === message.whatsapp_username;

    if (atChatSelected) {
      commit('updateChatWithMessage', { message });
    }

    dispatch(
      'StoreManager/Querowhats/messageReceived',
      {
        customerId: customer_id,
        message,
      },
      { root: true }
    );
  },
  async getTransferOptions({ dispatch, state }, { whatsappHostKey, tenantId }) {
    const formatOptions = ({ name, id }) => ({ name, id });

    await dispatch('fetchAttendants', tenantId);

    const attendants = state.attendants.map(formatOptions);

    const filteredQueues = state.queueOptions.filter(
      ({ whatsapp_host }) => whatsapp_host.key === whatsappHostKey
    );
    const formatedQueues = filteredQueues.map(formatOptions);
    const queues = _.sortBy(formatedQueues, ['name']);

    return { queues, attendants };
  },
  tenantUpdated({ commit }) {
    commit('setQueueOptionSelected', null);
    PersistentSelectedQueue.clear();
  },
};

const PersistentSelectedQueue = {
  localStorageKey: 'queueOptionSelectedId',
  save(queueOption) {
    const queueOptionId = queueOption?.id || defaultQueueOptionId;

    localStorage.setItem(this.localStorageKey, queueOptionId);
  },
  clear() {
    localStorage.removeItem(this.localStorageKey);
  },
  retrieve(possibleOptions) {
    const savedSelectedQueueId = localStorage.getItem(this.localStorageKey);

    const optionIndex = _.findIndex(possibleOptions, {
      id: parseInt(savedSelectedQueueId),
    });
    if (optionIndex != -1) {
      return possibleOptions[optionIndex];
    }
  },
};
