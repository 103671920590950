<template>
  <Card class="price-card" :show-footer-details="!hasParcelamento">
    <template slot="header">
      <div class="price-card__header">
        <div class="price-card__header-item">
          <span class="price-card__big-text">{{ headerText }}</span>
        </div>
        <Badge v-if="hasRegressiveDiscount && !hasParcelamento" type="warning">
          Desconto Regressivo
        </Badge>
        <Badge v-if="hasParcelamento && !hasRegressiveDiscount" type="blue">
          Parcelamento
        </Badge>
      </div>
    </template>
    <div v-if="hasParcelamento" class="price-card__body">
      <div class="price-card__line price-card__line--spaced">
        <span>Valor sem Parcelamento</span>
        <span>
          {{ offer.offeredPrice | brlcurrency }}
        </span>
      </div>
      <div class="price-card__separator" />
      <div class="price-card__line price-card__line--spaced">
        <span>Valor durante o curso (50%)</span>
        <span>
          {{ (offer.offeredPrice / 2) | brlcurrency }}
        </span>
      </div>
      <div class="price-card__line price-card__line--spaced">
        <span>Valor após o curso (50%)</span>
        <span>
          {{ (offer.offeredPrice / 2) | brlcurrency }}
        </span>
      </div>
    </div>
    <div v-else class="price-card__body">
      <div class="price-card__line price-card__line--spaced">
        <span>Valor sem desconto</span>
        <span>
          {{ installmentsPrefix }}{{ offer.fullPrice | brlcurrency }}
        </span>
      </div>
      <div class="price-card__line price-card__line--spaced">
        <span>Desconto Quero Bolsa ({{ offer.discountPercentage }}%)</span>
        <span>
          {{ (offer.fullPrice - offer.offeredPrice) | brlcurrency }}
        </span>
      </div>
      <div class="price-card__separator" />
      <div
        class="price-card__line price-card__important price-card__line--spaced"
      >
        <span>Valor com desconto</span>
        <span>
          {{ installmentsPrefix }}{{ offer.offeredPrice | brlcurrency }}
        </span>
      </div>
    </div>
    <template
      v-if="offer.totalAbsoluteDiscount && !hasParcelamento"
      slot="infoFooter"
    >
      <div class="price-card__line price-card__line--spaced">
        <span>Economia Total</span>
        <span>
          {{ offer.totalAbsoluteDiscount | brlcurrency }}
        </span>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import {
  installmentsPrefix,
  installmentsType,
} from '@/assets/javascripts/formatters/Installments';

export default {
  components: {
    Card,
    Badge,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasRegressiveDiscount() {
      return this.offer.regressiveCommercialDiscount > 0;
    },
    hasParcelamento() {
      return this.offer.hasParcelamento;
    },
    headerText() {
      return installmentsType(this.course.level);
    },
    installmentsPrefix() {
      const {
        course: { level },
        offer: { maxPayments },
      } = this;

      const installmentsText = installmentsPrefix(level, maxPayments);

      return installmentsText ? `${installmentsText} ` : '';
    },
  },
};
</script>

<style lang="sass" scoped>
.price-card
  margin-bottom: $space-st
  color: $silver

.price-card__header
  display: flex
  justify-content: space-between
  align-items: flex-start
  width: 100%
  position: relative
  font-size: rem(18)
  font-weight: bold

.price-card__body
  color: $silver-gray
  padding: $space-s 0

.price-card__line
  margin: $space-s 0
  display: flex
  align-items: center

.price-card__line--spaced
  justify-content: space-between

.price-card__important
  font-weight: bold
  color: $white

.price-card__separator
  background-color: $star-gray
  height: 2px

.price-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold
</style>
