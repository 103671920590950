<template>
  <div class="follow-up-update-tab">
    <TabHeader title="Atualizar Status de Matrícula" />
    <template>
      <form class="follow-up-update__body" @submit.prevent="submit">
        <template slot="header" />
        <div class="opa__card-body">
          <div class="follow-up-update__label">
            Status
          </div>
          <Select
            v-model="selectedStep"
            class="follow-up-update__select"
            placeholder="Escolha o novo status"
            :list="followUpStepsList"
            label-property="name"
          />
          <div class="follow-up-update__label">
            Observações
          </div>
          <textarea
            v-model="observations"
            class="follow-up-update__textarea"
            placeholder="Escreva suas observações aqui..."
          />
          <Button
            class="follow-up-update__button"
            type="positive"
            :disabled="hasErrors"
            :text="submitting ? 'Aguarde...' : 'Atualizar'"
            :submit="true"
            :loading="submitting"
          />
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapState, mapActions } from 'vuex';
import FollowUpAPI from '@/api/followUpAPI';
import { followUpStepList } from '@/assets/javascripts/data/admissions/followUpStep.js';

export default {
  components: {
    TabHeader,
    Select,
    Button,
  },

  data: () => {
    return {
      selectedStep: null,
      observations: null,
      submitting: false,
    };
  },
  computed: {
    ...mapState('Order', ['followUp']),

    followUpData() {
      return this.followUp;
    },
    followUpStepsList() {
      return followUpStepList;
    },
    hasErrors() {
      return !this.selectedStep || !this.observations;
    },
  },
  methods: {
    ...mapActions('Profile', ['setFollowUp']),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),

    submit() {
      this.submitting = true;

      FollowUpAPI.updateStep({
        followUpId: this.followUpData.id,
        step: this.selectedStep.label,
        observations: this.observations,
      })
        .then(response => {
          this.setFollowUp(response.data.follow_up);
          this.notifySuccess('Status da matrícula atualizado!');
        })
        .catch(() => {
          this.notifyError(
            'A atualização do status falhou. Por favor, contate o suporte.'
          );
        })
        .finally(() => {
          this.submitting = false;
          this.tabGoBack();
        });
    },
  },
};
</script>

<style lang="sass">
.follow-up-update__body
  padding: $space-st

.follow-up-update__textarea
  @extend %opa-input
  width: 100%
  margin-bottom: $space-st
  height: 89px
  resize: none

.follow-up-update__label
  margin-bottom: $space-s
  font-weight: bold

.follow-up-update__select
  margin-bottom: $space-m

.follow-up-update__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
