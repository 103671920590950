<template>
  <div ref="form" class="update-email-login">
    <TabHeader class="update-email-login__header" :title="'Cadastrar aluno'" />
    <div class="update-email-login__body">
      <div class="update-email-login__title">
        Atualizar login do aluno
      </div>
      <div class="update-email-login__text">
        Ao atualizar o email de login, o aluno receberá um e-mail para definição
        de senha.
      </div>
      <div class="update-email-login__group">
        <label class="update-email-login__label">E-mail</label>
        <input
          id="emailLogin"
          v-model="formData.emailLogin.value"
          class="update-email-login__input"
          type="text"
          name="emailLogin"
          :data-index="0"
          placeholder="nome@email.com"
          autocomplete="off"
        />
      </div>
      <Button
        class="update-email-login__button"
        type="positive"
        :disabled="hasErrors"
        text="Atualizar login"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import TabHeader from '@/components/common/TabHeader';
import ProfileService from '@/assets/javascripts/services/ProfileService';

import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Button,
    TabHeader,
  },
  data() {
    return {
      formData: {
        emailLogin: { value: null },
      },
    };
  },
  computed: {
    ...mapState('Profile', ['profile', 'user']),
    hasErrors() {
      return !this.formData.emailLogin.value;
    },
  },
  created() {
    this.setEmailLoginDefaultValue();
  },
  methods: {
    ...mapActions('Profile', ['refreshProfile']),
    ...mapActions('Notifications', ['notifySuccess', 'notifyError']),
    ...mapActions('RightPanel', ['tabGoBack']),

    setEmailLoginDefaultValue() {
      this.formData.emailLogin.value = this.profile.logins[0].login_email;
    },

    async submit() {
      const email = this.formData.emailLogin.value;
      const baseUserId = this.user.base_user;

      try {
        await ProfileService.updateEmailLogin({
          baseUserId,
          email,
        });

        this.notifySuccess('Login atualizado com sucesso');
        this.refreshProfile();
        this.tabGoBack();
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          'Ocorreu um erro ao atualizar o email de login.';
        this.notifyError(message);
      }
    },
  },
};
</script>

<style lang="sass">
.update-email-login
  background-color: $space-gray
  display: flex
  flex-direction: column
  height: 100vh
  line-height: normal

.update-email-login__body
  flex-grow: 1
  overflow: auto
  padding: $space-st

.update-email-login__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.update-email-login__label
  display: block
  margin-bottom: $space-s

.update-email-login__group
  display: flex
  flex-direction: column
  margin-bottom: $space-m

.update-email-login__input
  @extend %opa-input
  flex-grow: 1
  margin-bottom: $space-s

.update-email-login__input:disabled
  cursor: not-allowed
  opacity: 0.3

.update-email-login__input::placeholder
  color: $chrome-gray

.update-email-login__text
  color: $silver-gray
  padding-bottom: $space-m

.update-email-login__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-m
</style>
