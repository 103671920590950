<template>
  <ClickableIcon v-clipboard:copy="clipboardText" svg-name="i-file-copy" />
</template>

<script>
import ClickableIcon from '@/components/common/ClickableIcon';

export default {
  components: {
    ClickableIcon,
  },
  props: {
    clipboardText: {
      type: String,
      required: true,
    },
  },
};
</script>
