<template>
  <div class="offer-details">
    <TabHeader class="offer-details__header" title="Detalhes e regras" />
    <div ref="scroller" class="offer-details__body">
      <Card class="opa__card" show-footer-template="showFooterTemplate">
        <template slot="header">
          <div class="opa__card-header">
            Detalhes, observações e regras
          </div>
        </template>
        <div
          ref="detailsCardBody"
          class="offer-details__details-body"
          :class="detailsCardBodyClass"
          v-html="extraInfo.observations"
        />
        <template slot="footer">
          <ShowMore v-model="showMore" />
        </template>
      </Card>
      <Card v-if="extraInfo.universityTweets.length !== 0" class="opa__card">
        <template slot="header">
          <div class="offer-details__university-header">
            <h2>{{ params.data.course.campus.university.name }}</h2>
          </div>
        </template>
        <div
          v-for="tweet in extraInfo.universityTweets"
          :key="tweet.id"
          class="offer-details__university-body"
        >
          <div class="offer-details__university-tweet" v-html="tweet.content" />
          <i>Atualizado em: {{ tweet.updatedAt | momentformat('L - LT') }}</i>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Card from '@/components/common/Card';
import ShowMore from '@/components/common/ShowMore';

export default {
  components: {
    TabHeader,
    Card,
    ShowMore,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    detailsCardBodyClass() {
      return this.showMore ? 'offer-details__details-body--more' : '';
    },
    extraInfo() {
      return this.params.data.extraInfo;
    },
  },
  watch: {
    showMore(value) {
      const detailsCardBody = this.$refs.detailsCardBody;
      if (value) {
        detailsCardBody.style.height = detailsCardBody.scrollHeight + 'px';
      } else {
        this.$refs.scroller.scrollTop = 0;
        detailsCardBody.style.height = null;
      }
    },
  },
};
</script>
