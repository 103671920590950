<template>
  <button
    class="opa-btn"
    :class="buttonClass"
    :disabled="disabled || loading"
    :type="buttonType"
    :title="title"
    @click="$emit('click')"
  >
    <div class="opa-btn__inner" :class="innerWrapperClass">
      <slot />
      <Icon v-if="icon" :svg-name="icon" type="small" />
      <span v-if="text" class="opa-btn__text">
        {{ text }}
      </span>
    </div>
  </button>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'positive',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    submit: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    buttonClass() {
      return {
        'opa-btn--main': this.type === 'positive',
        'opa-btn--negative': this.type === 'negative',
        'opa-btn--outline': this.type === 'dotted',
        'opa-btn--white': this.type === 'white',
        'opa-btn--tertiary': this.type === 'tertiary',
        'opa-btn--action': this.type === 'action',
        'opa-btn--loading': this.loading,
      };
    },
    buttonType() {
      if (this.submit) {
        return 'submit';
      }
      return 'button';
    },
    innerWrapperClass() {
      return {
        'opa-btn__inner--with-icon': this.icon,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.opa-btn__inner--with-icon
  display: flex

.opa-btn__text
  margin-left: $space-xs
</style>
