<template>
  <div class="opa-popover">
    <slot />
    <transition :name="transitionName">
      <slot v-if="popoverOpen" name="popover" />
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    mirrorEl: {
      type: Function,
      required: false,
      default: () => {},
    },
    openOn: {
      type: String,
      required: false,
      validator: value => ['focus', 'hover'].includes(value),
      default: 'focus',
    },
    transitionName: {
      type: String,
      required: false,
      default: 'slide',
    },
    toggleableFocus: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      popoverOpen: false,
    };
  },
  mounted() {
    const el = this.mirrorEl() || this.$el;
    const setPopoverState = this.setPopoverState;

    if (this.openOn == 'hover') {
      el.addEventListener('mouseenter', setPopoverState.bind(this, true));
      el.addEventListener('mouseleave', setPopoverState.bind(this, false));
    }

    if (this.openOn == 'focus') {
      el.addEventListener('focusin', this.setFocusWithin.bind(this, el));
      el.addEventListener('focusout', this.setFocusWithin.bind(this, el));
      if (this.toggleableFocus) {
        this.addToggleableFocus(el);
      }
    }
  },
  methods: {
    setPopoverState(state) {
      setTimeout(() => {
        this.popoverOpen = state;
      }, 200);

      this.$emit('input', state);
    },
    setFocusWithin(el) {
      const focusWithin = el.matches('*:focus-within');

      this.setPopoverState(focusWithin);
    },
    addToggleableFocus(el) {
      el.addEventListener('mousedown', () => {
        if (el.matches('*:focus')) {
          requestAnimationFrame(() => {
            el.blur();
          });
        }
      });
    },
  },
};
</script>
