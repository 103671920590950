<template>
  <div v-if="!emptyActiveNotifications" class="notifications">
    <div
      v-for="(notification, index) in activeNotifications"
      :key="index"
      class="notification"
      :class="`notification--${notification.status}`"
    >
      <button
        class="notification__close"
        type="button"
        @click="hideNotification(notification)"
      >
        <Icon
          class="notification__close__icon"
          svg-name="i-close"
          svg-alt="Fechar notificação"
        />
      </button>
      <Icon
        v-if="notification.icon"
        class="notification__icon"
        :svg-name="notification.icon"
      />
      <div class="notification__content">
        <strong v-if="notification.title" class="notification__title">{{
          notification.title
        }}</strong>
        <p
          v-if="notification.message"
          class="notification__message"
          v-html="notification.message"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Icon,
  },

  computed: {
    ...mapGetters('Notifications', [
      'emptyActiveNotifications',
      'activeNotifications',
    ]),
  },

  methods: {
    ...mapActions('Notifications', ['hideNotification']),
  },
};
</script>
