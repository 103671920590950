<template>
  <div class="send-bill">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <div class="send-bill__body">
      <SendBillBody
        ref="sendBillBody"
        v-model="body"
        :accepts-boleto="acceptsBoletoPayment"
      />
      <Button
        class="send-bill__btn"
        :disabled="body.hasErrors"
        :text="submitting ? 'Aguarde...' : 'Enviar cobrança'"
        :submit="true"
        :loading="submitting"
        @click="sendBill"
      />
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import SendBillBody from '@/components/Scholarship/SendBillBody';
import Button from '@/components/common/Button';
import OrderModel from '@/models/Order';
import { mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    SendBillBody,
    Button,
  },
  data() {
    return {
      body: {},
      submitting: false,
    };
  },
  computed: {
    ...mapGetters('Order', ['order', 'offer', 'offerNames']),
    acceptsBoletoPayment() {
      return OrderModel.acceptsBoletoPayment(
        this.order.result,
        this.offer.result.offer
      );
    },
  },
  methods: {
    async sendBill() {
      this.submitting = true;
      await this.$refs.sendBillBody.sendBill();
      this.submitting = false;
    },
  },
};
</script>

<style lang="sass">
.send-bill
  display: flex
  flex-direction: column
  height: 100vh
  background-color: $space-gray

.send-bill__body
  overflow: auto
  padding: $space-m

.send-bill__btn
  margin-top: $space-m
  flex-shrink: 0
  width: 100%
</style>
