<template>
  <div class="refund-request">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <div class="refund-request__scroller">
      <template v-if="isLoading">
        <content-loader
          class="refund-request__body"
          :width="400"
          :height="160"
          :speed="2"
          primary-color="#49555B"
          secondary-color="#272D30"
        >
          <rect x="4" y="3" rx="0" ry="0" width="343" height="22" />
          <rect x="4" y="43" rx="0" ry="0" width="343" height="15" />
          <rect x="4" y="66" rx="0" ry="0" width="172" height="15" />
        </content-loader>
      </template>
      <template v-else>
        <template v-if="refundRequestOutOfTime && !hasAuthorization">
          <form
            class="refund-request__body"
            @submit.prevent="submitAuthorization"
          >
            <div class="opa__card-body">
              <div class="refund-request__title">
                Essa ação necessita da autorização do time de reembolso.
              </div>
              <div class="refund-request__text">
                Essa bolsa se encontra fora do prazo de 7 dias para abertura de
                reembolso, a partir da data da compra. Essa ação foi liberada
                pelo time responsável?
              </div>
              <div class="refund-request__radio">
                <Radio
                  v-model="refundAuthorizationOption"
                  class="refund-request__radio-option"
                  label="Sim"
                  name="refund_authorization"
                  choice="yes"
                />
                <Radio
                  v-model="refundAuthorizationOption"
                  class="refund-request__radio-option"
                  label="Não"
                  name="refund_authorization"
                  choice="no"
                />
              </div>
              <Alert
                v-if="refundAuthorizationOption === 'no'"
                class="refund-request__manager-alert"
                :dismissable="false"
                type="error"
                message="A aprovação do time de reembolso é necessária"
              />
              <Button
                class="refund-request__button"
                type="positive"
                :disabled="refundAuthorizationOption !== 'yes'"
                :text="'Prosseguir'"
                :submit="true"
              />
            </div>
          </form>
        </template>
        <template v-else>
          <form class="refund-request__body">
            <Card class="opa__card">
              <template slot="header">
                <div class="opa__card-header">
                  Solicitar voucher ou reembolso
                </div>
              </template>
              <div class="opa__card-body">
                <div class="refund-request__label">
                  Motivo *
                </div>
                <Select
                  v-model="selectedReason"
                  class="refund-request__select"
                  placeholder="Escolha um motivo de reembolso"
                  :list="reasons || []"
                  label-property="name"
                />
                <div class="refund-request__label">
                  Contato *
                </div>
                <Select
                  v-model="selectedContact"
                  class="refund-request__select"
                  placeholder="Selecione um atendimento"
                  :list="contacts || []"
                  label-property="name"
                />
                <div class="refund-request__label">
                  Observações *
                </div>
                <textarea
                  v-model="observations"
                  class="refund-request__textarea"
                  placeholder="Escreva suas observações aqui..."
                />
                <div class="refund-request__label">
                  Qual o tipo de Reembolso? *
                </div>
                <div class="refund-request__radio">
                  <Radio
                    v-model="refundType"
                    class="refund-request__radio-option"
                    label="Voucher"
                    name="refund_type"
                    choice="voucher"
                  />
                  <Radio
                    v-model="refundType"
                    class="refund-request__radio-option"
                    label="Dinheiro"
                    name="refund_type"
                    choice="default"
                  />
                </div>
                <div class="refund-request__label">
                  É um atendimento Reclame Aqui? *
                </div>
                <div class="refund-request__radio">
                  <Radio
                    v-model="reclameAqui"
                    class="refund-request__radio-option"
                    label="Sim"
                    name="reclame_aqui"
                    choice="true"
                  />
                  <Radio
                    v-model="reclameAqui"
                    class="refund-request__radio-option"
                    label="Não"
                    name="reclame_aqui"
                    choice="false"
                  />
                </div>
                <Button
                  v-if="!revoucher && !hasUsedCodeVoucher && !hasSubscription"
                  class="refund-request__button js-generate-voucher"
                  type="positive"
                  :disabled="hasErrors"
                  :text="submitting ? 'Aguarde...' : 'Gerar Voucher'"
                  :loading="submitting"
                  @click="createRefundRequest(true)"
                />
                <Button
                  class="refund-request__button js-refund-request"
                  type="positive"
                  :disabled="hasErrors"
                  :text="submitting ? 'Aguarde...' : 'Solicitar Análise'"
                  :loading="submitting"
                  @click="createRefundRequest(false)"
                />
              </div>
            </Card>
          </form>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import Alert from '@/components/common/Alert';
import Card from '@/components/common/Card';
import Radio from '@/components/common/Radio';
import Rollbar from '@/common_modules/tools/Rollbar';
import { ContentLoader } from 'vue-content-loader';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Alert,
    Button,
    Card,
    ContentLoader,
    Radio,
    Select,
    TabHeader,
  },
  data: () => ({
    isLoading: true,
    refundAuthorizationOption: '',
    refundType: '',
    reclameAqui: '',
    refundRequestOutOfTime: null,
    selectedReason: null,
    selectedContact: null,
    hasAuthorization: false,
    contacts: null,
    reasons: null,
    observations: '',
    alert: null,
    submitting: false,
  }),
  computed: {
    ...mapGetters('Order', ['order', 'offerNames']),
    orderData() {
      return this.order.result;
    },
    hasErrors() {
      return (
        !this.selectedContact ||
        !this.observations ||
        !this.selectedReason ||
        this.refundType === '' ||
        this.reclameAqui === ''
      );
    },
    hasUsedCodeVoucher() {
      return this.orderData.hasUsedCodeVoucher;
    },
    hasSubscription() {
      return this.orderData.subscriptionTest;
    },
    revoucher() {
      return this.$attrs.params.revoucher;
    },
    orderId() {
      return this.$attrs.params.orderId;
    },
    cancelVoucherCode() {
      return this.$attrs.params.cancelVoucherCode;
    },
  },
  async created() {
    this.isLoading = true;

    try {
      if (this.orderId) {
        await this.fetchOrder({ orderId: this.orderId });
      }

      const {
        data: { refund_data: refundData },
      } = await this.requestRefundInfo();

      this.contacts = Object.keys(refundData.contacts).map(key => ({
        name: refundData.contacts[key],
        value: key,
      }));

      this.reasons = Object.keys(refundData.reasons).map(key => ({
        name: refundData.reasons[key],
        value: key,
      }));

      this.refundRequestOutOfTime = refundData.refundRequestOutOfTime;
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.notifyError('Erro ao buscar dados. Tente novamente.');
      this.tabGoBack();
    }
  },
  methods: {
    ...mapActions('Order', [
      'requestRefundInfo',
      'submitRefundRequestClicked',
      'fetchOrder',
    ]),
    ...mapActions('Notifications', ['notifyError', 'notifySuccess']),
    ...mapActions('RightPanel', ['tabGoBack']),
    async createRefundRequest(generateVoucher) {
      this.submitting = true;

      try {
        await this.submitRefundRequestClicked({
          contact: this.selectedContact.value,
          observations: this.observations,
          reason: this.selectedReason.value,
          refundType: this.refundType,
          reclameAqui: this.reclameAqui === 'true',
          cancelVoucherCode: this.cancelVoucherCode,
          generateVoucher,
        });

        if (generateVoucher) {
          this.notifySuccess('Voucher criado com sucesso!');
        } else {
          this.notifySuccess(
            'A solicitação de reembolso foi criada com sucesso!'
          );
        }
      } catch (error) {
        Rollbar.error('RefundRequest | error to submit', { error });
        this.$faro.api.pushError(error, {
          context: {
            message: 'RefundRequest | error to submit',
          },
        });

        this.notifyError(
          'A solicitação de reembolso falhou. Por favor, contate o suporte.'
        );
      } finally {
        this.submitting = false;
      }
    },
    submitAuthorization() {
      this.hasAuthorization = true;
    },
  },
};
</script>

<style lang="sass">
.refund-request
  display: flex
  flex-direction: column
  height: 100%
  background-color: $space-gray

.refund-request__scroller
  flex-grow: 1
  overflow: auto

.refund-request__body
  padding: $space-st

.refund-request__title
  display: flex
  justify-content: space-between
  font-size: rem(18)
  font-weight: bold
  color: white
  padding-bottom: $space-s

.refund-request__text
  padding: $space-s 0

.refund-request__textarea
  @extend %opa-input
  width: 100%
  margin-bottom: $space-st
  height: 89px
  resize: none

.refund-request__label
  margin-bottom: $space-s
  font-weight: bold

.refund-request__text-gray
  padding: $space-s 0
  color: $chrome-gray

.refund-request__radio
  display: flex

.refund-request__radio-option
  margin-right: $space-st

.refund-request__select
  margin-bottom: $space-m

.refund-request__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
