<template>
  <div class="opa-card">
    <div class="opa-card__header">
      <slot name="header" />
    </div>
    <div class="opa-card__content">
      <div class="opa-card__body">
        <slot />
      </div>
    </div>
    <div v-if="thereIsFooter" class="opa-card__footer">
      <template v-if="showPrevious">
        <button
          class="opa-card__btn opa-card__btn--left"
          type="button"
          @click="$emit('clicked', 'previous')"
        >
          <Icon svg-name="i-chevron-left" svg-class="opa-card__btn-icon" />
          <span class="opa-card__btn-text">
            Anterior
          </span>
        </button>
      </template>
      <template v-if="showNext">
        <button
          class="opa-card__btn opa-card__btn--right"
          type="button"
          @click="$emit('clicked', 'next')"
        >
          <span class="opa-card__btn-text">Próximo</span>
          <Icon svg-name="i-chevron-right" svg-class="opa-card__btn-icon" />
        </button>
      </template>
      <template v-if="showMore">
        <button
          class="opa-card__btn opa-card__btn--right"
          type="button"
          @click="$emit('clicked', 'more')"
        >
          <span class="opa-card__btn-text">Ver mais detalhes</span>
          <Icon svg-name="i-chevron-right" svg-class="opa-card__btn-icon" />
        </button>
      </template>
      <slot v-if="showFooterTemplate" name="footer" />
    </div>
    <div v-if="showFooterDetails" class="opa-card__info-footer">
      <slot name="infoFooter" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    showPrevious: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNext: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMore: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFooterTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFooterDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    thereIsFooter() {
      return (
        this.showFooterTemplate ||
        this.showPrevious ||
        this.showNext ||
        this.showMore
      );
    },
  },
};
</script>
