<template>
  <div class="register-contact">
    <TabHeader title="Registro de atendimento" />
    <form class="register-contact__body" @submit.prevent="submit">
      <template v-if="!loadedContacts" />
      <template v-else-if="nothingToRegister">
        <div class="register-contact__item-empty">
          Não há atendimentos para serem registrados.
        </div>
      </template>
      <template v-else>
        <div
          class="register-contact__item register-contact__item--choose-contacts"
        >
          <div class="register-contact__label">
            Atendimentos que serão registrados
          </div>
          <template v-for="cc in customerContacts">
            <div
              :key="cc.customer_contact_id"
              class="register-contact__l-checkbox"
            >
              <Checkbox
                v-model="cc.selected"
                :label="cc.contact_type | contactTypeTranslate"
              >
                <template #default="slotProps">
                  <Icon
                    class="register-contact__label-icon"
                    :svg-name="cc.contact_type | contactTypeIcon"
                  />{{ slotProps.label }}
                </template>
              </Checkbox>
            </div>
          </template>
        </div>
      </template>
      <template v-if="loadedContacts">
        <div v-if="!newCustomerContactClicked" class="new-customer-contact">
          <button
            class="new-customer-contact__contact-type-btn"
            @click="newCustomerContact"
          >
            <icon
              svg-name="i-plus"
              svg-class="new-customer-contact__icon"
            />Adicionar novo atendimento
          </button>
        </div>
        <template v-else>
          <Select
            v-model="selectedPossibleContactType"
            class="new-customer-contact__contact-type-select"
            placeholder="Escolha um atendimento"
            :list="possibleContactTypes"
            :autofocus="true"
            label-property="label"
          />
        </template>
      </template>
      <template v-if="!nothingToRegister && loadedContacts">
        <div class="register-contact__item">
          <div class="register-contact__label">Grupo de atendimento</div>
          <Select
            v-model="selectedGroup"
            class="register-contact__select register-contact__item"
            placeholder="Informe o tipo do registro"
            :list="reasons"
            :autofocus="true"
          >
            <template slot-scope="{ item }">
              <div class="register-contact__reason">
                <div class="register-contact__name">{{ item.group }}</div>
              </div>
            </template>
          </Select>
          <div class="register-contact__label">Tipo do atendimento</div>
          <Select
            v-model="selectedReason"
            class="register-contact__select"
            placeholder="Informe o tipo do registro"
            :list="filterReasonsByGroup"
          >
            <template slot-scope="{ item }">
              <div class="register-contact__reason">
                <div class="register-contact__name">{{ item['reason'] }}</div>
              </div>
            </template>
          </Select>
        </div>
        <template v-if="hasWhatsappContactSelected && chats">
          <div class="register-contact__label">
            Selecione o chat que você deseja registrar
          </div>
          <Select
            v-model="chatSelected"
            class="register-contact__select"
            placeholder="Escolher chat"
            :list="formatWhatsappChats(chats)"
            label-property="whatsappUsernameTranslated"
          />
        </template>
        <div class="register-contact__item">
          <div class="register-contact__label">
            Se necessário informe alguma observação
          </div>
          <textarea
            v-model="observations"
            class="register-contact__textarea"
            placeholder="Ex.: O aluno não conseguiu efetuar o pagamento no Checkout, porque o site estava fora do ar"
          />
        </div>
        <div class="register-contact__item">
          <div class="register-contact__label">Anexo de arquivos</div>
          <button
            class="register-contact__attachment-button"
            type="button"
            @click="attachmentButtonClicked"
          >
            <icon
              class="register-contact__attachment-icon"
              svg-name="i-attach"
              svg-alt="Anexar arquivo"
            />
            <div class="register-contact__attachment-text">
              <div class="opa-bold">Anexar</div>
              {{ attachmentsMessage }}
            </div>
          </button>
          <FileSelector ref="fileSelector" @filesSelected="onFilesSelected" />
        </div>
        <Alert
          class="register-contact__alert"
          :message="alertMessage"
          type="error"
          :dismissable="false"
        />
        <Button
          class="register-contact__button"
          type="positive"
          :disabled="hasErrors || submited"
          :text="submitting ? 'Aguarde...' : 'Registrar contato'"
          :submit="true"
          :loading="submitting"
        />
      </template>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Rollbar from '@/common_modules/tools/Rollbar';
import {
  contactTypeTranslate,
  contactTypeIcon,
} from '@/assets/javascripts/data/customerContact';

import Alert from '@/components/common/Alert';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import FileSelector from '@/components/common/FileSelector';
import formatFeedbackMessage from '@/assets/javascripts/formatters/FeedbackMessageFormatter.js';
import Icon from '@/components/common/Icon';
import { possibleContactTypes } from '@/assets/javascripts/data/customerContact';
import Select from '@/components/common/Select';
import TabHeader from '@/components/common/TabHeader';
import ticketAPI from '@/api/ticketAPI';

export default {
  components: {
    Alert,
    Button,
    Checkbox,
    FileSelector,
    Icon,
    Select,
    TabHeader,
  },

  filters: {
    contactTypeIcon,
    contactTypeTranslate,
  },

  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    reasons: [],
    selectedGroup: {},
    selectedReason: {},
    observations: '',
    alertMessage: null,
    submitting: false,
    submited: false,
    customerContacts: [],
    chats: [],
    chatSelected: null,
    loadedContacts: false,
    newCustomerContactClicked: false,
    selectedPossibleContactType: null,
    attachments: [],
    possibleContactTypes,
  }),

  computed: {
    ...mapGetters('Tickets', ['formatWhatsappChats']),
    hasErrors() {
      const reasonNotSelected = !(
        this.selectedReason && this.selectedReason.id
      );

      const contactNotSelected =
        this.customerContacts.length > 0 &&
        this.selectedCustomerContactIds.length === 0;

      if (this.hasWhatsappContactSelected) {
        return reasonNotSelected || contactNotSelected || !this.chatSelected;
      }

      return reasonNotSelected || contactNotSelected;
    },

    selectedCustomerContactIds() {
      return this.customerContacts
        .filter(contact => contact.selected)
        .map(contact => contact.customer_contact_id);
    },

    selectedCustomerContactTypes() {
      return this.customerContacts
        .filter(contact => contact.selected)
        .map(contact => contact.contact_type);
    },

    nothingToRegister() {
      return !this.customerContacts || this.customerContacts.length === 0;
    },

    attachmentsMessage() {
      const { length } = this.attachments;

      return `${length} ${this.$pluralize('arquivo', length)} ${this.$pluralize(
        'anexado',
        length
      )}`;
    },

    hasWhatsappContactSelected() {
      return this.selectedCustomerContactTypes.includes('whatsapp');
    },

    allContacts() {
      if (this.hasWhatsappContactSelected) {
        return this.allCostumerContacts && this.chats.length == 1;
      }

      return this.allCostumerContacts;
    },

    allCostumerContacts() {
      return (
        this.customerContacts.length == this.selectedCustomerContactIds.length
      );
    },

    whatsappUsername() {
      return this.chatSelected ? this.chatSelected.whatsappUsername : '';
    },

    filterReasonsByGroup() {
      const selected = this.reasons.find(
        item => item.group === this.selectedGroup.group
      )?.reasons;
      return selected ? selected : [];
    },
  },

  watch: {
    async selectedPossibleContactType(contactType) {
      if (contactType == null) return;
      await this.startNewCustomerContact(contactType);
      await this.populateCustomerContactsAndChats();
      this.selectedPossibleContactType = null;
      this.newCustomerContactClicked = false;
    },
  },

  async created() {
    await this.populateCustomerContactsAndChats();
    const {
      data: { results },
    } = await ticketAPI.getContactReasons(this.params.tenantId);
    this.reasons = this.groupByReason(results);
  },

  methods: {
    ...mapActions('Tickets', [
      'startCustomerContact',
      'getContactReasons',
      'getDraftCustomerContactsAndChats',
      'registerContactsSubmited',
    ]),

    async populateCustomerContactsAndChats() {
      const { contacts, chats } = await this.getDraftCustomerContactsAndChats(
        this.params.customerId
      );

      this.customerContacts = contacts.map(item => ({
        ...item,
        selected: true,
      }));

      this.chats = chats;
      this.loadedContacts = true;
    },

    startNewCustomerContact(contactType) {
      return this.startCustomerContact({
        customerId: this.params.customerId,
        contactType: contactType.value,
      });
    },

    newCustomerContact() {
      this.newCustomerContactClicked = true;
    },

    async submit() {
      this.submitting = true;
      this.alert = undefined;

      try {
        await this.registerContactsSubmited({
          allContacts: this.allContacts,
          contactReasonId: this.selectedReason.id,
          customerContactIds: this.selectedCustomerContactIds,
          contactTypes: this.selectedCustomerContactTypes,
          observations: this.observations,
          customerId: this.params.customerId,
          whatsappUsername: this.whatsappUsername,
          attachments: this.attachments,
        });
      } catch (error) {
        const message = formatFeedbackMessage(error);

        this.alertMessage = message || 'Ocorreu um erro ao registrar o contato';

        Rollbar.error('RegisterContact | error to submit', {
          error,
          customerContactIds: this.selectedCustomerContactIds,
        });
        this.submitting = false;
      }
    },

    groupByReason(data) {
      return Object.values(
        data.reduce((acc, item) => {
          const { group, id, reason } = item;
          if (!acc[group]) {
            acc[group] = { group, reasons: [] };
          }
          acc[group].reasons.push({ id, reason });
          return acc;
        }, {})
      );
    },

    attachmentButtonClicked() {
      this.$refs.fileSelector.openFileSelector();
    },

    onFilesSelected(files) {
      files.forEach(({ fileName, data }) =>
        this.attachments.push({ fileName, data })
      );
    },
  },
};
</script>
