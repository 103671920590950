<template>
  <div>
    <div class="register-order-rg__label">
      RG *
    </div>
    <input
      ref="document"
      v-model="value.document"
      class="register-order-rg__input"
      type="text"
      name="document"
      placeholder="Ex: 108.032.123"
      autocomplete="off"
    />
    <div class="register-order-rg__label">
      Orgão expedidor *
    </div>
    <input
      ref="documentExpeditor"
      v-model="value.documentExpeditor"
      class="register-order-rg__input"
      type="text"
      name="documentExpeditor"
      placeholder="Ex: SSP"
      autocomplete="off"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasErrors() {
      return !this.value.document || !this.value.documentExpeditor;
    },
  },
  watch: {
    hasErrors() {
      this.$emit('input', {
        ...this.value,
        hasErrors: this.hasErrors,
      });
    },
  },
};
</script>

<style lang="sass">
.register-order-rg__label
  margin-bottom: $space-s

.register-order-rg__input
  @extend %opa-input
  width: 100%
  margin-bottom: $space-st
</style>
