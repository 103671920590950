<template>
  <div class="register-bo">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <div class="register-bo__body">
      <Card class="opa__card">
        <template slot="header">
          <div class="opa__card-header">
            Registrar B.O.
          </div>
        </template>
        <div class="opa__card-body">
          <div v-if="options.types" class="register-bo__type-container">
            <div class="register-bo__label">
              Tipo
            </div>
            <Select
              v-model="fields.type"
              class="register-bo__select"
              placeholder="Selecione um tipo"
              :list="options.types"
              label-property="name"
            />
          </div>
          <div class="register-bo__label">
            Motivo
          </div>
          <Select
            v-model="fields.reason"
            class="register-bo__select"
            placeholder="Selecione um tipo"
            :list="options.reasons || []"
            label-property="name"
          />
          <div class="register-bo__label">
            Contato
          </div>
          <Select
            v-model="fields.contact"
            class="register-bo__select"
            :disabled="!hasContactEvents"
            :placeholder="
              hasContactEvents
                ? 'Selecione um contato'
                : 'Nenhum contato para selecionar'
            "
            :list="options.contacts || []"
            label-property="name"
          />
          <Checkbox v-model="fields.reclameAqui" label="Reclame Aqui" />
          <div class="register-bo__label">
            Observações
          </div>
          <textarea
            v-model="fields.observations"
            class="register-bo__textarea"
            placeholder="Acrescente alguma informação relativa ao caso, se necessário."
          />
          <Alert
            v-if="failed"
            class="register-bo__alert"
            :message="alertMessage"
            :type="alertType"
            :dismissable="false"
          />
          <Button
            class="register-bo__button"
            type="positive"
            :disabled="hasFilledFields === false"
            :text="submitting ? 'Aguarde...' : 'Registrar B.O.'"
            :loading="submitting"
            @click="submit"
          />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import Alert from '@/components/common/Alert';
import Card from '@/components/common/Card';
import Checkbox from '@/components/common/Checkbox';
import Rollbar from '@/common_modules/tools/Rollbar';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
    Select,
    Alert,
    Card,
    Checkbox,
  },
  data() {
    return {
      fields: {
        type: null,
        reason: null,
        reclameAqui: false,
        observations: null,
      },
      options: {},
      submitting: false,
      failed: false,
      alertMessage: null,
      alertType: 'error',
    };
  },
  computed: {
    ...mapGetters('Order', ['offerNames']),
    hasFilledFields() {
      const { type, reason, observations, contact } = this.fields;
      const isStudentBo = this.options && this.options.types == null;

      if (isStudentBo) return Boolean(reason && observations && contact);

      return Boolean(type && reason && observations && contact);
    },
    hasContactEvents() {
      return this.options.contacts && this.options.contacts.length > 0;
    },
  },
  async created() {
    const options = await this.getRegisterBOOptions();
    this.options = options || {};
  },
  methods: {
    ...mapActions('Order', ['submitRegisterBOCliked', 'getRegisterBOOptions']),
    async submit() {
      this.failed = false;
      this.submitting = true;

      try {
        await this.submitRegisterBOCliked(this.fields);
      } catch (errors) {
        if (
          errors.response.data &&
          errors.response.data.message == 'bo_already_created'
        ) {
          this.alertType = 'warning';
          this.alertMessage =
            'Não é possível abrir essa solicitação para este motivo.' +
            ' Um B.O existente já foi cadastrado, e o tempo de atendimento ainda não foi expirado.';
        } else {
          this.alertMessage = 'Ocorreu um erro ao registrar B.O.';
        }

        this.failed = true;
        Rollbar.error('RegisterBO | error to submit', { error: errors });
        this.$faro.api.pushError(errors, {
          context: {
            message: 'RegisterBO | error to submit',
          },
        });
      }

      this.submitting = false;
    },
  },
};
</script>
