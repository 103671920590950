<template>
  <div class="blank-input-list">
    <slot :item="value[0]" :index="0" />
    <slot name="bottom" :item="value[0]" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.blank-input-list
  display: flex
  flex-direction: column
</style>
