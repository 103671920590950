<template>
  <div class="tenant-list">
    <div class="tenant-list__tenant-items">
      <TenantItem
        v-for="tenant in tenantsSelected"
        :key="tenant.slug"
        :tenant="tenant"
        :size="sizeByTenantSlug[tenant.slug]"
        :selected="tenant.slug === currentTenant.slug"
        @click="tenantFilterSelected(tenant)"
      />
    </div>
    <button ref="selectBox" class="tenant-list__action-button">
      <Popover
        class="tenant-list__popover"
        :mirror-el="() => $refs.selectBox"
        :toggleable-focus="false"
      >
        <Icon class="tenant-list__action-icon" :svg-name="'i-plus-inverted'" />
        <template slot="popover">
          <Tooltip
            class="tenant-list__tooltip"
            special-position="top-right"
            overflow="overflow"
          >
            <div class="tenant-list__tooltip-text">
              Editar Marcas
            </div>
            <div
              v-for="tenant in componentTenantsList"
              :key="tenant.slug"
              class="tenant-list__checkobox"
            >
              <Checkbox
                v-model="tenant.selected"
                class="tenant-list__checkobox-item"
                :label="tenant.name"
                :enabled="enableCheckbox(tenant)"
              />
            </div>
          </Tooltip>
        </template>
      </Popover>
    </button>
  </div>
</template>

<script>
import TenantItem from '@/components/sidebar/TenantItem';
import Icon from '@/components/common/Icon';
import Popover from '@/components/common/Popover';
import Tooltip from '@/components/common/Tooltip';
import Checkbox from '@/components/common/Checkbox';
import { mapState, mapActions } from 'vuex';

const DEFAULT_SELECTED_TENANT = ['quero-bolsa'];

export default {
  components: {
    TenantItem,
    Icon,
    Tooltip,
    Popover,
    Checkbox,
  },
  data: () => ({
    componentTenantsList: [],
  }),
  computed: {
    ...mapState('Admin', ['tenantsList', 'currentTenant']),
    ...mapState('Whatsapp', ['queueOptions']),
    isLoading() {
      return this.queueOptions === undefined;
    },
    tenantsSelected() {
      return this.componentTenantsList.filter(tenant => tenant.selected);
    },
    sizeByTenantSlug() {
      if (this.isLoading) return [];

      return this.queueOptions.reduce((acc, queue) => {
        const { slug } = queue.whatsapp_host.tenant;
        const accSize = acc[slug] || 0;
        const newSize = accSize + queue.size;

        return { ...acc, [slug]: newSize };
      }, {});
    },
  },
  watch: {
    tenantsSelected: {
      handler() {
        this.saveTenantsInLocalStorage(this.tenantsSelected);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadTenants();

    const savedTenantSlugs = this.getTenantsInLocalStorage() || [];
    const tenantListCopy = [...this.tenantsList];
    const tenantListWithSelected = tenantListCopy.map(tenant => {
      const selected = savedTenantSlugs.some(slug => slug === tenant.slug);

      return { ...tenant, selected };
    });

    this.componentTenantsList = tenantListWithSelected;
  },
  methods: {
    ...mapActions('Admin', ['loadTenants', 'updateCurrentTenant']),
    ...mapActions('Tickets', ['deselectedTicket']),
    tenantFilterSelected(tenant) {
      this.updateCurrentTenant(tenant);
      this.deselectedTicket();
    },
    enableCheckbox(tenant) {
      const isNotCurrentTenant = !(tenant.slug === this.currentTenant.slug);
      const isNotTenantSelected = !tenant.selected;

      return isNotTenantSelected || isNotCurrentTenant;
    },
    saveTenantsInLocalStorage(tenantsSelected) {
      const tenantSlugsSelected = tenantsSelected.map(tenant => tenant.slug);

      localStorage.setItem(
        'tenantSlugsSelected',
        JSON.stringify(tenantSlugsSelected)
      );
    },
    getTenantsInLocalStorage() {
      const savedTenantSlugsJSON = localStorage.getItem('tenantSlugsSelected');
      const savedTenantSlugs = JSON.parse(savedTenantSlugsJSON) || [];

      return savedTenantSlugs.length > 0
        ? savedTenantSlugs
        : DEFAULT_SELECTED_TENANT;
    },
  },
};
</script>

<style lang="sass">
.tenant-list
  flex-direction: column
  padding: $space-s 0

.tenant-list__tenant-items
  padding-top: $space-xs
  max-height: 455px
  overflow-y: auto
  +flex-centered

.tenant-list__action-button
  margin: 0 $space-xs
  border-bottom: 1px solid gray
  cursor: pointer
  padding: $space-s 0
  width: 87%

.tenant-list__action-icon
  +square(30px)

.tenant-list__tooltip
  padding: $space-m $space-m
  white-space: nowrap
  width: 250px

.tenant-list__tooltip-text
  font-weight: $fw-bold
  padding: $space-m $space-m
  text-align: left
  font-size: rem(16)

.tenant-list__checkobox
  display: flex
  margin-bottom: $space-s

.tenant-list__checkobox-item
  font-size: rem(14)
  margin: $space-s $space-m
  margin-bottom: $space-s
  white-space: nowrap
</style>
