<template>
  <div class="profile-head">
    <ProfileActions />
    <div class="profile-head__layout-avatar">
      <div
        class="profile-head__impersonate-btn"
        title="Impersonar aluno"
        @click="impersonate"
      >
        <Avatar
          class="profile-head__avatar"
          :tenant-id="profile.tenantId"
          :has-login="hasLogin"
          big="big"
        />
      </div>
    </div>
    <template v-if="!profileLoading">
      <div class="profile-head__l-name">
        <div
          :is="hasRelatedProfiles ? 'button' : 'div'"
          ref="usersDrop"
          class="profile-head__name-but"
          :class="hasRelatedProfiles ? 'profile-head__name-but--more' : ''"
        >
          <div class="profile-head__group">
            <h3 class="profile-head__name">
              {{ profile.name }}
            </h3>
            <UserInfoBadge
              class="profile-head__badges"
              :user-info="profile"
              color="#FFF"
            />
            <Popover
              v-if="hasRelatedProfiles"
              class="profile-head__popover"
              open-on="focus"
              :mirror-el="() => $refs.usersDrop"
            >
              <Icon
                svg-class="profile-head__drop"
                svg-name="i-chevron-right"
                svg-alt="Escolher usuário"
              />
              <template slot="popover">
                <Tooltip vposition="down" hposition="right">
                  <ul class="profile-head__user-list">
                    <li
                      v-for="relatedProfile in relatedProfiles"
                      :key="relatedProfile.base_user_id"
                      class="profile-head__user-item"
                      @click="chooseProfile(relatedProfile)"
                    >
                      <div
                        class="profile-head__user-text"
                        :class="{
                          'profile-head__user-text--active':
                            relatedProfile.base_user_id === originalProfileId,
                        }"
                        :title="
                          relatedProfile.base_user_id === originalProfileId
                            ? 'Perfil Original'
                            : ''
                        "
                      >
                        {{ relatedProfile.name || 'Anônimo' }}
                      </div>
                    </li>
                  </ul>
                </Tooltip>
              </template>
            </Popover>
          </div>
        </div>
        <div class="profile-profile-head__reclame-aqui">
          <img
            v-if="hasReclameAquiContact"
            class="profile-head__reclame-aqui-logo profile-head__complain-image"
            :src="reclameAquiContactImage"
            :alt="reclameAquiContactAltText"
          />
        </div>
      </div>
      <FollowUp v-if="profile.followUp" :follow-up="profile.followUp" />
      <div class="profile-head__w-user-identification">
        <div v-if="profile.cpf" class="profile-head__c-cpf">
          <span>CPF:</span>
          <div class="profile-head__cpf">
            {{ profile.cpf }}
          </div>
        </div>
        <div class="profile-head__c-id">
          <span>ID:</span>
          <div class="profile-head__id">
            {{ profile.id }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import Avatar from '@/components/common/Avatar';
import Tooltip from '@/components/common/Tooltip';
import Popover from '@/components/common/Popover';
import Icon from '@/components/common/Icon';
import ProfileActions from '@/components/Profile/ProfileActions';
import FollowUp from '@/components/Profile/FollowUp';
import UserInfoBadge from '@/components/Profile/UserInfoBadge';

export default {
  components: {
    Icon,
    Avatar,
    ProfileActions,
    FollowUp,
    Tooltip,
    Popover,
    UserInfoBadge,
  },
  computed: {
    ...mapState('Profile', ['profile', 'profileLoading', 'relatedProfiles']),
    ...mapGetters('Profile', ['hasLogin']),
    ...mapState('Profile', { chooseProfileData: 'chooseProfile' }),
    hasRelatedProfiles() {
      return this.relatedProfiles.length > 1;
    },
    originalProfileId() {
      return this.chooseProfileData.originalId || this.profile.id;
    },
    hasReclameAquiContact() {
      return (
        this.profile.hasComplainHereContact ||
        this.profile.hasResolvedReclameAquiContact
      );
    },

    reclameAquiContactImage() {
      return this.profile.hasResolvedReclameAquiContact
        ? '/images/reclame-aqui-gray.png'
        : '/images/reclame-aqui-logo.png';
    },

    reclameAquiContactAltText() {
      return this.profile.hasResolvedReclameAquiContact
        ? 'Reclame Aqui - Contato Resolvido'
        : 'Reclame Aqui - Contato não resolvido';
    },
  },
  methods: {
    ...mapActions('Profile', ['chooseProfile']),
    impersonate() {
      window.open(`/impersonate/${this.profile.id}`, '_blank');
    },
  },
};
</script>

<style lang="sass">
.profile-head
  background: linear-gradient(45deg, $space-gray, $light-black 70%)
  padding-bottom: $space-st
  border-bottom: 1px solid $dark-gray
  padding-top: $space-m

.profile-head__layout-avatar
  display: flex
  justify-content: center
  margin-top: $space-m

.profile-head__avatar
  cursor: pointer
  display: block

.profile-head__l-name
  display: flex
  justify-content: center
  align-items: center
  margin-top: $space-m
  letter-spacing: 1px
  padding: 0 $space-m
  flex-direction: column

.profile-head__popover
  display: inline
  +square(16px)

.profile-head__drop
  +square(16px)
  transform: rotate(90deg)

.profile-head__name
  text-align: center

.profile-head__name-but
  +flex-centered
  outline: none

.profile-head__name-but--more
  cursor: pointer

.profile-head__badges
  @extend %truncate
  +flex-centered
  font-size: rem(14)
  justify-content: flex-start

.profile-head__w-user-identification
  margin-top: 11px
  display: flex
  flex-direction: row
  justify-content: center
  font-size: rem(13)
  font-weight: bold
  height: 15px
  align-items: center

.profile-head__c-cpf
  display: flex

.profile-head__cpf
  font-weight: normal
  margin-left: $space-xs
  margin-right: $space-m

.profile-head__c-id
  display: flex

.profile-head__id
  font-weight: normal
  margin-left: $space-xs

.profile-head__user-list
  list-style: none
  padding: $space-s 0

.profile-head__user-item
  cursor: pointer

  &:hover
    background: $dark-gray

    + .profile-head__user-item
      &::before
        width: 100%

  &:not(:first-child)
    // Option divider
    &::before
      background: $dark-gray
      content: ""
      display: block
      height: 1px
      margin-left: auto
      margin-right: auto
      width: calc(100% - #{$space-st * 2})

.profile-head__user-text
  align-items: center
  color: $white
  display: flex
  font-size: rem(14)
  font-weight: $fw-normal
  justify-content: space-between
  padding: $space-s $space-m
  white-space: nowrap

.profile-head__user-text--active
  font-weight: $fw-semibold

.profile-head__integration-icon
  margin: auto $space-xs auto $space-xs
  +square(19px)

.profile-head__reclame-aqui-logo
  height: 20px
  margin: $space-s
  display: flex
  align-items: center

.profile-head__group
  display: flex
  flex-direction: row
  align-items: center
</style>
