<template>
  <div ref="form" class="expires-boleto">
    <TabHeader
      :title="offerNames.course || '.'"
      :sub-title="offerNames.university || '.'"
    />
    <div class="expires-boleto__body">
      <div class="expires-boleto__title">
        Expirar boleto
      </div>
      <div class="expires-boleto__message">
        Você tem certeza que deseja expirar este boleto?
      </div>
      <div class="expires-boleto__message">
        Atenção, essa ação é irreversível.
      </div>
      <div class="expires-boleto__label">
        Observações (opcional)
      </div>
      <textarea
        v-model="reason"
        class="expires-boleto___textarea"
        placeholder="Insira a observação aqui..."
      />
      <Button
        class="expires-boleto__button"
        type="positive"
        :disabled="submited"
        :text="submitting ? 'Aguarde...' : 'Expirar boleto'"
        :submit="true"
        :loading="submitting"
        @click="expiresBoleto"
      />
    </div>
  </div>
</template>

<script>
import TabHeader from '@/components/common/TabHeader';
import Button from '@/components/common/Button';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    TabHeader,
    Button,
  },
  data: () => ({
    reason: '',
    submitting: false,
    submited: false,
  }),
  computed: {
    ...mapGetters('Order', ['offerNames']),
  },
  methods: {
    ...mapActions('Order', ['submitExpiresBoletoClicked']),
    async expiresBoleto() {
      this.submitting = true;
      await this.submitExpiresBoletoClicked({ reason: this.reason });
      this.submitting = false;
    },
  },
};
</script>

<style lang="sass">
.expires-boleto
  display: flex
  flex-direction: column
  height: 100vh
  background-color: $space-gray

.expires-boleto__body
  overflow: auto
  padding: $space-m

.expires-boleto__title
  font-size: rem(18)
  font-weight: bold
  margin-bottom: $space-s

.expires-boleto___textarea
  @extend %opa-input
  margin-top: $space-s
  width: 100%
  height: 89px
  resize: none

.expires-boleto__label
  margin-top: $space-m

.expires-boleto__button
  margin-top: $space-m
  width: 100%
</style>
