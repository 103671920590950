<template>
  <Card class="voucher-card">
    <template slot="header">
      <div class="voucher-card__header">
        <div class="voucher-card__header-item">
          <span class="voucher-card__big-text">{{ cardTitle }}</span>
          <div class="voucher-card__card-origin">
            <VoucherActions :voucher="voucher" />
          </div>
        </div>
      </div>
    </template>
    <div class="voucher-card__body">
      <div v-if="showCourseName" class="voucher-card__line">
        Curso:
        <div class="opa-bold">
          {{ courseName }}
        </div>
      </div>
      <div class="voucher-card__line">
        Código:
        <div class="opa-bold">
          {{ voucher.code }}
        </div>
      </div>
      <div class="voucher-card__line">
        Vencimento:
        <div class="opa-bold">
          {{ voucher.ends_at | momentformat('L') }}
        </div>
      </div>
      <div class="voucher-card__line">
        Valor:
        <div class="opa-bold">
          {{ voucher.value | brlcurrency }}
        </div>
      </div>
      <div class="voucher-card__line">
        Status:
        <Badge class="voucher-card__status-badge" :type="voucherState.variant">
          {{ voucherState.label }}
        </Badge>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/common/Card';
import Badge from '@/components/common/Badge';
import VoucherActions from '@/components/Profile/ManageVoucher/VoucherActions';

export default {
  components: {
    Badge,
    Card,
    VoucherActions,
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    showCourseName: {
      type: Boolean,
      default: false,
    },
    showUniversityName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    voucherState() {
      const state = this.voucher.state || 'expired';

      const stateMapper = {
        active: {
          label: 'Ativo',
          variant: 'success',
        },
        expired: {
          label: 'Expirado',
          variant: 'error',
        },
        pending: {
          label: 'Pendente',
          variant: 'warning',
        },
        used: {
          label: 'Usado',
          variant: 'blue',
        },
        canceled: {
          label: 'Cancelado',
          variant: 'abort',
        },
      };

      return stateMapper[state];
    },
    cardTitle() {
      return this.showUniversityName ? this.universityName : 'Voucher';
    },
    course() {
      const { order } = this.voucher;
      const { courses } = order;

      return courses[0];
    },
    courseName() {
      const { name } = this.course;

      return name;
    },
    universityName() {
      const { university } = this.course;
      const { name } = university;

      return name;
    },
  },
};
</script>

<style lang="sass" scoped>
.voucher-card
  color: $silver
  margin-bottom: $space-st

.voucher-card__header
  align-items: flex-start
  display: flex
  font-size: rem(18)
  font-weight: bold
  justify-content: space-between
  position: relative
  width: 100%

.voucher-card__header-item
  align-items: flex-start
  display: flex
  font-size: rem(18)
  font-weight: bold
  justify-content: space-between
  position: relative
  width: 100%

.voucher-card__body
  color: $silver-gray
  padding: $space-s 0

.voucher-card__line
  align-items: center
  display: flex
  margin: $space-s 0

.voucher-card__big-text
  color: $white
  font-size: rem(18)
  font-weight: $fw-bold
</style>
