<template>
  <div class="course-card-body">
    <div
      v-for="info in courseDataList"
      :key="info.svgName"
      class="course-card-body__line"
    >
      <Icon class="course-card-body__icon" :svg-name="info.svgName" /><span>{{
        getData(info.value)
      }}</span>
    </div>
    <template v-if="offer">
      <div
        v-for="benefit in getOfferBenefits()"
        :key="benefit.name"
        class="course-card-body__line"
      >
        <Icon class="course-card-body__icon" :svg-name="benefit.svgName" />
        <span>
          {{ benefit.name }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import { courseData } from '@/assets/javascripts/data/courseData';
import { courseOfferBenefits } from '@/assets/javascripts/data/offerBenefits';

export default {
  components: {
    Icon,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      courseDataList: courseData,
      offerBenefitsList: courseOfferBenefits,
    };
  },
  computed: {
    campusAddress() {
      const { campus } = this.course;
      const addressFirstPart =
        campus.neighborhood && campus.address
          ? campus.neighborhood + ', ' + campus.address + ', '
          : '';
      const addressSecondPart =
        campus.city && campus.state ? campus.city + ' - ' + campus.state : '';

      return addressFirstPart + addressSecondPart;
    },
    campusName() {
      return this.course.campus.name;
    },
    contactPhone() {
      return (
        this.course.campus.phone || this.course.campus.university.phone || '---'
      );
    },
    enrollmentSemester() {
      return this.course.enrollmentSemester;
    },
    kind() {
      return this.course.kind;
    },
    level() {
      return this.course.level;
    },
    period() {
      const periodKind = this.plurify(this.course.periodKind);
      return `${this.course.maxPeriods} ${periodKind}`;
    },
    shift() {
      return this.course.shift;
    },
  },
  methods: {
    plurify(period) {
      const to_plural = {
        semestre: 'semestres',
        quinzenal: 'quinzenas',
        mes: 'meses',
        trimestre: 'trimestres',
        dia: 'dias',
        semanal: 'semanas',
        modulo: 'módulos',
        ano: 'anos',
        hora: 'horas',
      };

      return to_plural[period];
    },
    getOfferBenefits() {
      return this.offerBenefitsList.filter(
        benefit => this.offer[benefit.attibute]
      );
    },
    getData(attribute) {
      return this[attribute];
    },
  },
};
</script>

<style lang="sass" scoped>
.course-card-body
  color: $silver-gray

.course-card-body__line
  margin-bottom: $space-s
  display: flex
  align-items: center

.course-card-body__icon
  +square(24px)
  flex-shrink: 0
  margin-right: $space-s
</style>
