<template>
  <div class="tab-header">
    <ClickableIcon
      v-keycut:v="tabGoBack"
      class="tab-header__icon"
      :svg-name="iconName"
      svg-alt="Retornar (+V)"
      @click="tabGoBack"
    />
    <div class="tab-header__title-box">
      <h3 class="tab-header__title" :title="title">
        {{ title }}
      </h3>
      <div v-if="subTitle" class="tab-header__sub-title" :title="subTitle">
        {{ subTitle }}
      </div>
    </div>
    <slot v-if="$slots.default" />
    <div v-else class="tab-header__spacer" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ClickableIcon from '@/components/common/ClickableIcon';

export default {
  components: {
    ClickableIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('RightPanel', ['hasStackedTabs']),
    iconName() {
      return this.hasStackedTabs ? 'i-arrow-back' : 'i-close';
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabGoBack']),
  },
};
</script>
