<template>
  <button
    class="tenant-item"
    :class="{ 'tenant-item--selected': selected }"
    :title="tenant.name"
    @click="$emit('click')"
  >
    <div class="tenant-item__avatar-box">
      <Avatar
        class="tenant-item__avatar"
        :tenant-id="tenant.id"
        :selected="selected"
      />
      <div v-if="size" class="tenant-item__count-layout">
        <span class="tenant-item__count tenant-item__queue-size">{{
          size
        }}</span>
      </div>
    </div>
  </button>
</template>

<script>
import Avatar from '@/components/common/Avatar';
export default {
  components: {
    Avatar,
  },

  props: {
    tenant: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="sass">
.tenant-item
  width: 100%
  color: $silver-gray
  padding: $space-xs $space-s
  +flex-centered
  &::-moz-focus-inner
    border: 0

.tenant-item
  &:hover, &:focus
    background-color: $black-gray
    outline: none

.tenant-item--selected
  color: $white
  background: $black-gray
  &:hover, &:focus
    background-color: $black-gray

.tenant-item__avatar-box
  padding: $space-s $space-xs
  position: relative
  white-space: nowrap

.tenant-item__avatar
  display: block

.tenant-item__count
  border-radius: 18px
  color: $white
  font-size: rem(12)
  font-weight: $fw-bold
  line-height: normal
  padding: $space-xxs $space-xs

.tenant-item__count-layout
  position: absolute
  right: -$space-s
  top: -$space-xs

.tenant-item__queue-size
  background: $danger-red
  color: $white
  margin-right: $space-xxs
</style>
