<template>
  <div class="accordion">
    <template v-for="(item, index) in list">
      <div :key="index">
        <div ref="item" @click="open(item, index)">
          <slot
            name="head"
            :item="item"
            :index="index"
            :open="indexOpen === index"
          />
        </div>
        <template v-if="indexOpen === index">
          <slot name="body" :item="item" :index="index" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    openFun: {
      type: Function,
      required: false,
      default: undefined,
    },
    initializeOpen: {
      type: Number,
      required: false,
      default: undefined,
    },
    scrollOnOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      indexOpen: undefined,
    };
  },

  created() {
    if (this.initializeOpen !== undefined) {
      this.indexOpen = this.initializeOpen;
    }
  },

  methods: {
    open(item, index) {
      this.$emit('open', item, index);
      this.item = item;
      this.indexOpen = index !== this.indexOpen ? index : undefined;

      if (this.scrollOnOpen) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.$refs.item[index].scrollIntoView({
              block: 'start',
              inline: 'start',
              behavior: 'smooth',
            });
          });
        });
      }
    },
  },
};
</script>
