<template>
  <div>
    <template v-for="(flow, index) in admission.flow">
      <Card
        v-if="showCard(flow)"
        :key="index"
        class="flow__item"
        :show-footer-template="showFooter(flow)"
      >
        <template #header>
          <div class="admission-title admission-title--small">
            {{ flow | flowKindTranslate }}
          </div>
        </template>
        <template #default>
          <div class="admission-card__content-wrapper">
            <div class="admission-card__body-item">
              <span class="admission-text">Situação:</span>
              <Badge
                class="admission-card__status-badge"
                :type="flow | flowStatusColor"
              >
                {{ flow | flowStatusTranslate }}
              </Badge>
            </div>
            <div
              v-if="!!flow.statusDate"
              class="admission-card__body-item admission-text admission-text--xs admission-text--gray"
            >
              <Icon class="admission-card__icon" svg-name="i-schedule" />
              <span>
                {{ flow.statusDate | momentformat('L - LT') }}
              </span>
            </div>
          </div>
        </template>
        <template #footer>
          <template v-if="showFooter(flow)">
            <button
              class="opa-card__btn opa-card__btn--right"
              type="button"
              @click="detailsClick(flow)"
            >
              <span class="opa-card__btn-text">Detalhes</span>
              <Icon svg-name="i-chevron-right" svg-class="opa-card__btn-icon" />
            </button>
          </template>
        </template>
      </Card>
    </template>
    <FailedApplicationModal
      v-if="isApllicationFailed"
      ref="modal"
      :tips="admission.applications[0].tips"
    />
  </div>
</template>

<style lang="sass" scoped>
.flow__item:not(:last-child)
  margin-bottom: $space-st
</style>

<script>
import { mapActions } from 'vuex';

import Badge from '@/components/common/Badge';
import Card from '@/components/common/Card';
import Icon from '@/components/common/Icon';
import FailedApplicationModal from '@/opa-modules/admissions/components/FailedApplicationModal';

import {
  flowKindTranslate,
  flowStatusColor,
  flowStatusTranslate,
} from '@/assets/javascripts/data/admissions/flow';

export default {
  components: {
    Badge,
    Card,
    Icon,
    FailedApplicationModal,
  },

  filters: {
    flowKindTranslate,
    flowStatusColor,
    flowStatusTranslate,
  },

  props: {
    admission: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isApllicationFailed() {
      if (this.haveApplication) {
        return this.admission.applications[0].status === 'failed';
      }
      return false;
    },
    isEssayApplication() {
      if (this.haveApplication) {
        return this.admission.applications[0].kind === 'ESSAY';
      }
      return false;
    },
    haveApplication() {
      return this.admission.applications.length > 0;
    },
  },

  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    showDocumentList() {
      this.tabChange({
        key: 'admission-documents',
        params: { admission: this.admission },
      });
    },
    showFooter(flow) {
      return (
        (this.isApplicationFlow(flow) &&
          this.isApllicationFailed &&
          this.isEssayApplication) ||
        this.isDocumentationFlow(flow)
      );
    },
    showCard(flow) {
      return flow.status != 'unavailable';
    },
    isApplicationFlow(flow) {
      return flow.kind === 'APPLICATION';
    },
    isDocumentationFlow(flow) {
      return flow.kind === 'DOCUMENTATION';
    },
    detailsClick(flow) {
      if (this.isApplicationFlow(flow)) {
        this.openModal();
      } else if (this.isDocumentationFlow(flow)) {
        this.showDocumentList();
      }
    },
    openModal() {
      this.$refs.modal.show();
    },
  },
};
</script>
