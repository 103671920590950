<template>
  <Card class="user-info" :show-footer-template="hasMore">
    <template slot="header">
      <h3>Contatos e endereço</h3>
      <ClickableIcon
        v-if="hasLogin"
        class="user-info__icon"
        svg-name="i-edit"
        svg-class="user-info__svg"
        svg-alt="Editar dados"
        @click="goToRegisterTab"
      />
    </template>
    <div class="user-info__body">
      <div v-if="hasContact">
        <div
          v-for="(contactItem, index) in info.contact"
          :key="contactItem.caption || index"
          class="user-info__row"
        >
          <span class="user-info__caption">{{ contactItem.caption }}:</span>
          <span class="user-info__text">{{ contactItem.text }}</span>
        </div>
      </div>

      <div v-if="hasAddress">
        <div class="user-info__body-space" />
        <div
          v-for="(addressItem, index) in info.address"
          :key="addressItem.caption || index"
          class="user-info__row"
        >
          <span class="user-info__caption">{{ addressItem.caption }}:</span>
          <span class="user-info__text">{{ addressItem.text }}</span>
        </div>
      </div>

      <div v-if="canCreateLogin" class="user-info__row">
        <Button
          class="user-info__button"
          type="positive"
          text="Cadastrar aluno"
          @click="goToCreateLoginTab"
        />
      </div>

      <div v-if="hasLogin">
        <div class="user-info__body-space" />
        <h4>Logins:</h4>
        <div
          v-for="(loginItem, index) in info.login"
          :key="loginItem.caption || index"
          class="user-info__login-row"
        >
          <span class="user-info__caption">{{ loginItem.caption }}</span>
          <span class="user-info__text">{{ loginItem.text }}</span>
        </div>
      </div>
    </div>
    <template slot="footer">
      <ShowMore v-model="showMoreInfo" />
    </template>
  </Card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Card from '@/components/common/Card';
import ClickableIcon from '@/components/common/ClickableIcon';
import ShowMore from '@/components/common/ShowMore';
import Button from '@/components/common/Button';

import { stateCodeTranslate } from '@/assets/javascripts/data/stateCode';

export default {
  components: {
    Card,
    ClickableIcon,
    ShowMore,
    Button,
  },
  data() {
    return {
      showMoreInfo: false,
    };
  },
  computed: {
    ...mapState('Profile', ['profile', 'user']),
    ...mapGetters('Profile', ['hasLogin']),
    contact() {
      const profile = this.profile || {};
      const emails = profile.emails || [];
      const phones = profile.phones || [];
      const getAccTemplate = () => ({ initial: [], more: [] });
      const payload = {};

      payload.phones = phones
        .map(phone => forgeListItemObject('Celular', phone))
        .map((item, index) => ({
          ...item,
          whatsappUsername:
            this.profile.phonesWhatsappEnabled[index] &&
            this.profile.phonesWhatsapp[index],
        }))
        .reduce(catalogueReducer, getAccTemplate());

      payload.emails = emails
        .map(email => forgeListItemObject('Email', email))
        .reduce(catalogueReducer, getAccTemplate());

      return payload;

      function catalogueReducer(acc, cur, index) {
        let arrayToAdd = index == 0 ? acc.initial : acc.more;
        arrayToAdd.push(cur);

        return acc;
      }
    },

    userLogins() {
      const profileLogins = this.profile.logins || [];
      const loginTypes = getLoginTypes();

      const logins = profileLogins.map(login =>
        forgeListItemObject(
          loginTypes[login.type],
          `(${login.email || login.login_email || 'Não informado'})`
        )
      );

      return { logins };
    },

    addressFields() {
      const initialListBase = [
        ['Estado', 'state'],
        ['Cidade', 'city'],
        ['Bairro', 'neighborhood'],
      ];

      const moreListBase = [
        ['Endereço', 'address'],
        ['Número', 'addressNumber'],
        ['Complemento', 'addressComplement'],
      ];

      const fillData = array =>
        array
          .map(([caption, key]) => ({
            caption,
            text: this.profile[key],
          }))
          .filter(item => !!item.text)
          .map(item =>
            item.caption === 'Estado'
              ? { ...item, text: stateCodeTranslate(item.text) }
              : item
          );

      return {
        initial: fillData(initialListBase),
        more: fillData(moreListBase),
      };
    },

    info() {
      let address = [];
      let contact = [];
      let login = [];

      if (this.showMoreInfo) {
        address = [...this.addressFields.initial, ...this.addressFields.more];

        const { phones, emails } = this.contact;
        contact = [
          ...phones.initial,
          ...phones.more,
          ...emails.initial,
          ...emails.more,
        ];

        const { logins } = this.userLogins;
        login = [...logins];
      } else {
        address = this.addressFields.initial;
        const { phones, emails } = this.contact;
        contact = [...phones.initial, ...emails.initial];
      }

      return { contact, address, login };
    },

    hasMore() {
      return !!(
        this.addressFields.more.length ||
        this.contact.emails.more.length !== 0 ||
        this.contact.phones.more.length !== 0 ||
        this.hasLogin
      );
    },

    hasAddress() {
      return this.info.address.length !== 0;
    },

    hasContact() {
      return this.info.contact.length !== 0;
    },

    moreIconClass() {
      return (
        'user-info__more-icon ' +
        'user-info__more-icon--' +
        (this.showMoreInfo ? 'more' : 'less')
      );
    },

    canCreateLogin() {
      return !this.hasLogin;
    },
  },
  methods: {
    ...mapActions('RightPanel', ['tabChange']),
    goToRegisterTab() {
      this.tabChange({
        key: 'register-user',
        params: { registerType: 'update' },
      });
    },
    goToCreateLoginTab() {
      this.tabChange({ key: 'create-login' });
    },
  },
};

function forgeListItemObject(caption, val) {
  return {
    caption: caption,
    text: val,
  };
}

function getLoginTypes() {
  return {
    AppleLogin: 'Apple',
    EmailLogin: 'Email',
    FacebookLogin: 'Facebook',
    FirebaseLogin: 'Aplicativo',
    GoogleLogin: 'Google',
  };
}
</script>

<style lang="sass">
.user-info__icon
  margin-left: auto
  align-self: stretch
  padding: $space-s
  position: relative
  left: $space-s

.user-info__svg
  +square(20px)

.user-info__body
  color: $silver-gray
  font-size: rem(14)
  padding: $space-m 0

.user-info__row
  margin: $space-xxs 0
  word-wrap: break-word

.user-info__login-row
  line-height: 18px
  word-wrap: break-word

.user-info__caption
  font-weight: bold

.user-info__body-space
  height: $space-m

.user-info__contact-icon
  color: $silver-gray
  margin-left: $space-s
  vertical-align: text-bottom
  +square(15px)

.user-info__text
  margin-left: $space-xs

.user-info__register-btn
  margin: $space-s 0 $space-m 0
  width: 100%

.user-info__whatsapp-contact
  cursor: pointer

.user-info__button
  width: 100%
  margin-bottom: $space-s
  margin-top: $space-st
</style>
